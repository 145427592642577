import React, { Component } from "react";
import Footer1 from "../directives/footer1";
import Header1 from "../directives/header1";
import axios from "axios";
import config from "../config/config";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip } from "react-reveal";
import { Helmet } from "react-helmet";
import { withRouter  } from 'react-router-dom';
import { ColorRing } from "react-loader-spinner";

const MAX_LENGTH = 290;

export default class collection extends Component {
  // const navigate = useNavigate();
  constructor(props) {
    super(props);
    this.loginData = !Cookies.get("artfungibleUserloginSuccess")
      ? []
      : JSON.parse(Cookies.get("artfungibleUserloginSuccess"));
    this.state = {
      artistList: "",
      collectionList: [],
      seoList: "",
      loaderSpinner:true
    };

    // const {
    //   match: { params },
    // } = this.props;
    // this.artistId = params.artistId;
  }

  componentDidMount() {
    
    var url = window.location.href;
    var split = url.split("/");
    let lastSecondSegment = split[split.length - 2];
    this.getSeoAPI(lastSecondSegment);
    
    // console.log(this.props.location.state)
    if(this.props.location.state){
      const receivedData = this.props.location.state;
      if (receivedData) {
        this.artistId = receivedData.state.artistId
        this.getArtistDetailByUserIdAction();
        this.getCollectionDetailByArtistIdAction();
        // alert(this.artistId)
      } else {
        console.log('No data received');
      }
    }
    else{

      let artistId = Cookies.get('artistId')
            this.artistId = artistId
      this.getArtistDetailByUserIdAction();
      this.getCollectionDetailByArtistIdAction();
    }
  }

  async getSeoAPI(lastSecondSegment) {
    await axios({
      method: "post",
      url: `${config.apiUrl}/getSEO`,
      data: { pageName: lastSecondSegment },
    })
      .then(async (response) => {
        if (response.data.success === true) {
          this.setState({
            seoList: response.data.data,
          });
        } else if (response.data.success === false) {
        }
      })
      .catch((err) => {});
  }

  async getArtistDetailByUserIdAction() {
    
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}getUserArtistDetailById`,
        data: { id: this.artistId },
      }).then((response) => {
        this.setState({
          artistList: response.data.data,
          loaderSpinner:false
        });
      });
    } catch (err) {
      this.setState({
        loaderSpinner:false
      })
    }
  }

  async getCollectionDetailByArtistIdAction() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}getCollectionDetailByArtistId`,
        data: { id: this.artistId },
      }).then((response) => {
        if (response.data.success === true) {
          this.setState({
            collectionList: response.data.data,
          loaderSpinner:false

          });
        } else {
          this.setState({
            collectionList: [],
          loaderSpinner:false

          });
        }
      });
    } catch (err) {
      this.setState({
        loaderSpinner:false

      })
    }
  }
  collectionLink = async (item) => {
    const formattedName = item.name.replace(/\s+/g, '-'); // Replaces spaces with hyphens
    const formattedCollectionName = item.short_name.replace(/\s+/g, '-'); // Replaces spaces with hyphens
  
    this.props.history.push(`${config.baseUrl}${formattedName}/${formattedCollectionName}`, {
      state: { collectionId: item.collectionId },
    });
  };
  
  render() {
    return (
      <>
        <div className="body_bg">
          <Header1 />
          <Helmet>
            <title>{this.state.seoList?.title}</title>
            <meta title={this.state.seoList?.pageTitle} content="IE=edge" />
            <meta
              name="description"
              content={this.state.seoList?.description
                ?.replace(/<[^>]*>?/gm, "")
                .substring(0, 170)}
            />
          </Helmet>

          {/*---------------------- Collection Section Start -------------------- */}

          <section className="collection pt-lg-5 pt-3">
            <div className="container-fluid px-lg-5">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                  <Zoom>
                    <div className="mb-lg-0 mb-5">
                      <img
                        src={config.imageUrl1 + this.state?.artistList?.image}
                        className="collec_jollykoh"
                        alt="subscribeimg"
                      />
                    </div>
                  </Zoom>
                </div>
                <div className="col-lg-8 col-md-6 col-12 d-flex align-items-center pl-lg-5">
                  <Slide right>
                    <div className="substext pl-lg-5">
                      <h5>{this.state?.artistList?.name}</h5>
                      <div className="viewart">
                        <span>
                          <img
                            src="mercafor_dashboard/assets/img/globe.svg"
                            className=""
                            alt="globe"
                          />
                        </span>
                        <a href={this.state?.artistList?.baseUrl} target="_blank">
                          <h6>View Art here</h6>
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <span>
                          <img
                            src="mercafor_dashboard/assets/img/globe.svg"
                            className=""
                            alt="globe"
                          />
                        </span>
                        <a
                          href={this.state?.artistList?.viewArtistHere}
                          target="_blank"
                        >
                          <h6>View Aritst here</h6>
                        </a>
                      </div>
                      {/* {this?.state?.artistList.description?.length >
                      MAX_LENGTH ? (
                        <p title={this?.state?.artistList.description}>
                          {`${this?.state?.artistList.description.substring(
                            0,
                            MAX_LENGTH
                          )}...`}
                        </p>
                      ) : (
                        <p title={this?.state?.artistList.description}>
                          {this?.state?.artistList.description}
                        </p>
                      )} */}
                      <p style={{ textAlign: "justify" }}>
                        {this.state?.artistList?.description}
                      </p>
                    </div>
                  </Slide>
                </div>
              </div>
            </div>
          </section>

          {/*---------------------- Collection Section End -------------------- */}

          {/*---------------------- Collection Section Start -------------------- */}

          <section className="collection_card py-lg-5 pt-3">
            <div className="container-fluid px-lg-5">
              <div className="row">
                <div className="col-12 mb-4">
                  <h2 className="tophead">Collection</h2>
                  {/* <p>Our artists are at the bleeding edge of generative art, We also partner with traditional artists, and give them the support they need to relese generative art.</p> */}
                </div>
              </div>
              <div className="row">
              {this.state.loaderSpinner  ? (
          <ColorRing
            visible={true}
            height="180"
            width="180"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        ) :this.state.collectionList.length === 0 ? (
                  <div className="col-lg-12 col-md-12 col-12 text-center mb-4">
                    <Flip left>
                      <div
                        className="d-flex justify-content-center"
                        style={{ marginBottom: "-20px" }}
                      >
                        <dotlottie-player
                          src="https://lottie.host/2779598a-ca3c-4fc6-aeb8-25216948d698/L7Y2Nq9xlE.json"
                          background="transparent"
                          speed={1}
                          style={{ width: "200px", height: "200px" }}
                          loop
                          autoPlay
                        />
                      </div>
                      <h2>Sorry, no results found!</h2>

                      <h5> No Collection Found For These Artist</h5>
                    </Flip>
                  </div>
                ) : (
                  this.state.collectionList.map((item) => {
                    return (
                      <div
                        className="col-lg-6 col-md-6 col-12 text-center mb-4"
                        key={item.collectionId}
                      >
                        <Flip left>
                          <div className="card feature">
                            <div className="cardblock">
                              <a
                                href="javascript:void(0)" //
                                onClick={this.collectionLink.bind(this,item)}
                              >
                                <img
                                  className="img-fluid"
                                  src={config.imageUrl1 + item?.collectionImage}
                                  alt="water"
                                  style={{ height: "401px", width: "631px" }}
                                />
                              </a>
                            </div>
                            <div className="card-body my-3">
                              <h5
                                className="card-title text-left mb-4"
                                style={{
                                  fontFamily: "Catamaran",
                                  fontWeight: "bold",
                                  fontSize: "40px",
                                  lineHeight: "32px",
                                }}
                              >
                                <strong>{item?.collectionName}</strong>
                              </h5>
                              {/* {item?.collectionDescription?.length >
                              MAX_LENGTH ? (
                                <p
                                  className="card-text text-left"
                                  title={item?.collectionDescription}
                                >
                                  {`${item?.collectionDescription.substring(
                                    0,
                                    MAX_LENGTH
                                  )}...`}
                                </p>
                              ) : (
                                <p
                                  className="card-text text-left"
                                  title={item?.collectionDescription}
                                >
                                  {item?.collectionDescription}
                                </p>
                              )} */}
                              <p style={{ textAlign: "justify" }}>
                                {item?.collectionDescription}
                              </p>
                            </div>
                          </div>
                        </Flip>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </section>

          {/*---------------------- Collection Section End -------------------- */}

          <Footer1 />
        </div>
      </>
    );
  }
}
