import React, { Component } from "react";
import Header1 from "./directives/header1";
import Footer1 from "./directives/footer1";
import axios from "axios";
import config from "./config/config";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import "react-alice-carousel/lib/alice-carousel.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Popup from "./popup";
import { Helmet } from "react-helmet";

import { Fade, Zoom, Bounce } from "react-reveal";
const MAX_LENGTH = 45;

export default class home2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      artistList: [],
      collectionList: [],
      faqList: [],
      isPopupError: false,
      isPopupSuccess: false,
      msg: false,
      seoList: "",
    };
    this.addSubscriberAPI = this.addSubscriberAPI.bind(this);
    this.loginData = !Cookies.get("artfungibleUserloginSuccess")
      ? []
      : JSON.parse(Cookies.get("artfungibleUserloginSuccess"));
  }

  componentDidMount() {
    this.getArtistDetailAction();
    this.getCollectionAPI();
    this.getFaqAPI();
    var parts = window.location.href.split("/");
    var lastSegment = parts.pop() || parts.pop(); // handle potential trailing slash

    this.getSeoAPI(lastSegment);
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  async getArtistDetailAction() {
    try {
      await axios({
        method: "get",
        url: `${config.apiUrl}getUserArtistDetail`,
      }).then((response) => {
        this.setState({
          artistList: response.data.data.filter(
            (item) => item.isFeatured === 1
          ),
        });
      });
    } catch (err) { }
  }

  async addSubscriberAPI(e) {
    e.preventDefault();

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!pattern.test(this.state.email)) {
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Please enter a valid email",
      });
    } else {
      await axios({
        method: "post",
        url: `${config.apiUrl}addSubscriber`,
        data: { email: this.state.email },
      })
        .then((response) => {
          if (response.data.success === true) {
            Swal.fire({
              title: "Congratulation",
              icon: "success",
              text: response.data.msg,
            });
            this.setState({
              email: "",
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: err.response.data.msg,
          });
          this.setState({
            email: "",
          });
        });
    }
  }

  marketplace(categoryid) {
    Cookies.set("categoryid", categoryid);
    window.location.href = `marketplace`;
  }

  async getCollectionAPI() {
    await axios({
      method: "get",
      url: `${config.apiUrl}getUserCollectionDetail`,
    })
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            collectionList: response.data.data.filter(
              (item) => item.isFeatured === 1
            ),
          });
        } else if (response.data.success === false) {
          this.setState({
            collectionList: [],
          });
        }
      })

      .catch((err) => {
        this.setState({
          collectionList: [],
        });
      });
  }

  async getFaqAPI() {
    await axios({
      method: "get",
      url: `${config.apiUrl}faqlistUser`,
    })
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            faqList: response.data.response,
          });
        } else if (response.data.success === false) {
        }
      })

      .catch((err) => { });
  }

  async getSeoAPI(lastSegment) {
    await axios({
      method: "post",
      url: `${config.apiUrl}/getSEO`,
      data: { pageName: lastSegment === "art_fungible" ? "Home" : "" },
    })
      .then(async (response) => {
        if (response.data.success === true) {
          this.setState({
            seoList: response.data.data,
          });
        } else if (response.data.success === false) {
        }
      })
      .catch((err) => { });
  }

  loginCheck() {
    if (this.loginData.length === 0) {
      this.setState({
        isPopupError: true,
        msg: "Connect Wallet First",
      });
      setTimeout(() => {
        this.setState({
          isPopupError: false,
        });
      }, 2000);
    } else {
      window.location.href = `${config.baseUrl}member`;
    }
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,

      slidesToShow:
        this.state.artistList.length > 3 ? 4 : this.state.artistList.length,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
      ],
    };

    const settings1 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow:
        this.state.collectionList.length > 3
          ? 4
          : this.state.collectionList.length,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
      ],
    };

    return (
      <>
        <div className="body_bg">
          <Helmet>
            <title>{this.state.seoList?.title}</title>
            <meta title={this.state.seoList?.pageTitle} content="IE=edge" />
            <meta
              name="description"
              content={this.state.seoList?.description
                ?.replace(/<[^>]*>?/gm, "")
                .substring(0, 170)}
            />
          </Helmet>
          <Header1 />
          <Popup
            isPopupError={this.state.isPopupError}
            isPopupSuccess={this.state.isPopupSuccess}
            msg={this.state.msg}
            className="me-5"
          />
          {/*---------------------- Banner Section Start -------------------- */}
          <section className="banner pt-lg-5 pt-3">
            <div className="filterone"></div>
            <div className="filtertwo"></div>
            <div className="container-fluid px-lg-5">
              <div className="row">
                <Fade left>
                  <div className="col-lg-6 col-md-6 col-12 px-lg-3 d-flex align-items-center">
                    <div className="bnrtext">
                      <h1>
                        Enter the new era <br />
                        of collecting art.
                      </h1>
                      <div className="generative">
                        <p>
                          A fully-decentralized marketplace for art and collectibles.
                        </p>
                      </div>
                      <div className="mt-5">
                        <span
                          className="custombtn freemint"
                          style={{ cursor: "pointer" }}
                          onClick={this.loginCheck.bind(this)}
                        >
                          Free Mint Now{" "}
                          <span>
                            <img
                              src="mercafor_dashboard/assets/img/arrowwhite.svg"
                              style={{ width: "10px", height: "12px" }}
                              className="mb-1"
                              alt="arrowwhite"
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </Fade>

                <Fade right>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="bannerimg">
                      <img
                        src="mercafor_dashboard/assets/img/banner_img.webp"
                        className="img-fluid"
                        alt="banner"
                      />
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </section>
          {/*---------------------- Feature Section Start -------------------- */}
          <section className="feature_collection py-5">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center mb-4">
                  <h2 className="tophead">Featured Collections</h2>
                </div>
              </div>

              <div className="row">
                {this.state.collectionList.length <= 4 &&
                  this.state.collectionList.length > 0 ? (
                  <>
                    {this.state.collectionList.map((item) => (
                      <div className="col-lg-3 col-md-3 col-3" key={item.id}>
                        <div className="item">
                          <div className="card feature">
                            <a href={`${config.baseUrl}afgenesis/` + item.id}>
                              <img
                                className="card-img-top"
                                src={config.imageUrl1 + item.collectionImage}
                                alt="Jollykoh1"
                              />
                            </a>
                            <div className="card-body">
                              <p className="card-text">
                                {" "}
                                {item.collectionName}
                              </p>
                              <h5 className="card-title">
                                {" "}
                                {item.collectionDescription?.length >
                                  MAX_LENGTH ? (
                                  <span title={item.collectionDescription}>
                                    {`${item.collectionDescription.substring(
                                      0,
                                      MAX_LENGTH
                                    )}...`}
                                  </span>
                                ) : (
                                  <span>{item.collectionDescription}</span>
                                )}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="">
                    <div className="">
                      <div className="">
                        {this.state.collectionList.length === 0 ? (
                          <div className="">
                            <div className="">
                              <div className="">
                                <h3 className="" style={{ marginLeft: "33%" }}>
                                  <img src="images/noData.png" />
                                  {/* <h2 style={{marginLeft:"-42px"}}>Sorry, no results found!</h2> */}

                                  <h5 style={{ marginLeft: "28px" }}>
                                    {" "}
                                    No Collection Found
                                  </h5>
                                </h3>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="">
                          <Bounce bottom>
                            <Slider {...settings1}>
                              {this.state.collectionList.map((item) => (
                                <div
                                  className="col-lg-12 col-md-12 col-12"
                                  key={item.id}
                                >
                                  <div className="item">
                                    <div className="card feature">
                                      <a
                                        href={
                                          `${config.baseUrl}afgenesis/` +
                                          item.id
                                        }
                                      >
                                        <img
                                          className="card-img-top"
                                          style={{
                                            width: "225px",
                                            height: "400px",
                                          }}
                                          src={
                                            config.imageUrl1 +
                                            item.collectionImage
                                          }
                                          alt="Jollykoh1"
                                        />
                                      </a>
                                      <div className="card-body">
                                        <p className="card-text">
                                          {" "}
                                          {item.collectionName}
                                        </p>
                                        <h5 className="card-title">
                                          {" "}
                                          {item.collectionDescription?.length >
                                            MAX_LENGTH ? (
                                            <span
                                              title={item.collectionDescription}
                                            >
                                              {`${item.collectionDescription.substring(
                                                0,
                                                MAX_LENGTH
                                              )}...`}
                                            </span>
                                          ) : (
                                            <span>
                                              {item.collectionDescription}
                                            </span>
                                          )}
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </Slider>
                          </Bounce>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
          {/*---------------------- Feature Section End -------------------- */}
          {/*---------------------- Advantage Section Start -------------------- */}
          <section className="advantage pt-5">
            <div className="container-fluid px-lg-5">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                  <Zoom top>
                    <div className="adventure_div">
                      <div className="adventure_text">
                        <h4 className="mb-5">The advantage as a member</h4>
                        <p className="">
                          Elevate your art collecting experience, join us today!
                        </p>
                        <div className="mt-5 pt-4">
                          <span
                            style={{ cursor: "pointer" }}
                            className="custombtn learnmore"
                            onClick={this.loginCheck.bind(this)}
                          >
                            {/* <a href="#javascript" className="custombtn learnmore"> */}
                            Learn More {/* </a> */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Zoom>
                </div>
              </div>
            </div>
          </section>
          {/*---------------------- Advantage Section End -------------------- */}
          {/*---------------------- World Section Start -------------------- */}
          <section className="world py-5" id="worldid">
            <div className="container-fluid px-lg-5">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12 mx-auto text-center mb-5">
                  <h2 className="tophead">World renowned artists</h2>
                  <p className="topheadpera mt-4">
                    Our artists are at the bleeding edge of blockchain art. We also partner with traditional artists, and give them the support they need to relese their art on the blockchain.
                  </p>
                </div>
              </div>

              <div className="row">
                {this.state.artistList.length <= 4 &&
                  this.state.artistList.length > 0 ? (
                  <>
                    {this.state.artistList.map((item) => (
                      <div className="col-lg-3 col-md-3 col-3" key={item.id}>
                        <div className="item">
                          <div className="card feature">
                            <a href={`${config.baseUrl}collection/` + item.id}>
                              <img
                                className="card-img-top"
                                src={config.imageUrl1 + item.image}
                                alt="Jollykoh1"
                              />
                            </a>
                            <div className="card-body">
                              <p className="card-text"> {item.name}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="">
                    <div className="">
                      <div className="">
                        {this.state.artistList.length === 0 ? (
                          <div className="item">
                            <div className="card feature">
                              <div className="cardblock">
                                <h3 className="" style={{ marginLeft: "33%" }}>
                                  No Artist Found
                                </h3>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="">
                          <Bounce bottom>
                            <Slider {...settings}>
                              {this.state.artistList.map((item) => (
                                <div
                                  className="col-lg-12 col-md-12 col-12"
                                  key={item.id}
                                >
                                  <div className="item">
                                    <div className="card feature">
                                      <a
                                        href={
                                          `${config.baseUrl}collection/` +
                                          item.id
                                        }
                                      >
                                        <img
                                          className="card-img-top"
                                          style={{
                                            width: "225px",
                                            height: "400px",
                                          }}
                                          src={config.imageUrl1 + item.image}
                                          alt="Jollykoh1"
                                        />
                                      </a>
                                      <div className="card-body">
                                        <p className="card-text">
                                          {" "}
                                          {item.name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </Slider>
                          </Bounce>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
          {/*---------------------- World Section End -------------------- */}
          {/*---------------------- Subscribe Section Start -------------------- */}
          <section className="subscribe py-5">
            <div className="container-fluid px-lg-5">
              <div className="row">
                <Fade left>
                  <div className="col-lg-5 col-md-6 col-12 d-flex align-items-center">
                    <div className="substext subscribe">
                      <h5>
                        Subscribe to our ultra-exclusive drop list and be the
                        first to know about upcoming Art Fungible drops.
                      </h5>
                      <div className="mt-5 subs_form">
                        <form>
                          <div className="form-group arrowfield">
                            <input
                              type="email"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="Enter your email"
                              value={this.state.email}
                              name="email"
                              onChange={this.onChange}
                            />
                            <span>
                              <img
                                src="mercafor_dashboard/assets/img/emailarrow.svg"
                                className=""
                                style={{ cursor: "pointer" }}
                                onClick={this.addSubscriberAPI}
                                alt="emailarrow"
                              />
                            </span>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Fade>
                <Zoom top>
                  <div className="col-lg-7 col-md-6 col-12">
                    <div className="subscribeimg pl-lg-5">
                      <img
                        src="mercafor_dashboard/assets/img/subscribeimg.webp"
                        className=""
                        alt="subscribeimg"
                      />
                    </div>
                    <div className="">
                      <a href="#javascript">
                        <img
                          src="mercafor_dashboard/assets/img/chaticon.svg"
                          className="chaticon"
                          alt="chaticon"
                        />
                      </a>
                    </div>
                  </div>
                </Zoom>
              </div>
            </div>
          </section>
          {/*---------------------- Subscribe Section End -------------------- */}
          {/*---------------------- World Section Start -------------------- */}
          <section className="faq py-5">
            <div className="container-fluid px-lg-5">
              <div className="row">
                <div className=" col-12 mx-auto text-center mb-5">
                  <h2 className="tophead">FAQ</h2>
                  <p className="topheadpera mt-4">
                    Our artists are at the bleeding edge of blockchain art. We also partner with traditional artists, and give them the support they need to relese their art on the blockchain.
                  </p>
                </div>
              </div>

              <div className="row">
                <Bounce bottom>
                  <div className="col-lg-8 col-md-8 col-12 mx-auto">
                    <div
                      className="panel-group"
                      id="accordion"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      {this.state.faqList.map((item, index) => (
                        <div className="panel panel-default mb-3" key={item.id}>
                          <div
                            className="panel-heading"
                            role="tab"
                            id={"headingOne" + (index + 1)}
                          >
                            <h4 className="panel-title">
                              <a
                                role="button"
                                data-toggle="collapse"
                                data-parent="#accordion"
                                href={"#collapseOne" + (index + 1)}
                                aria-expanded="false"
                                aria-controls={"collapseOne" + (index + 1)}
                                style={{ color: "#000" }}
                              >
                                {item.question}
                              </a>
                            </h4>
                          </div>
                          <div
                            id={"collapseOne" + (index + 1)}
                            className="panel-collapse collapse in"
                            role="tabpanel"
                            aria-labelledby={"headingOne" + (index + 1)}
                          >
                            <div className="panel-body pl-lg-3">
                              {item.answer}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Bounce>
              </div>
            </div>
          </section>
          {/*---------------------- World Section End -------------------- */}
          <Footer1 />
        </div>
      </>
    );
  }
}
