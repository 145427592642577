import React, { Component } from "react";
import "./bgparticle.css";

export default class Bgparticle extends Component {
  constructor(props) {
    super(props);
    this.interBubbleRef = React.createRef(); // Create a ref to target .interactive element
    this.curX = 0;
    this.curY = 0;
    this.tgX = 0;
    this.tgY = 0;
  }

  componentDidMount() {
    const interBubble = this.interBubbleRef.current; // Use the ref instead of document.querySelector

    const move = () => {
      this.curX += (this.tgX - this.curX) / 20;
      this.curY += (this.tgY - this.curY) / 20;
      interBubble.style.transform = `translate(${Math.round(this.curX)}px, ${Math.round(this.curY)}px)`;
      requestAnimationFrame(move);
    };

    // Add mousemove event listener to update target X and Y coordinates
    window.addEventListener("mousemove", this.handleMouseMove);

    // Start the animation
    move();
  }

  componentWillUnmount() {
    // Remove mousemove event listener when the component is unmounted
    window.removeEventListener("mousemove", this.handleMouseMove);
  }

  handleMouseMove = (event) => {
    // Update target positions (tgX, tgY) on mouse movement
    this.tgX = event.clientX;
    this.tgY = event.clientY;
  };

  render() {
    return (
      <>
       <div className="animation-bg">
          <div className="">
            <svg viewBox="0 0 100% 100%" xmlns="http://www.w3.org/2000/svg" className="noise">
              <filter id="noiseFilter">
                <feTurbulence type="fractalNoise" baseFrequency="0.85" numOctaves="6" stitchTiles="stitch" />
              </filter>
              <rect width="100%" height="100%" preserveAspectRatio="xMidYMid meet" filter="url(#noiseFilter)" />
            </svg>
          </div>
          <div className="gradient-bg">
            <svg viewBox="0 0 100vw 100vw" xmlns="http://www.w3.org/2000/svg" className="noiseBg">
              <filter id="noiseFilterBg">
                <feTurbulence type="fractalNoise" baseFrequency="0.6" stitchTiles="stitch" />
              </filter>
              <rect width="100%" height="100%" preserveAspectRatio="xMidYMid meet" filter="url(#noiseFilterBg)" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" className="svgBlur">
              <defs>
                <filter id="goo">
                  <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                  <feColorMatrix
                    in="blur"
                    mode="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8"
                    result="goo"
                  />
                  <feBlend in="SourceGraphic" in2="goo" />
                </filter>
              </defs>
            </svg>
            <div className="gradients-container">
              <div className="g1"></div>
              <div className="g2"></div>
              <div className="g3"></div>
              <div className="g4"></div>
              <div className="g5"></div>
              {/* Assign the ref to the interactive div */}
              <div className="interactive" ref={this.interBubbleRef}></div>
            </div>
          </div>
      </div>
      </>
    );
  }
}
