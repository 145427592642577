import React, { Component } from "react";
import Header1 from "./directives/header1";
import Footer1 from "./directives/footer1";
import axios from "axios";
import config from "./config/config";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import "react-alice-carousel/lib/alice-carousel.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import "./mercafor_dashboard/assets/css/homenew.css";
import { FaArrowRight } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa6";
import { Tab, Nav, Row, Col, Button } from "react-bootstrap";
import Bgparticle from "./bgparticle";

import Popup from "../src/popup";
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router-dom';

import { Fade, Zoom, Bounce, Roll } from "react-reveal";
import moment from "moment";
const MAX_LENGTH = 35;
const MAX_LENGTH_NAME = 25;

export default class home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      artistList: [],
      collectionList: [],
      faqList: [],
      getSliderNftListData: [],
      getFeaturedNftListData: [],
      blog_list: [],
      isPopupError: false,
      isPopupSuccess: false,
      msg: false,
      seoList: "",
      blog_list: [
        // Example data for testing
        {
          title: "Blog Title 1",
          description: "This is a sample description for the blog post 1.",
          date: new Date(),
          image: "image1.jpg",
          isExpanded: false,
        },
        {
          title: "Blog Title 2",
          description: "This is a sample description for the blog post 2.",
          date: new Date(),
          image: "image2.jpg",
          isExpanded: false,
        },
      ],

      activeTab: "1", // Track the active tab
    };
    this.sliderRef = React.createRef();
    this.addSubscriberAPI = this.addSubscriberAPI.bind(this);
    this.loginData = !Cookies.get("artfungibleUserloginSuccess")
      ? []
      : JSON.parse(Cookies.get("artfungibleUserloginSuccess"));
  }

  handleMouseEnter = (eventKey) => {
    this.setState({ activeTab: eventKey });
  };

  onChange = (index) => {

  };

  onClickItem = (index) => {

  };

  onClickThumb = (index) => {

  };

  componentDidMount() {
    this.getArtistDetailAction();
    this.getCollectionAPI();
    this.getFaqAPI();
    this.getSliderNftListAPI();
    this.getFeaturedNftListAPI();
    this.allBlogList();
    var parts = window.location.href.split("/");
    var lastSegment = parts.pop() || parts.pop(); // handle potential trailing slash

    this.getSeoAPI(lastSegment);
  }

  async allBlogList() {
    await axios({
      method: "get",
      url: `${config.apiUrl}/getBlogList`,
    })
      .then((result) => {
        if (result.data.success === true) {
          this.setState({
            blog_list: result.data.response.filter(
              (item) => item.is_active == 1
            ),
          });
        } else if (result.data.success === false) {
          this.setState({
            blog_list: [],
          });
        }
      })

      .catch((err) => {
        this.setState({
          blog_list: [],
        });
      });
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  async getArtistDetailAction() {
    try {
      await axios({
        method: "get",
        url: `${config.apiUrl}getUserArtistDetail`,
      }).then((response) => {
        this.setState({
          artistList: response.data.data.filter(
            (item) => item.isFeatured === 1
          ),
        });
      });
    } catch (err) { }
  }

  async addSubscriberAPI(e) {
    e.preventDefault();

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!pattern.test(this.state.email)) {
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Please enter a valid email",
      });
    } else {
      await axios({
        method: "post",
        url: `${config.apiUrl}addSubscriber`,
        data: { email: this.state.email },
      })
        .then((response) => {
          if (response.data.success === true) {
            Swal.fire({
              title: "Congratulation",
              icon: "success",
              text: response.data.msg,
            });
            this.setState({
              email: "",
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops",
            text: err.response.data.msg,
          });
          this.setState({
            email: "",
          });
        });
    }
  }

  marketplace(categoryid) {
    Cookies.set("categoryid", categoryid);
    window.location.href = `marketplace`;
  }

  async getCollectionAPI() {
    await axios({
      method: "get",
      url: `${config.apiUrl}getUserCollectionDetail`,
    })
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            collectionList: response.data.data.filter(
              (item) => item.isFeatured === 1
            ),
          });
        } else if (response.data.success === false) {
          this.setState({
            collectionList: [],
          });
        }
      })

      .catch((err) => {
        this.setState({
          collectionList: [],
        });
      });
  }

  async getFaqAPI() {
    await axios({
      method: "get",
      url: `${config.apiUrl}faqlistUser`,
    })
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            faqList: response.data.response,
          });
        } else if (response.data.success === false) {
        }
      })

      .catch((err) => { });
  }

  async getSliderNftListAPI() {
    await axios({
      method: "get",
      url: `${config.apiUrl}getSliderNftList`,
    })
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            getSliderNftListData: response.data.response,
          });
        } else if (response.data.success === false) {
        }
      })

      .catch((err) => { });
  }

  async getFeaturedNftListAPI() {
    await axios({
      method: "get",
      url: `${config.apiUrl}getFeaturedNftList`,
    })
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            getFeaturedNftListData: response.data.response,
          });
        } else if (response.data.success === false) {
        }
      })

      .catch((err) => { });
  }

  async getSeoAPI(lastSegment) {
    await axios({
      method: "post",
      url: `${config.apiUrl}/getSEO`,
      data: { pageName: lastSegment === "art_fungible" ? "Home" : "" },
    })
      .then(async (response) => {
        if (response.data.success === true) {
          this.setState({
            seoList: response.data.data,
          });
        } else if (response.data.success === false) {
        }
      })
      .catch((err) => { });
  }

  loginCheck() {
    if (this.loginData.length === 0) {
      this.setState({
        isPopupError: true,
        msg: "Connect Wallet First",
      });
      setTimeout(() => {
        this.setState({
          isPopupError: false,
        });
      }, 2000);
    } else {
      window.location.href = `${config.baseUrl}member`;
    }
  }
  next = () => {
    this.sliderRef.current.slickNext(); // Move to next slide
  };

  previous = () => {
    this.sliderRef.current.slickPrev(); // Move to previous slide
  };


  collectionLink = async (item) => {

    const formattedCreatorName = item.creatorName.replace(/\s+/g, '-'); // Replaces spaces with hyphens
    const formattedCollectionName = item.short_name.replace(/\s+/g, '-'); // Replaces spaces with hyphens
    const formattedArtworkName = item.name.replace(/\s+/g, '-'); // Replaces spaces with hyphens

    if (item.firstPurchase == 0) {
      this.props.history.push(`${config.baseUrl}${formattedCreatorName}/${formattedCollectionName}`, {
        state: { collectionId: item.item_collection_id },
      });
    }
    else {

      this.props.history.push(`${config.baseUrl}${formattedCreatorName}/${formattedCollectionName}/${formattedArtworkName}`, {
        state: { itemId: item.id, type: item.type == "Digital" ? 1 : 2 },
      });
    }

  };

  collectionLinkFeat = async (item) => {

    const formattedName = item.name.replace(/\s+/g, '-'); // Replaces spaces with hyphens
    const formattedCollectionName = item.short_name.replace(/\s+/g, '-'); // Replaces spaces with hyphens

    this.props.history.push(`${config.baseUrl}${formattedName}/${formattedCollectionName}`, {
      state: { collectionId: item.item_collection_id },
    });
  };


  artistLink= async (item) => {
    
    const formattedName = item.name.replace(/\s+/g, '-'); // Replaces spaces with hyphens

    this.props.history.push(`${config.baseUrl}${formattedName}`, {
      state: { artistId: item.id },
    });
  };

  render() {
    const { blog_list, activeTab } = this.state;

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow:
        this.state.artistList.length > 3 ? 4 : this.state.artistList.length,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],

      // responsive: [
      //   {
      //     breakpoint: 600,
      //     settings: {
      //       slidesToShow: 1,
      //       slidesToScroll: 1,
      //       initialSlide: 1,
      //     },
      //   },
      // ],
    };

    const settings1 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow:
        this.state.collectionList.length > 3
          ? 4
          : this.state.collectionList.length,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
      ],
    };

    // Custom left arrow
    const customPrevArrow = (onClickHandler, hasPrev, label) => {
      return (
        hasPrev && (
          <button
            type="button"
            onClick={onClickHandler}
            className="custom-arrow prev-arrow "
          >
            <FaArrowLeft fill="#000" />
            {/* Unicode for left arrow */}
          </button>
        )
      );
    };

    // Custom right arrow
    const customNextArrow = (onClickHandler, hasNext, label) => {
      return (
        hasNext && (
          <button
            type="button"
            onClick={onClickHandler}
            className="custom-arrow next-arrow"
          >
            <FaArrowRight fill="#000" />
          </button>
        )
      );
    };

    return (
      <>
        <div className="body_bg">
          <Helmet>
            <title>{this.state.seoList?.title}</title>
            <meta title={this.state.seoList?.pageTitle} content="IE=edge" />
            <meta
              name="description"
              content={this.state.seoList?.description
                ?.replace(/<[^>]*>?/gm, "")
                .substring(0, 170)}
            />
          </Helmet>
          <Header1 />
          <Popup
            isPopupError={this.state.isPopupError}
            isPopupSuccess={this.state.isPopupSuccess}
            msg={this.state.msg}
            className="me-5"
          />
          {/*---------------------- Banner Section Start -------------------- */}
          <section className="banner">
            <div className="filterone"></div>
            <div className="filtertwo"></div>
            <div className="container-fluid px-0">
              <div className="row">
                <div className="col-lg-12">
                  <Carousel
                    showArrows={true}
                    onChange={this.onChange}
                    showStatus={false} // Disable the status indicator
                    showThumbs={false}
                    renderArrowPrev={customPrevArrow}
                    renderArrowNext={customNextArrow}
                    showIndicators={false}
                    swipeable={true} // Enables swiping (enabled by default)
                    emulateTouch={true} // Improves touch gesture responsiveness
                  >
                    {this.state.getSliderNftListData.map((item) => (
                      <div>
                        <img
                          src={config.imageUrl1 + item.image}
                          alt="Slide 1"
                        />
                        <div className="legend">
                          <div className="px-lg-5">
                            <h5>{item.name}</h5>
                          </div>
                          <hr className="h-line my-2" />
                          <div className="px-lg-5">
                            <div className="row">
                              <div className="col-lg-6 col-md-8">
                                <div className="d-flex">
                                  <div className="mr-5">
                                    <h6>Creator</h6>
                                    <p>{item.creatorName}</p>
                                  </div>
                                  <div className="mr-5">
                                    <h6>
                                      {item.artistType == 0
                                        ? config.currencyType
                                        : config.currencyTypeSep}{" "}
                                      Price
                                    </h6>
                                    <p>{item.price}</p>
                                  </div>
                                  <div>
                                    <h6>Type</h6>
                                    <p>{item.type}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-4 text-right">
                                <div className="">
                                  <button
                                    className="btn btn-default mintbtn border-0"
                                    href="javascript:void(0)" //
                                    onClick={this.collectionLink.bind(this, item)}

                                  >
                                    Mint now
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </div>
                {/* <Fade left>
                  <div className="col-lg-6 col-md-6 col-12 px-lg-3 d-flex align-items-center">
                    <div className="bnrtext">
                      <h1>
                        Enter the new era <br />
                        of collecting art.
                      </h1>
                      <div className="generative">
                        <p>
                          A fully-decentralized marketplace for art and collectibles.
                        </p>
                      </div>
                      <div className="mt-5">
                        <span
                          className="custombtn freemint"
                          style={{ cursor: "pointer" }}
                          onClick={this.loginCheck.bind(this)}
                        >
                          Free Mint Now{" "}
                          <span>
                            <img
                              src="mercafor_dashboard/assets/img/arrowwhite.svg"
                              style={{ width: "10px", height: "12px" }}
                              className="mb-1"
                              alt="arrowwhite"
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </Fade>

                <Fade right>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="bannerimg">
                      <img
                        src="mercafor_dashboard/assets/img/banner_img.webp"
                        className="img-fluid"
                        alt="banner"
                      />
                    </div>
                  </div>
                </Fade> */}
              </div>
            </div>
          </section>
          <section className="collecting_art position-relative py-5">
            <div className="video_art">
              <Bgparticle />
            </div>
            <div className="container px-lg-5">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h2 className="tophead">
                    {" "}
                    Enter the new era of collecting art.
                  </h2>
                  <p className="topheadpera">
                    A fully-decentralized marketplace for art and collectibles.
                  </p>
                  <div className="my-5">
                    <img src={`${config.baseUrl}images/favicon.png`} />
                  </div>
                </div>
              </div>
              <div className="row mt-3 justify-content-center">
                <div className="col-lg-4 col-md-6 mb-3 text-center">
                  <div
                    className="d-flex align-items-center collectBox"
                    style={{ height: "373.348px" }}
                  >
                    <div className="collect-card">
                      <h5 className="mb-0">
                        <b className="text-black position-relative">
                          Decentralised
                        </b>
                      </h5>
                      <div className="my-4">
                        <img
                          src={`${config.baseUrl}images/gif/Decentralised.gif`}
                        />
                      </div>
                      <p className="position-relative">
                        a blockchain-based marketplace for art and collectibles
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-3 text-center">
                  <div
                    className="d-flex align-items-center collectBox"
                    style={{ height: "373.348px" }}
                  >
                    <div className="collect-card">
                      <h5 className="mb-0">
                        <b className="text-black position-relative">Security</b>
                      </h5>
                      <div className="my-4">
                        <img src={`${config.baseUrl}images/gif/Security.gif`} />
                      </div>
                      <p className="position-relative">
                        Secure purchase with smart contract
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-3 text-center">
                  <div
                    className="d-flex align-items-center collectBox"
                    style={{ height: "373.348px" }}
                  >
                    <div className="collect-card">
                      <h5 className="mb-0">
                        <b className="text-black position-relative">
                          Authenticity
                        </b>
                      </h5>
                      <div className="my-4">
                        <img
                          src={`${config.baseUrl}images/gif/Authenticity.gif`}
                        />
                      </div>
                      <p className="position-relative">
                        Resell your collection purchased from our artists
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <div className="col-lg-12 text-center">
                  <button className="text-black bg-transparent border-0" onClick={()=>window.location.href = `${config.baseUrl}whyArtFungible`}>
                    Get More Information
                  </button>
                </div>
              </div>
            </div>
          </section>
          {/*---------------------- Feature Section Start -------------------- */}
          <section className="feature_artworks py-5">
            <div className="container px-lg-5">
              <div className="row">
                <div className="col-12 mb-4">
                  <h2 className="tophead">Featured Artworks</h2>
                  <p className="topheadpera">Selected by our curatorial team</p>
                </div>
              </div>

              <div className="row">
                {this.state.getFeaturedNftListData.slice(0, 8).map((item) => (
                  <div className="col-xl-3 col-lg-6 col-md-6 col-12 mb-4 column-custom">
                    <div className="card feature border p-3 ">
                      <div className="item position-relative">
                        <a
                          href="javascript:void(0)" //
                          onClick={this.collectionLink.bind(this, item)}
                        >
                          <img
                            className="card-img-top"
                            src={config.imageUrl1 + item.image}
                            alt="Jollykoh1"
                          />
                        </a>
                        <div className="card-body pb-0">
                          <p className="card-text mb-0">{item.name}</p>
                          <h5 className="card-title">
                            {" "}
                            <span title="SACRIFICE TO THE HEAVENS">
                              {item.description?.length > MAX_LENGTH ? (
                                <span title={item.description}>
                                  {`${item.description.substring(
                                    0,
                                    MAX_LENGTH
                                  )}...`}
                                </span>
                              ) : (
                                <span>{item.description}</span>
                              )}
                            </span>
                          </h5>
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <h2 className="mb-0">
                                {item.price}{" "}
                                <span className="h5 fw-400">
                                  {item.artistType == 0
                                    ? config.currencyType
                                    : config.currencyTypeSep}
                                </span>
                              </h2>
                            </div>
                            <div>
                              <button
                                className="btn btn-circle"
                                href="javascript:void(0)" //
                                onClick={this.collectionLink.bind(this, item)}
                              >
                                Buy
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
          {/*---------------------- Feature Section End -------------------- */}
          <section className="feature_collection feature_card py-5">
            <div className="container px-lg-5">
              <div className="row">
                <div className="col-12 mb-4">
                  <h2 className="tophead">Featured Collections</h2>
                  <p className="topheadpera">
                    Browse our most favored collections of works
                  </p>
                </div>
              </div>

              <div className="row">
                {this.state.collectionList.slice(0, 8).map((item) => (
                  <div className="col-xl-3 col-lg-6 col-md-6 col-12 mb-4 column-custom">
                    <a
                      href="javascript:void(0)" //
                      onClick={this.collectionLinkFeat.bind(this, item)}


                    >
                      <div className="card feature p-3">
                        <div className="item position-relativ z-4">
                          <img
                            className="card-img-top"
                            src={config.imageUrl1 + item.collectionImage}
                            alt="Jollykoh1"
                          />
                          <div className="card-body">
                            <h5 className="text-black mb-0">
                              {" "}
                              <span title="SACRIFICE TO THE HEAVENS">
                                {item.collectionName?.length >
                                  MAX_LENGTH_NAME ? (
                                  <span title={item.collectionName}>
                                    {`${item.collectionName.substring(
                                      0,
                                      MAX_LENGTH_NAME
                                    )}...`}
                                  </span>
                                ) : (
                                  <span>{item.collectionName}</span>
                                )}{" "}
                                </span>
                            </h5>
                            <p className="">
                              {" "}
                              <span title="SACRIFICE TO THE HEAVENS">
                                {item.collectionDescription?.length >
                                  MAX_LENGTH ? (
                                  <span title={item.collectionDescription}>
                                    {`${item.collectionDescription.substring(
                                      0,
                                      MAX_LENGTH
                                    )}...`}
                                  </span>
                                ) : (
                                  <span>{item.collectionDescription}</span>
                                )}{" "}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/*---------------------- World Section Start -------------------- */}
          <section className="world py-5" id="worldid">
            <div className="container px-lg-5">
              <div className="col-lg-12">
                <div className="row align-items-end">
                  <div className="col-lg-6 col-md-6 col-12 mx-auto mb-4">
                    <h2 className="tophead">Artists</h2>
                    <p className="topheadpera mb-2">
                      World renowned artists from diverse artistic background
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 mx-auto mb-4 text-right">
                    <div className="mb-2">
                      <button
                        className="btn btn-default rounded-0 box-shadow-none"
                        onClick={this.previous}
                      >
                        <FaArrowLeft
                          fill="#000"
                          fillOpacity={`0.5`}
                          size={`24px`}
                        />
                      </button>
                      <button
                        className="btn btn-default rounded-0 box-shadow-none"
                        onClick={this.next}
                      >
                        <FaArrowRight
                          fill="#000"
                          fillOpacity={`0.5`}
                          size={`24px`}
                        />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    {this.state.artistList.length <= 4 &&
                      this.state.artistList.length > 0 ? (
                      <>
                        {this.state.artistList.map((item) => (
                          <div
                            className="col-lg-3 col-md-3 col-3"
                            key={item.id}
                          >
                            <div className="item">
                              <div className="card feature1 border-0">
                                <a
                                  href="javascipt:void(0)"
                                  onClick={this.artistLink.bind(this, item)}
                                  // {
                                  //   `${config.baseUrl}collection/` + item.id
                                  // }
                                >
                                  <img
                                    className="card-img-top"
                                    src={config.imageUrl1 + item.image}
                                    alt="Jollykoh1"
                                  />
                                </a>
                                <div className="card-body">
                                  <p className="card-text"> {item.name}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className="">
                        {this.state.artistList.length === 0 ? (
                          <div className="item">
                            <div className="card feature1 border-0">
                              <div className="cardblock">
                                <h3 className="" style={{ marginLeft: "33%" }}>
                                  No Artist Found
                                </h3>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="">

                          <Slider {...settings} ref={this.sliderRef}>
                            {this.state.artistList.map((item) => (
                              <div className="" key={item.id}>
                                <div className="item">
                                  <div className="card feature1 border-0">
                                    <a
                                      href= "javascript:void(0)"
                                  onClick={this.artistLink.bind(this, item)}

                                      
                                    >
                                      <img
                                        className="card-img-top"
                                        style={{
                                          width: "100%",
                                          height: "400px",
                                        }}
                                        src={config.imageUrl1 + item.image}
                                        alt="Jollykoh1"
                                      />
                                    </a>
                                    <div className="card-body">
                                      <p className="card-text">
                                        {" "}
                                        {item.name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </Slider>

                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*---------------------- World Section End -------------------- */}
          {/*---------------------- Blog Section Start -------------------- */}
          <section className="blog py-5">
            <div className="container px-lg-5">
              <div className="row">
                <div className="col-12 mb-4">
                  <h2 className="tophead">Blog</h2>
                  <p className="topheadpera">Our latest news and articles</p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="tab_style3">
                    {this.state.blog_list.length == 0 ? 
                     <div className="blog-card position-relative">
                     <div
                       className="d-flex justify-content-center"
                       style={{ marginBottom: "-20px" }}
                     >
                       <dotlottie-player
                         src="https://lottie.host/2779598a-ca3c-4fc6-aeb8-25216948d698/L7Y2Nq9xlE.json"
                         background="transparent"
                         speed={1}
                         style={{ width: "200px", height: "200px" }}
                         loop
                         autoPlay
                       />
                     </div>
                     <h2 className="text-center">Sorry, no blogs found!</h2>
</div> :
                    
                    <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="1"
                    activeKey={activeTab}
                  >
                    <Row>
                      <Col lg={5}>
                        <Tab.Content>
                          {this.state.blog_list.slice(0, 3).map((item, index) => (
                            <Tab.Pane
                              key={index}
                              eventKey={(index + 1).toString()}
                            >
                              <div className="blogImg">
                                <img
                                  src={config.imageUrl1 + item.image}
                                  alt={item.title}
                                />
                              </div>
                            </Tab.Pane>
                          ))}
                        </Tab.Content>
                      </Col>
                      <Col lg={7}>
                        <Nav variant="pills" className="flex-column">
                          {this.state.blog_list.slice(0, 3).map((item, index) => (
                            <Nav.Item key={index}>
                              <Nav.Link
                                eventKey={(index + 1).toString()}
                                onMouseEnter={() =>
                                  this.handleMouseEnter(
                                    (index + 1).toString()
                                  )
                                }
                              >
                                <div>
                                  <div className="d-flex justify-content-between align-items-end">
                                    <div>
                                      <h5 style={{ opacity: "0.5" }}>
                                        {item.title}
                                      </h5>
                                    </div>
                                    <div>
                                      <small className="x-small">
                                        {moment(item.date).format(
                                          "MMM DD, YYYY"
                                        )}
                                      </small>
                                    </div>
                                  </div>
                                  <div className="row align-items-end">
                                    <div className="col-lg-9">
                                      <div>
                                        <h5>{item.heading}</h5>
                                        <small className="topheadpara x-small lh-1">
                                          {/* Show first 50 characters initially, and full description when expanded */}
                                          {item.isExpanded
                                            ? item?.shortDescription
                                            : item?.shortDescription?.slice(
                                              0,
                                              150
                                            )}
                                        </small>
                                      </div>
                                    </div>
                                    <div className="col-lg-3 text-right">
                                      <button
                                        className="btn btn-default p-0 box-shadow-none readmore"
                                        style={{ color: "#101111" }}
                                        onClick={() => {
                                          window.location.href = `${config.baseUrl}blogDetail/${item.id}`;
                                          // item.isExpanded = !item.isExpanded; // Toggle the expanded state
                                          // this.forceUpdate(); // Re-render the component
                                        }}
                                      >
                                        {item.isExpanded
                                          ? "Read Less"
                                          : "Read More"}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Nav.Link>
                            </Nav.Item>
                          ))}
                        </Nav>
                      </Col>
                    </Row>
                  </Tab.Container>
                  }
                   
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/*---------------------- Blog Section End -------------------- */}
          {/*---------------------- Free Mint Start -------------------- */}
          <section className="free_mint py-5">
            <div className="container px-lg-5">
              <div className="row">
                <div className="col-lg-12">
                  <div className="tab_style bg-mint p-5">

                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="first"
                    >
                      <Row>
                        <Col xl={3}>
                          <Nav variant="pills" className="">
                            <Nav.Item>
                              <Nav.Link eventKey="first">
                                Free Mint Now
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="second">Membership</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="three">
                                Join Mail List
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                        <Col xl={9}>
                          <Tab.Content>
                            <Tab.Pane eventKey="first">
                              <div className="mt-2">
                                <h5>Mint your first artwork</h5>
                                <div className="content-box">
                                  <p className="mb-3"> Join our community</p>
                                  <img src="images/nft/artwork.png" />
                                </div>
                              </div>
                              <div className="mt-3 text-right">
                                <button className="btn btn-default mintbtn px-4 border-0"  onClick={this.loginCheck.bind(this)}>
                              
                                  Mint Now
                                </button>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                              <div className="mt-2">
                                <h5>Membership Benefits</h5>
                                <div className="content-box">
                                  <p className="mb-5">
                                    Members receive VIP invitation, special
                                    discount, airdrops and all kinds of
                                    privileges
                                  </p>
                                </div>
                              </div>
                              <div className="mt-3 text-right">
                                <button className="btn btn-default mintbtn px-4 border-0">
                                  Details
                                </button>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="three">
                              <div className="mt-2">
                                <h5>
                                  Subscribe to our ultra-exclusive drop list
                                </h5>
                                <div className="content-box">
                                  <p className="mb-5">
                                    Be the first to know about upcoming Art
                                    Fungible drops
                                  </p>

                                  <div className="mt-5 subs_form">
                                    <form>
                                      <div className="form-group arrowfield bg-transparent">
                                        <input
                                          type="email"
                                          className="form-control bg-transparent"
                                          id="exampleInputEmail1"
                                          aria-describedby="emailHelp"
                                          placeholder="Enter your email"
                                          value={this.state.email}
                                          name="email"
                                          onChange={this.onChange}
                                        />
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-3 text-right">
                                <button
                                  className="btn btn-default mintbtn px-4 border-0"
                                  onClick={this.addSubscriberAPI}
                                >
                                  Join Now
                                </button>
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/*---------------------- Faq Section Start -------------------- */}
          <section className="faq py-5">
            <div className="container px-lg-5">
              <div className="row">
                <div className="col-12 mb-4">
                  <h2 className="tophead">FAQ</h2>
                  <p className="topheadpera">
                    Join our discord and ask our team or community members
                  </p>
                </div>
              </div>

              <div className="row">
                <Bounce bottom>
                  <div className="col-lg-8 col-md-12 col-sm-12 col-12 mx-auto">
                    <div
                      className="panel-group"
                      id="accordion"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      {this.state.faqList.map((item, index) => (
                        <div className="panel panel-default mb-3" key={item.id}>
                          <div
                            className="panel-heading"
                            role="tab"
                            id={"headingOne" + (index + 1)}
                          >
                            <h4 className="panel-title">
                              <a
                                role="button"
                                data-toggle="collapse"
                                data-parent="#accordion"
                                href={"#collapseOne" + (index + 1)}
                                aria-expanded="false"
                                aria-controls={"collapseOne" + (index + 1)}
                                style={{ color: "#000" }}
                              >
                                {item.question}
                              </a>
                            </h4>
                          </div>
                          <div
                            id={"collapseOne" + (index + 1)}
                            className="panel-collapse collapse in"
                            role="tabpanel"
                            aria-labelledby={"headingOne" + (index + 1)}
                          >
                            <div className="panel-body pl-lg-3">
                              {item.answer}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Bounce>
              </div>
            </div>
          </section>
          {/*---------------------- World Section End -------------------- */}
          <Footer1 />
        </div>
      </>
    );
  }
}
