import React, { Component } from 'react';
// import Header1 from './directives/header1'
// import Footer1 from './directives/footer1'
// import header1 from '../directives/header1';
import Footer1 from '../directives/footer1';
import Header1 from '../directives/header1';
import axios from 'axios';
// import config from '../config/config'
import config from '../config/config';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
// import Countdown from 'react-countdown';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
// import FlipNumbers from 'react-flip-numbers';
import FlipCountdown from '@rumess/react-flip-countdown';
import Countdown, { zeroPad } from 'react-countdown';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Fade, Slide, Zoom, Bounce } from 'react-reveal';

import Slider from "react-slick";
import { Player } from 'video-react';
import ReactAudioPlayer from 'react-audio-player';
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';


const MAX_LENGTH = 10;

export default class afgenesis2 extends Component {

    render() {

        return (
            <>


                <div className="body_bg">

                    <Header1 />

                    {/*---------------------- Afgenesis Section Start -------------------- */}

                    <section className='afgenesis pt-lg-5 pt-3'>
                        <div className='container-fluid px-lg-5'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12 mb-4'>
                                    <Slide left>
                                        <div className="card afgenesiscard">
                                            <div className="row g-0">
                                                <div className="col-lg-5 col-md-6 col-12">
                                                    <div className='cardblock'>
                                                        <img className="afgenesisimg img-fluid" src="mercafor_dashboard/assets/img/genesis8.webp" alt="genesis8" />
                                                    </div>
                                                    <div className=''>
                                                        <a href='#' className='custombtn genesis'>Membership</a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-7 col-md-6 col-12">
                                                    <div className="card-body px-0 pt-0">
                                                        <div className='genesistext'>
                                                            <h4 className="">AF Genesis</h4>
                                                            <p className='my-3'>AF Genesis 2233</p>
                                                            <h6 className="">0.01 ETH</h6>
                                                        </div>

                                                        <div className='row'>
                                                            <div className='col-lg-7 col-md-6 col-12 pt-3'>
                                                                <form action="#" className='shipp_method'>
                                                                    <div className='mb-2'>
                                                                        <h6>Resale Method</h6>
                                                                    </div>
                                                                    <div className='mb-2'>
                                                                        <input type="radio" id="test1" name="radio-group" checked />
                                                                        <label htmlFor="test1">Auction</label>
                                                                    </div>
                                                                    <div className='mb-2'>
                                                                        <input type="radio" id="test2" name="radio-group" />
                                                                        <label htmlFor="test2" style={{ fontFamily: "Catamaran", fontWeight: "400", color: "#818186" }}>Fixed price</label>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>

                                                        <div className='row'>
                                                            <div className='col-lg-2 col-md-12 col-12 d-flex align-items-center'>
                                                                <span style={{ color: "#818186" }}>Bidding end</span>
                                                            </div>
                                                            <div className='col-lg-5 col-md-12 col-12'>
                                                                <div className='offerprice my-2'>

                                                                    <div className="input-group">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control" style={{ fontFamily: "Catamaran", fontWeight: "500", color: "#101012" }}
                                                                            id="inlineFormInputGroup"
                                                                            placeholder="Jun 17 , 6:30 AM GMT + 8"
                                                                        />
                                                                        <div className="input-group-prepend">
                                                                            <div className="input-group-text">Edit</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='genesischeck mt-3 pt-3'>
                                                            <input
                                                                className="styled-checkbox"
                                                                id="styled-checkbox-1"
                                                                type="checkbox"
                                                                defaultValue="value1"
                                                            />
                                                            <label htmlFor="styled-checkbox-1">I agree with <span>Terms and Conditions</span> and   <span> Privacy Policy </span></label>
                                                            <div className='mt-4'>
                                                                <a href='#' className='custombtn genesis2'>Mint</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12 mb-4'>
                                    <Slide right>
                                        <div className="card afgenesiscard">
                                            <div className="row g-0">
                                                <div className="col-lg-5 col-md-6 col-12">
                                                    <div className='cardblock'>
                                                        <img className="afgenesisimg img-fluid" src="mercafor_dashboard/assets/img/genesis9.webp" alt="genesis9" />
                                                    </div>
                                                    <div className=''>
                                                        <a href='#' className='custombtn genesis'>Digital Artwork</a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-7 col-md-6 col-12">
                                                    <div className="card-body px-0 pt-0">
                                                        <div className='genesistext'>
                                                            <h4 className="">Seasky</h4>
                                                            <p className='my-3'>Seasky #89</p>
                                                            <h6 className="">0.01 ETH</h6>
                                                        </div>

                                                        <div className='row'>
                                                            <div className='col-lg-7 col-md-6 col-12 pt-lg-5 pt-3'>
                                                                <form action="#" className='shipp_method'>
                                                                    <div className='mb-2'>
                                                                        <h6>Resale Method</h6>
                                                                    </div>
                                                                    <div className='mb-2'>
                                                                        <input type="radio" id="test3" name="radio-group" />
                                                                        <label htmlFor="test3">Auction</label>
                                                                    </div>
                                                                    <div className='mb-2'>
                                                                        <input type="radio" id="test4" name="radio-group" checked />
                                                                        <label htmlFor="test4">Fixed price</label>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>

                                                        <div className='genesischeck mt-lg-5 mt-3 pt-3'>
                                                            <input
                                                                className="styled-checkbox"
                                                                id="styled-checkbox-2"
                                                                type="checkbox"
                                                                defaultValue="value1"
                                                            />
                                                            <label htmlFor="styled-checkbox-2" className='mt-3'>I agree with <span>Terms and Conditions</span> and   <span> Privacy Policy </span></label>
                                                            <div className='mt-4'>
                                                                <a href='#' className='custombtn genesis2'>Mint</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12 mb-4'>
                                    <Slide left>
                                        <div className="card afgenesiscard">
                                            <div className="row g-0">
                                                <div className="col-lg-5 col-md-6 col-12">
                                                    <div className='cardblock'>
                                                        <img className="afgenesisimg img-fluid" src="mercafor_dashboard/assets/img/genesis10.webp" alt="genesis10" />
                                                    </div>
                                                    <div className=''>
                                                        <a href='#' className='custombtn genesis'>Physical Artwork</a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-7 col-md-6 col-12">
                                                    <div className="card-body px-0 pt-0">
                                                        <div className='genesistext'>
                                                            <h4 className="">Moon (Physical Collection)</h4>
                                                            <p className='my-3'>Seasky #2235</p>
                                                            <h6 className="">0.01 ETH</h6>
                                                        </div>

                                                        <div className='row'>
                                                            <div className='col-lg-5 col-md-6 col-12 pt-4'>
                                                                <form action="#" className='shipp_method d-flex align-items-center'>
                                                                    <div className='mb-2'>
                                                                        <h6>Holding Type</h6>
                                                                    </div>
                                                                    <div className='mb-2'>
                                                                        {/* <input type="radio" id="test5" name="radio-group" /> */}
                                                                        <label htmlFor="test5">Self Holding</label>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>

                                                        <div className='row'>
                                                            <div className='col-lg-7 col-md-6 col-12 pt-3'>
                                                                <form action="#" className='shipp_method'>
                                                                    <div className='mb-2'>
                                                                        <h6>Resale Method</h6>
                                                                    </div>
                                                                    <div className='mb-2'>
                                                                        <input type="radio" id="test6" name="radio-group" />
                                                                        <label htmlFor="test6">Auction</label>
                                                                    </div>
                                                                    <div className='mb-2'>
                                                                        <input type="radio" id="test7" name="radio-group" checked />
                                                                        <label htmlFor="test7">Fixed price</label>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>

                                                        <div className='genesischeck mt-lg-4 mt-3 pt-4'>
                                                            <input
                                                                className="styled-checkbox"
                                                                id="styled-checkbox-3"
                                                                type="checkbox"
                                                                defaultValue="value1"
                                                            />
                                                            <label htmlFor="styled-checkbox-3" className='mt-2'>I agree with <span>Terms and Conditions</span> and   <span> Privacy Policy </span></label>
                                                            <div className='mt-4'>
                                                                <a href='#' className='custombtn genesis2'>Mint</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/*---------------------- Afgenesis Section End -------------------- */}



                    < Footer1 />
                </div >
            </>
        )
    }
}