const config1= {
  baseUrl: "/artV4/",
  blockchinUrlAddress: "https://testnet.bscscan.com/address/",
  blockchinUrl: "https://testnet.bscscan.com/tx/",
  ipfsUrl: "https://gateway.pinata.cloud/ipfs/",
  currencyType: "BNB",
  currencyTypeSep: "SEPOLIA",

  contractAdd: "0xa15A161C855967dCf8aA7f8fA04353f3929d38DA",
  imageUrl: "https://artfungible.mypinata.cloud/ipfs/",
  // imageUrl1:"http://localhost:8000/api/uploads",
  //  apiUrl : "http://localhost:8000/api/",
  apiUrl: "https://espsofttech.org:1234/api/",

  imageUrl1: "https://espsofttech.org:1234/cp/api/uploads/",
  pinataUrl: "https://artfungible.mypinata.cloud/ipfs/",

  // imageUrl1: "https://artfungible.io/backend/uploads/",
  redirectUrl: "https://artfungible.io/",
  chainId: "97", //"0x5",
  chainIdTx: "0x61", //"0x5",
  chainIdSep: "84532",
  chainIdSepTx: "0x14a34",
  ethRpcUrl: "https://data-seed-prebsc-1-s1.bnbchain.org:8545",
  baseRpcUrl: "https://sepolia.base.org",
  memberShipContract: "0xFc5a9A9B5f39DA52E7Bd27457bB59A235873c618",
  memberShipContractBase: "0x0753ba0251048904d5Ce74C2320A3946FAe219D4",

  marketplaceContractBase: "0x12000A19fc416932B43bFC12551005EB181E95c3",
  memberShipABI: [
    {
      inputs: [
        { internalType: "string", name: "_name", type: "string" },
        { internalType: "string", name: "_symbol", type: "string" },
        { internalType: "string", name: "_initBaseURI", type: "string" },
        { internalType: "string", name: "_initNotRevealedUri", type: "string" },
      ],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "approved",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "operator",
          type: "address",
        },
        {
          indexed: false,
          internalType: "bool",
          name: "approved",
          type: "bool",
        },
      ],
      name: "ApprovalForAll",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "from",
          type: "address",
        },
        { indexed: true, internalType: "address", name: "to", type: "address" },
        {
          indexed: true,
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "Transfer",
      type: "event",
    },
    {
      inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
      name: "Burn",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
      ],
      name: "approve",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "owner", type: "address" }],
      name: "balanceOf",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
      name: "getApproved",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "owner", type: "address" },
        { internalType: "address", name: "operator", type: "address" },
      ],
      name: "isApprovedForAll",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "isSaleStarted",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "wallet", type: "address" },
        { internalType: "bytes32[]", name: "merkleProof", type: "bytes32[]" },
      ],
      name: "isWhitelisted",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "maxSupply",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "merkleTreeRoot",
      outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "bytes32[]", name: "merkleProof", type: "bytes32[]" },
      ],
      name: "mint",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address[]", name: "_address", type: "address[]" },
        { internalType: "uint256[]", name: "_tokenIds", type: "uint256[]" },
      ],
      name: "multiTransfer",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "name",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "nftPerAddressLimit",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
      name: "ownerOf",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "bool", name: "_state", type: "bool" }],
      name: "pause",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "paused",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "remainingNftForSale",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "bool", name: "_reveal", type: "bool" }],
      name: "reveal",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "revealed",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "from", type: "address" },
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
      ],
      name: "safeTransferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "from", type: "address" },
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
        { internalType: "bytes", name: "_data", type: "bytes" },
      ],
      name: "safeTransferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "operator", type: "address" },
        { internalType: "bool", name: "approved", type: "bool" },
      ],
      name: "setApprovalForAll",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "string", name: "_newBaseURI", type: "string" }],
      name: "setBaseURI",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "string", name: "_notRevealedURI", type: "string" },
      ],
      name: "setNotRevealedURI",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "_remainingNftForSale",
          type: "uint256",
        },
      ],
      name: "setRemainingNftForSale",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "bytes32", name: "_root", type: "bytes32" }],
      name: "setRoot",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "startSale",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "stopSale",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
      name: "supportsInterface",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "symbol",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "index", type: "uint256" }],
      name: "tokenByIndex",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "owner", type: "address" },
        { internalType: "uint256", name: "index", type: "uint256" },
      ],
      name: "tokenOfOwnerByIndex",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
      name: "tokenURI",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "totalSupply",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "from", type: "address" },
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
      ],
      name: "transferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "_owner", type: "address" }],
      name: "walletOfOwner",
      outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
      name: "withdraw",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ],
  marketplaceContract: "0xf18c07C54e6b011EBaF3D9C0ca268961d8035fFf",
  abiMarketplace: [
    { inputs: [], stateMutability: "nonpayable", type: "constructor" },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "_buyer",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "royaltyAmount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "commissionAmount",
          type: "uint256",
        },
      ],
      name: "_AcceptBid",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "_bidder",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
      ],
      name: "_Bid",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "_buyer",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "royaltyAmount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "commissionAmount",
          type: "uint256",
        },
      ],
      name: "_BuyNft",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "artist",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "price",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "saleType",
          type: "uint256",
        },
      ],
      name: "_PutNftOnSale",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "_bidder",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
      ],
      name: "_RejectBid",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
      ],
      name: "_RemoveNftFromSale",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_bidder",
          type: "address",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
      ],
      name: "_ReturnBidAmount",
      type: "event",
    },
    { stateMutability: "nonpayable", type: "fallback" },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
      ],
      name: "BuyNft",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
      ],
      name: "acceptBid",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
        { internalType: "uint256", name: "_amount", type: "uint256" },
      ],
      name: "decreaseAmount",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
      ],
      name: "getTokenDetails",
      outputs: [
        {
          components: [
            { internalType: "bool", name: "putOnSale", type: "bool" },
            { internalType: "uint256", name: "price", type: "uint256" },
            { internalType: "uint256", name: "saleType", type: "uint256" },
            { internalType: "uint256", name: "lastBid", type: "uint256" },
            { internalType: "address", name: "lastBidder", type: "address" },
          ],
          internalType: "struct Marketplace.tokenDetails",
          name: "",
          type: "tuple",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
        { internalType: "uint256", name: "_amount", type: "uint256" },
      ],
      name: "increaseAmount",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
        { internalType: "uint256", name: "_price", type: "uint256" },
        { internalType: "uint256", name: "_saleType", type: "uint256" },
      ],
      name: "putNftOnSale",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
      ],
      name: "rejectBid",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
      ],
      name: "removeNftFromSale",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "newPercentage", type: "uint256" },
      ],
      name: "setCommissionPercentageToOwner",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "newCommissionWallet",
          type: "address",
        },
      ],
      name: "setCommissionWallet",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    { stateMutability: "payable", type: "receive" },
  ],
  artistAbi: [
    {
      inputs: [
        { internalType: "string", name: "_name", type: "string" },
        { internalType: "string", name: "_symbol", type: "string" },
        { internalType: "string", name: "_initBaseURI", type: "string" },
        { internalType: "string", name: "_initNotRevealedUri", type: "string" },
        {
          internalType: "address",
          name: "_artistWalletAddress",
          type: "address",
        },
        {
          internalType: "address",
          name: "_membershipcardAddress",
          type: "address",
        },
        { internalType: "address", name: "_marketplace", type: "address" },
        {
          internalType: "address",
          name: "_physicalMarketplace",
          type: "address",
        },
        { internalType: "uint256", name: "_minimumMintPrice", type: "uint256" },
      ],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "approved",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "operator",
          type: "address",
        },
        {
          indexed: false,
          internalType: "bool",
          name: "approved",
          type: "bool",
        },
      ],
      name: "ApprovalForAll",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "redeemer",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "itemId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "nftType",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "string",
          name: "ipfsHash",
          type: "string",
        },
      ],
      name: "NftMint",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "redeemer",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "price",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "refundAmount",
          type: "uint256",
        },
      ],
      name: "RefundExtraAmountForMint",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "from",
          type: "address",
        },
        { indexed: true, internalType: "address", name: "to", type: "address" },
        {
          indexed: true,
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "Transfer",
      type: "event",
    },
    {
      inputs: [
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
      ],
      name: "approve",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "artistWalletAddress",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "owner", type: "address" }],
      name: "balanceOf",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "_price", type: "uint256" },
        {
          internalType: "uint256",
          name: "_royaltyPercentage",
          type: "uint256",
        },
        {
          internalType: "uint256",
          name: "_minimumBidPercentage",
          type: "uint256",
        },
        {
          internalType: "uint256",
          name: "_commissionPercentage",
          type: "uint256",
        },
        {
          internalType: "address payable",
          name: "_commissionAddress",
          type: "address",
        },
        { internalType: "address", name: "_secondOwner", type: "address" },
        { internalType: "string", name: "_imageIpfsHash", type: "string" },
        { internalType: "uint256", name: "_nftType", type: "uint256" },
        { internalType: "uint256", name: "_itemId", type: "uint256" },
        { internalType: "address", name: "_userAddress", type: "address" },
      ],
      name: "directMint",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [],
      name: "discountPercentage",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
      name: "getApproved",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getArtistWalletAddress",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_userAddress", type: "address" },
      ],
      name: "getDiscountAvailable",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_userAddress", type: "address" },
        { internalType: "uint256", name: "_price", type: "uint256" },
      ],
      name: "getDiscountPrice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_tokenId", type: "uint256" }],
      name: "getMinimumBidPercentageOfToken",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getMinimumMintPrice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_tokenId", type: "uint256" }],
      name: "getRoyaltyOfToken",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_tokenId", type: "uint256" }],
      name: "getSecondaryOfToken",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "owner", type: "address" },
        { internalType: "address", name: "operator", type: "address" },
      ],
      name: "isApprovedForAll",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "marketplace",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "membershipcardAddress",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "minimumMintPrice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "name",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "notRevealedUri",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
      name: "ownerOf",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "physicalMarketplace",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "from", type: "address" },
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
      ],
      name: "safeTransferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "from", type: "address" },
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
        { internalType: "bytes", name: "data", type: "bytes" },
      ],
      name: "safeTransferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "operator", type: "address" },
        { internalType: "bool", name: "approved", type: "bool" },
      ],
      name: "setApprovalForAll",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
      name: "setApprovalForAllByMarketplace",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
      name: "setApprovalForAllByPhysicalMarketplace",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "string", name: "_newBaseURI", type: "string" }],
      name: "setBaseURI",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "_newDiscountPercentage",
          type: "uint256",
        },
      ],
      name: "setDiscountPercentage",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_marketplace", type: "address" },
      ],
      name: "setMarketplaceContract",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_membership", type: "address" },
      ],
      name: "setMembershipContract",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "_newMinimumBidPercentage",
          type: "uint256",
        },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
      ],
      name: "setMinimumBidPercentage",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "_newMinimumMintPrice",
          type: "uint256",
        },
      ],
      name: "setMinimumMintPrice",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "string", name: "_notRevealedURI", type: "string" },
      ],
      name: "setNotRevealedURI",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_physicalMarketplace",
          type: "address",
        },
      ],
      name: "setPhysicalMarketplaceContract",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
      name: "supportsInterface",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "symbol",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "index", type: "uint256" }],
      name: "tokenByIndex",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "owner", type: "address" },
        { internalType: "uint256", name: "index", type: "uint256" },
      ],
      name: "tokenOfOwnerByIndex",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
      name: "tokenURI",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "totalSupply",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "from", type: "address" },
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
      ],
      name: "transferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    { stateMutability: "payable", type: "receive" },
  ],
  marketplacePhysicalContract: "0x8ce3810BdAcaBd59c337D6B079fe0E30F82ba868",
  abiplacePhysicalMarketplace: [
    { inputs: [], stateMutability: "nonpayable", type: "constructor" },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_tokenId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_artistAmountPercentage",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_userAmountPercentage",
          type: "uint256",
        },
      ],
      name: "InterventionDispute",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_lastBidder",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_lastBid",
          type: "uint256",
        },
      ],
      name: "_AcceptBid",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_bidder",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
      ],
      name: "_Bid",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_buyer",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_royaltyAmount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_commissionAmount",
          type: "uint256",
        },
      ],
      name: "_BuyNft",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_bidder",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
      ],
      name: "_BuyRequest",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_tokenId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_userAddress",
          type: "address",
        },
      ],
      name: "_DisputeNftConfirmation",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_itemId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_userAddress",
          type: "address",
        },
      ],
      name: "_DisputePhysicalNft",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "itemId",
          type: "uint256",
        },
      ],
      name: "_Intervention",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_tokenId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_itemId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_userAddress",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_amount",
          type: "uint256",
        },
      ],
      name: "_MintPhysicalNft",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_itemId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_userAddress",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_amount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "string",
          name: "_ipfsHash",
          type: "string",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_nftType",
          type: "uint256",
        },
      ],
      name: "_PurchasePhysicalNftRequest",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_owner",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_saleType",
          type: "uint256",
        },
      ],
      name: "_PutNftOnSale",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_bidder",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
      ],
      name: "_RejectBid",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
      ],
      name: "_RemoveNftFromSale",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_bidder",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
      ],
      name: "_ReturnBidAmount",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_tokenId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_userAddress",
          type: "address",
        },
      ],
      name: "_TransferNftToNewUser",
      type: "event",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
      ],
      name: "BuyNft",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
      ],
      name: "acceptBid",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
        { internalType: "bool", name: "_isConfirm", type: "bool" },
      ],
      name: "confirmBuy",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_itemId", type: "uint256" },
        { internalType: "bool", name: "_confirm", type: "bool" },
      ],
      name: "confirmRecieveingPhysicalNft",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
        { internalType: "uint256", name: "_amount", type: "uint256" },
      ],
      name: "decreaseAmount",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_itemId", type: "uint256" },
      ],
      name: "getItemDetails",
      outputs: [
        {
          components: [
            { internalType: "bool", name: "amountRecieved", type: "bool" },
            { internalType: "bool", name: "isMinted", type: "bool" },
            { internalType: "bool", name: "isDispute", type: "bool" },
            { internalType: "uint256", name: "amount", type: "uint256" },
            { internalType: "address", name: "userAddress", type: "address" },
            {
              internalType: "uint256",
              name: "royaltyPercentage",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "minimumBidPercentage",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "commissionPercentage",
              type: "uint256",
            },
            {
              internalType: "address",
              name: "commissionAddress",
              type: "address",
            },
            { internalType: "address", name: "secondOwner", type: "address" },
            { internalType: "string", name: "imageIpfsHash", type: "string" },
            { internalType: "uint256", name: "nftType", type: "uint256" },
          ],
          internalType: "struct PhysicalMarketplace.purchaseDetails",
          name: "",
          type: "tuple",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
      ],
      name: "getTokenDetails",
      outputs: [
        {
          components: [
            { internalType: "bool", name: "putOnSale", type: "bool" },
            { internalType: "uint256", name: "price", type: "uint256" },
            { internalType: "uint256", name: "saleType", type: "uint256" },
            { internalType: "uint256", name: "lastBid", type: "uint256" },
            { internalType: "address", name: "lastBidder", type: "address" },
            { internalType: "uint256", name: "status", type: "uint256" },
            { internalType: "bool", name: "acceptBid", type: "bool" },
            { internalType: "address", name: "tokenOwner", type: "address" },
          ],
          internalType: "struct PhysicalMarketplace.tokenDetails",
          name: "",
          type: "tuple",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
        { internalType: "uint256", name: "_amount", type: "uint256" },
      ],
      name: "increaseAmount",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_itemId", type: "uint256" },
        { internalType: "uint256", name: "_artistPercentage", type: "uint256" },
        { internalType: "uint256", name: "_userPercentage", type: "uint256" },
      ],
      name: "intervention",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
        { internalType: "uint256", name: "_artistPercentage", type: "uint256" },
        { internalType: "uint256", name: "_userPercentage", type: "uint256" },
      ],
      name: "interventionDispute",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "operator", type: "address" },
        { internalType: "address", name: "from", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
        { internalType: "bytes", name: "data", type: "bytes" },
      ],
      name: "onERC721Received",
      outputs: [{ internalType: "bytes4", name: "", type: "bytes4" }],
      stateMutability: "pure",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_itemId", type: "uint256" },
        { internalType: "uint256", name: "_price", type: "uint256" },
        {
          internalType: "uint256",
          name: "_royaltyPercentage",
          type: "uint256",
        },
        {
          internalType: "uint256",
          name: "_minimumBidPercentage",
          type: "uint256",
        },
        {
          internalType: "uint256",
          name: "_commissionPercentage",
          type: "uint256",
        },
        {
          internalType: "address",
          name: "_commissionAddress",
          type: "address",
        },
        { internalType: "address", name: "_secondOwner", type: "address" },
        { internalType: "string", name: "_imageIpfsHash", type: "string" },
      ],
      name: "purchasePhysicalNft",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
        { internalType: "uint256", name: "_price", type: "uint256" },
        { internalType: "uint256", name: "_saleType", type: "uint256" },
      ],
      name: "putNftOnSale",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
      ],
      name: "rejectBid",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
      ],
      name: "removeNftFromSale",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
      ],
      name: "removeNftFromSaleEdit",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "newPercentage", type: "uint256" },
      ],
      name: "setCommissionPercentageToOwner",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "newCommissionWallet",
          type: "address",
        },
      ],
      name: "setCommissionWallet",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    { stateMutability: "payable", type: "receive" },
  ],
};

//===============================   Live with testnet  ===================================================

const config = {
  baseUrl: "/",
  blockchinUrlAddress: "https://etherscan.io/address/",
  blockchinUrlSep: "https://basescan.org/address/",
  blockchinUrltx: "https://basescan.org/tx/",

  blockchinUrl: "https://etherscan.io/tx/",
  ipfsUrl: "https://gateway.pinata.cloud/ipfs/",
  currencyType: "ETH",
  currencyTypeSep: "BASE ETH",

  imageUrl: "https://artfungible.mypinata.cloud/ipfs/",
  imageUrl1: "https://artfungible.io/backend/uploads/",
  apiUrl: "https://api.artfungible.io/api/",
  //  apiUrl : "http://localhost:8000/api/",
  pinataUrl: "https://artfungible.mypinata.cloud/ipfs/",
  redirectUrl: "https://artfungible.io/",
  chainId: "1", //"0x5",
  chainIdTx: "0x1", //"0x5",
  chainIdSep: "8453",
  chainIdSepTx: "0x2105",
  ethRpcUrl: "https://mainnet.infura.io/v3/90a9b762d6934d1690650febab004304",
  baseRpcUrl: "https://mainnet.base.org/",
  memberShipContract: "0xaDD42f079D5f735b8b6f4a1549e46F27a656b021",
  memberShipContractBase: "0x0753ba0251048904d5Ce74C2320A3946FAe219D4",
  marketplaceContractBase: "0xC2a39014335E2c65B1744D650e03038875ee60DB",

  memberShipABI: [
    {
      inputs: [
        { internalType: "string", name: "_name", type: "string" },
        { internalType: "string", name: "_symbol", type: "string" },
        { internalType: "string", name: "_initBaseURI", type: "string" },
        { internalType: "string", name: "_initNotRevealedUri", type: "string" },
      ],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "approved",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "operator",
          type: "address",
        },
        {
          indexed: false,
          internalType: "bool",
          name: "approved",
          type: "bool",
        },
      ],
      name: "ApprovalForAll",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "from",
          type: "address",
        },
        { indexed: true, internalType: "address", name: "to", type: "address" },
        {
          indexed: true,
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "Transfer",
      type: "event",
    },
    {
      inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
      name: "Burn",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
      ],
      name: "approve",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "owner", type: "address" }],
      name: "balanceOf",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
      name: "getApproved",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "owner", type: "address" },
        { internalType: "address", name: "operator", type: "address" },
      ],
      name: "isApprovedForAll",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "isSaleStarted",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "wallet", type: "address" },
        { internalType: "bytes32[]", name: "merkleProof", type: "bytes32[]" },
      ],
      name: "isWhitelisted",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "maxSupply",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "merkleTreeRoot",
      outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "bytes32[]", name: "merkleProof", type: "bytes32[]" },
      ],
      name: "mint",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address[]", name: "_address", type: "address[]" },
        { internalType: "uint256[]", name: "_tokenIds", type: "uint256[]" },
      ],
      name: "multiTransfer",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "name",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "nftPerAddressLimit",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
      name: "ownerOf",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "bool", name: "_state", type: "bool" }],
      name: "pause",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "paused",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "remainingNftForSale",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "bool", name: "_reveal", type: "bool" }],
      name: "reveal",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "revealed",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "from", type: "address" },
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
      ],
      name: "safeTransferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "from", type: "address" },
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
        { internalType: "bytes", name: "_data", type: "bytes" },
      ],
      name: "safeTransferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "operator", type: "address" },
        { internalType: "bool", name: "approved", type: "bool" },
      ],
      name: "setApprovalForAll",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "string", name: "_newBaseURI", type: "string" }],
      name: "setBaseURI",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "string", name: "_notRevealedURI", type: "string" },
      ],
      name: "setNotRevealedURI",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "_remainingNftForSale",
          type: "uint256",
        },
      ],
      name: "setRemainingNftForSale",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "bytes32", name: "_root", type: "bytes32" }],
      name: "setRoot",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "startSale",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "stopSale",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
      name: "supportsInterface",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "symbol",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "index", type: "uint256" }],
      name: "tokenByIndex",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "owner", type: "address" },
        { internalType: "uint256", name: "index", type: "uint256" },
      ],
      name: "tokenOfOwnerByIndex",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
      name: "tokenURI",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "totalSupply",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "from", type: "address" },
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
      ],
      name: "transferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "_owner", type: "address" }],
      name: "walletOfOwner",
      outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
      name: "withdraw",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ],
  marketplaceContract: "0xe5eD8A38b4B5C45A5FFa8E6aAd6E61b69462d661",
  abiMarketplace: [
    { inputs: [], stateMutability: "nonpayable", type: "constructor" },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "_buyer",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "royaltyAmount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "commissionAmount",
          type: "uint256",
        },
      ],
      name: "_AcceptBid",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "_bidder",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
      ],
      name: "_Bid",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "_buyer",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "royaltyAmount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "commissionAmount",
          type: "uint256",
        },
      ],
      name: "_BuyNft",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "artist",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "price",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "saleType",
          type: "uint256",
        },
      ],
      name: "_PutNftOnSale",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "_bidder",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
      ],
      name: "_RejectBid",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
      ],
      name: "_RemoveNftFromSale",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_bidder",
          type: "address",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
      ],
      name: "_ReturnBidAmount",
      type: "event",
    },
    { stateMutability: "nonpayable", type: "fallback" },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
      ],
      name: "BuyNft",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
      ],
      name: "acceptBid",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
        { internalType: "uint256", name: "_amount", type: "uint256" },
      ],
      name: "decreaseAmount",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
      ],
      name: "getTokenDetails",
      outputs: [
        {
          components: [
            { internalType: "bool", name: "putOnSale", type: "bool" },
            { internalType: "uint256", name: "price", type: "uint256" },
            { internalType: "uint256", name: "saleType", type: "uint256" },
            { internalType: "uint256", name: "lastBid", type: "uint256" },
            { internalType: "address", name: "lastBidder", type: "address" },
          ],
          internalType: "struct Marketplace.tokenDetails",
          name: "",
          type: "tuple",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
        { internalType: "uint256", name: "_amount", type: "uint256" },
      ],
      name: "increaseAmount",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
        { internalType: "uint256", name: "_price", type: "uint256" },
        { internalType: "uint256", name: "_saleType", type: "uint256" },
      ],
      name: "putNftOnSale",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
      ],
      name: "rejectBid",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
      ],
      name: "removeNftFromSale",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "newPercentage", type: "uint256" },
      ],
      name: "setCommissionPercentageToOwner",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "newCommissionWallet",
          type: "address",
        },
      ],
      name: "setCommissionWallet",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    { stateMutability: "payable", type: "receive" },
  ],
  artistAbi: [
    {
      inputs: [
        { internalType: "string", name: "_name", type: "string" },
        { internalType: "string", name: "_symbol", type: "string" },
        { internalType: "string", name: "_initBaseURI", type: "string" },
        { internalType: "string", name: "_initNotRevealedUri", type: "string" },
        {
          internalType: "address",
          name: "_artistWalletAddress",
          type: "address",
        },
        {
          internalType: "address",
          name: "_membershipcardAddress",
          type: "address",
        },
        { internalType: "address", name: "_marketplace", type: "address" },
        {
          internalType: "address",
          name: "_physicalMarketplace",
          type: "address",
        },
        { internalType: "uint256", name: "_minimumMintPrice", type: "uint256" },
      ],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "approved",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "operator",
          type: "address",
        },
        {
          indexed: false,
          internalType: "bool",
          name: "approved",
          type: "bool",
        },
      ],
      name: "ApprovalForAll",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "redeemer",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "itemId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "nftType",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "string",
          name: "ipfsHash",
          type: "string",
        },
      ],
      name: "NftMint",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "redeemer",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "price",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "refundAmount",
          type: "uint256",
        },
      ],
      name: "RefundExtraAmountForMint",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "from",
          type: "address",
        },
        { indexed: true, internalType: "address", name: "to", type: "address" },
        {
          indexed: true,
          internalType: "uint256",
          name: "tokenId",
          type: "uint256",
        },
      ],
      name: "Transfer",
      type: "event",
    },
    {
      inputs: [
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
      ],
      name: "approve",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "artistWalletAddress",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "owner", type: "address" }],
      name: "balanceOf",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "_price", type: "uint256" },
        {
          internalType: "uint256",
          name: "_royaltyPercentage",
          type: "uint256",
        },
        {
          internalType: "uint256",
          name: "_minimumBidPercentage",
          type: "uint256",
        },
        {
          internalType: "uint256",
          name: "_commissionPercentage",
          type: "uint256",
        },
        {
          internalType: "address payable",
          name: "_commissionAddress",
          type: "address",
        },
        { internalType: "address", name: "_secondOwner", type: "address" },
        { internalType: "string", name: "_imageIpfsHash", type: "string" },
        { internalType: "uint256", name: "_nftType", type: "uint256" },
        { internalType: "uint256", name: "_itemId", type: "uint256" },
        { internalType: "address", name: "_userAddress", type: "address" },
      ],
      name: "directMint",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [],
      name: "discountPercentage",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
      name: "getApproved",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getArtistWalletAddress",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_userAddress", type: "address" },
      ],
      name: "getDiscountAvailable",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_userAddress", type: "address" },
        { internalType: "uint256", name: "_price", type: "uint256" },
      ],
      name: "getDiscountPrice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_tokenId", type: "uint256" }],
      name: "getMinimumBidPercentageOfToken",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getMinimumMintPrice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_tokenId", type: "uint256" }],
      name: "getRoyaltyOfToken",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_tokenId", type: "uint256" }],
      name: "getSecondaryOfToken",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "owner", type: "address" },
        { internalType: "address", name: "operator", type: "address" },
      ],
      name: "isApprovedForAll",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "marketplace",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "membershipcardAddress",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "minimumMintPrice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "name",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "notRevealedUri",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
      name: "ownerOf",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "physicalMarketplace",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "from", type: "address" },
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
      ],
      name: "safeTransferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "from", type: "address" },
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
        { internalType: "bytes", name: "data", type: "bytes" },
      ],
      name: "safeTransferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "operator", type: "address" },
        { internalType: "bool", name: "approved", type: "bool" },
      ],
      name: "setApprovalForAll",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
      name: "setApprovalForAllByMarketplace",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
      name: "setApprovalForAllByPhysicalMarketplace",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "string", name: "_newBaseURI", type: "string" }],
      name: "setBaseURI",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "_newDiscountPercentage",
          type: "uint256",
        },
      ],
      name: "setDiscountPercentage",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_marketplace", type: "address" },
      ],
      name: "setMarketplaceContract",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_membership", type: "address" },
      ],
      name: "setMembershipContract",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "_newMinimumBidPercentage",
          type: "uint256",
        },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
      ],
      name: "setMinimumBidPercentage",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "_newMinimumMintPrice",
          type: "uint256",
        },
      ],
      name: "setMinimumMintPrice",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "string", name: "_notRevealedURI", type: "string" },
      ],
      name: "setNotRevealedURI",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_physicalMarketplace",
          type: "address",
        },
      ],
      name: "setPhysicalMarketplaceContract",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
      name: "supportsInterface",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "symbol",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "index", type: "uint256" }],
      name: "tokenByIndex",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "owner", type: "address" },
        { internalType: "uint256", name: "index", type: "uint256" },
      ],
      name: "tokenOfOwnerByIndex",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
      name: "tokenURI",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "totalSupply",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "from", type: "address" },
        { internalType: "address", name: "to", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
      ],
      name: "transferFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    { stateMutability: "payable", type: "receive" },
  ],
  marketplacePhysicalContract: "0xdF43862Fbf00311d5005b1F84Da07625C388E6c3",
  abiplacePhysicalMarketplace: [
    { inputs: [], stateMutability: "nonpayable", type: "constructor" },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_tokenId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_artistAmountPercentage",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_userAmountPercentage",
          type: "uint256",
        },
      ],
      name: "InterventionDispute",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_lastBidder",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_lastBid",
          type: "uint256",
        },
      ],
      name: "_AcceptBid",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_bidder",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
      ],
      name: "_Bid",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_buyer",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_royaltyAmount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_commissionAmount",
          type: "uint256",
        },
      ],
      name: "_BuyNft",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_bidder",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
      ],
      name: "_BuyRequest",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_tokenId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_userAddress",
          type: "address",
        },
      ],
      name: "_DisputeNftConfirmation",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_itemId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_userAddress",
          type: "address",
        },
      ],
      name: "_DisputePhysicalNft",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "itemId",
          type: "uint256",
        },
      ],
      name: "_Intervention",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_tokenId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_itemId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_userAddress",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_amount",
          type: "uint256",
        },
      ],
      name: "_MintPhysicalNft",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_itemId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_userAddress",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_amount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "string",
          name: "_ipfsHash",
          type: "string",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_nftType",
          type: "uint256",
        },
      ],
      name: "_PurchasePhysicalNftRequest",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_owner",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_saleType",
          type: "uint256",
        },
      ],
      name: "_PutNftOnSale",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_bidder",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
      ],
      name: "_RejectBid",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
      ],
      name: "_RemoveNftFromSale",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_token",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_bidder",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_price",
          type: "uint256",
        },
      ],
      name: "_ReturnBidAmount",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_artist",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_tokenId",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_userAddress",
          type: "address",
        },
      ],
      name: "_TransferNftToNewUser",
      type: "event",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
      ],
      name: "BuyNft",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
      ],
      name: "acceptBid",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
        { internalType: "bool", name: "_isConfirm", type: "bool" },
      ],
      name: "confirmBuy",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_itemId", type: "uint256" },
        { internalType: "bool", name: "_confirm", type: "bool" },
      ],
      name: "confirmRecieveingPhysicalNft",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
        { internalType: "uint256", name: "_amount", type: "uint256" },
      ],
      name: "decreaseAmount",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_itemId", type: "uint256" },
      ],
      name: "getItemDetails",
      outputs: [
        {
          components: [
            { internalType: "bool", name: "amountRecieved", type: "bool" },
            { internalType: "bool", name: "isMinted", type: "bool" },
            { internalType: "bool", name: "isDispute", type: "bool" },
            { internalType: "uint256", name: "amount", type: "uint256" },
            { internalType: "address", name: "userAddress", type: "address" },
            {
              internalType: "uint256",
              name: "royaltyPercentage",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "minimumBidPercentage",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "commissionPercentage",
              type: "uint256",
            },
            {
              internalType: "address",
              name: "commissionAddress",
              type: "address",
            },
            { internalType: "address", name: "secondOwner", type: "address" },
            { internalType: "string", name: "imageIpfsHash", type: "string" },
            { internalType: "uint256", name: "nftType", type: "uint256" },
          ],
          internalType: "struct PhysicalMarketplace.purchaseDetails",
          name: "",
          type: "tuple",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
      ],
      name: "getTokenDetails",
      outputs: [
        {
          components: [
            { internalType: "bool", name: "putOnSale", type: "bool" },
            { internalType: "uint256", name: "price", type: "uint256" },
            { internalType: "uint256", name: "saleType", type: "uint256" },
            { internalType: "uint256", name: "lastBid", type: "uint256" },
            { internalType: "address", name: "lastBidder", type: "address" },
            { internalType: "uint256", name: "status", type: "uint256" },
            { internalType: "bool", name: "acceptBid", type: "bool" },
            { internalType: "address", name: "tokenOwner", type: "address" },
          ],
          internalType: "struct PhysicalMarketplace.tokenDetails",
          name: "",
          type: "tuple",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
        { internalType: "uint256", name: "_amount", type: "uint256" },
      ],
      name: "increaseAmount",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_itemId", type: "uint256" },
        { internalType: "uint256", name: "_artistPercentage", type: "uint256" },
        { internalType: "uint256", name: "_userPercentage", type: "uint256" },
      ],
      name: "intervention",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
        { internalType: "uint256", name: "_artistPercentage", type: "uint256" },
        { internalType: "uint256", name: "_userPercentage", type: "uint256" },
      ],
      name: "interventionDispute",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "operator", type: "address" },
        { internalType: "address", name: "from", type: "address" },
        { internalType: "uint256", name: "tokenId", type: "uint256" },
        { internalType: "bytes", name: "data", type: "bytes" },
      ],
      name: "onERC721Received",
      outputs: [{ internalType: "bytes4", name: "", type: "bytes4" }],
      stateMutability: "pure",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_itemId", type: "uint256" },
        { internalType: "uint256", name: "_price", type: "uint256" },
        {
          internalType: "uint256",
          name: "_royaltyPercentage",
          type: "uint256",
        },
        {
          internalType: "uint256",
          name: "_minimumBidPercentage",
          type: "uint256",
        },
        {
          internalType: "uint256",
          name: "_commissionPercentage",
          type: "uint256",
        },
        {
          internalType: "address",
          name: "_commissionAddress",
          type: "address",
        },
        { internalType: "address", name: "_secondOwner", type: "address" },
        { internalType: "string", name: "_imageIpfsHash", type: "string" },
      ],
      name: "purchasePhysicalNft",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
        { internalType: "uint256", name: "_price", type: "uint256" },
        { internalType: "uint256", name: "_saleType", type: "uint256" },
      ],
      name: "putNftOnSale",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
      ],
      name: "rejectBid",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
      ],
      name: "removeNftFromSale",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_artist", type: "address" },
        { internalType: "uint256", name: "_token", type: "uint256" },
      ],
      name: "removeNftFromSaleEdit",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "newPercentage", type: "uint256" },
      ],
      name: "setCommissionPercentageToOwner",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "newCommissionWallet",
          type: "address",
        },
      ],
      name: "setCommissionWallet",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    { stateMutability: "payable", type: "receive" },
  ],
};
export default config;
