import React, { Component } from "react";
import config from "../config/config";
import Cookies from "js-cookie";
import axios from "axios";

export default class footer1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerSettingsForUserData: [],
    };
  }
  componentDidMount() {
    this.headerSettingsForUserAction();
  }

  async headerSettingsForUserAction() {
    try {
      await axios({
        method: "get",
        url: `${config.apiUrl}headerSettingsForUser`,
      }).then((response) => {
        this.setState({
          headerSettingsForUserData: response.data.response.filter(
            (item) => item.isActive === 1
          ),
        });
      });
    } catch (err) {}
  }

  render() {
    return (
      <>
        <footer className="footer_part">
          <div className="footer_top">
            <div className="container px-lg-5">
              {/* <div className='row'>
                                <div className='col-12 consectetur px-5 pb-5'>
                                    <h4 className='text-white'>Lorem ipsum dolor sit amet consectetur.</h4>
                                </div>
                            </div> */}
              {/* <div className="row align-items-center pl-2 pr-2">
                <div className="col-12 consectetur px-lg-0 pb-lg-5 pb-3">
                  <h4 className="text-white">
                  Be a part of our community!
                  </h4>
                </div>
              </div> */}
              <div className="row pl-2 pr-2 pt-5">
                <div className="col-lg-12 px-lg-0">
                  <div className="single_footer_part">
                    <a href="#javascript" className="footer_logo_iner">
                      {" "}
                      <img
                        src="images/f-logo.svg"
                        style={{ width: "184px" }}
                        className="navbar-logo"
                        alt="logo"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6 pl-lg-0">
                  <div className="single_footer_part">
                    <p>
                      The premier marketplace for art and cultural collectibles.
                    </p>
                    <div className="footer_icon social_icon">
                      {/* <ul className="list-unstyled" style={{ display: 'none' }}> */}
                      {/* <ul className="list-unstyled">
                                                <li><a href="#" className="single_social_icon"><i className="fab fa-twitter"></i></a></li>
                                                <li><a href="#" className="single_social_icon"><i className="fab fa-instagram"></i></a></li>
                                                <li><a href="#" className="single_social_icon"><i class="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#" className="single_social_icon"><i class="fab fa-discord"></i></a></li>
                                                <li><a href="#" className="single_social_icon"><i className="fab fa-youtube"></i></a></li>
                                                <li><a href="#" className="single_social_icon"><i class="far fa-envelope"></i></a></li>

                                            </ul> */}
                      <ul className="list-unstyled">
                        <li>
                          <a
                            href="https://twitter.com/art_fungible"
                            target="_blank"
                            className="single_social_icon blockicon p-0 m-0"
                          >
                            <img
                              src="mercafor_dashboard/assets/img/twitter.svg"
                              style={{ width: "25px" }}
                              className=""
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/artfungible/"
                            target="_blank"
                            className="single_social_icon blockicon p-0 m-0"
                          >
                            <img
                              src="mercafor_dashboard/assets/img/instagram.svg"
                              style={{ width: "25px" }}
                              className=""
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="single_social_icon blockicon p-0 m-0"
                          >
                            <img
                              src="mercafor_dashboard/assets/img/facebook.svg"
                              style={{ width: "25px" }}
                              className=""
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://discord.com/invite/w673fF44"
                            target="_blank"
                            className="single_social_icon blockicon p-0 m-0"
                          >
                            <img
                              src="mercafor_dashboard/assets/img/discord.svg"
                              style={{ width: "25px" }}
                              className=""
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.youtube.com/channel/UCFvBheY7B9A5HSwukcvmhng"
                            className="single_social_icon blockicon p-0 m-0"
                          >
                            <img
                              src="mercafor_dashboard/assets/img/youtube.svg"
                              style={{ width: "25px" }}
                              className=""
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="single_social_icon blockicon p-0 m-0"
                          >
                            <img
                              src="mercafor_dashboard/assets/img/mail.svg"
                              style={{ width: "25px" }}
                              className=""
                              alt=""
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="col-sm-6 col-lg-2">
                  <div className="single_footer_part">
                    <h4>Help</h4>
                    <ul>
                      
                      <li>
                        <a href={`${config.baseUrl}contact_us`}>Contact Us</a>
                      </li>
                    </ul>
                  </div>
                </div> */}
                <div className="col-sm-12 col-lg-2">
                  {/* <div className="single_footer_part">
                    <h4>Social Link</h4>

                    <ul>
                      <li>
                        <a
                          href="https://twitter.com/art_fungible"
                          target="_blank"
                        >
                          Twitter
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/artfungible/"
                          target="_blank"
                        >
                          Instagram
                        </a>
                      </li>
                      <li>
                        <a href="https://t.me/Artfungible_bot" target="_blank">
                          Telegram
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/channel/UCFvBheY7B9A5HSwukcvmhng"
                          target="_blank"
                        >
                          Youtube
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://discord.com/invite/w673fF44"
                          target="_blank"
                        >
                          Discord
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <div className="col-sm-6 col-lg-2">
                  <div className="single_footer_part">
                    <h4>Quick Link</h4>

                    <ul className="">
                      <li>
                        <a href={`${config.baseUrl}`}> Home</a>
                      </li>
                      <li>
                        <a href={`${config.baseUrl}member`}>Members</a>
                      </li>
                      {/* <li> */}
                      {/* <a href="#">Artists</a> */}
                      {/* </li> */}
                      <li>
                        <a href={`${config.baseUrl}marketplaceNew`}>
                          Marketplace
                        </a>
                      </li>
                      <li>
                        <a href={`${config.baseUrl}blog`}>
                          Blog
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-2">
                  <div className="single_footer_part">
                    <h4>About</h4>
                    <ul>
                      <li>
                        <a href={`${config.baseUrl}whyArtFungible`}>
                          Why Art Fungible
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://privacy.artfungible.io/"
                          // {`${config.baseUrl}privacy_policy`}
                        >
                          Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://terms.artfungible.io/"
                          // {`${config.baseUrl}terms_and_condition`}
                        >
                          Terms and Conditions
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href={`${config.baseUrl}contact_us`}
                          
                        >
                          Contact us
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-lg-12 col-12  pl-2 pr-2">
                  <div className="copyright_text mb-5">
                    <p>© 2021 - 2024 WXY LLC</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}
