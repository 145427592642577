import React, { Component, Fragment } from "react";
import Footer1 from "../directives/footer1";
import Header1 from "../directives/header1";
import axios from "axios";
import config from "../config/config";
import Cookies from "js-cookie";
import { Fade, Slide, Bounce } from "react-reveal";
import DateTimePicker from "react-datetime-picker";
import { getCurrentProvider } from "../components/provider";
import Web3 from "web3";
import { Dialog, Classes } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import BarLoader from "react-bar-loader";
import moment from "moment";
import Popup from "../popup";
import { Helmet } from "react-helmet";
import { Player } from "video-react";
import { ToastContainer, toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
const MAX_LENGTH = 15;

export default class membership extends Component {
  constructor(props) {
    super(props);
    this.options = [
      { value: "0", text: "--Choose an option--" },
      { value: "1", text: "Fixed Price" },
      { value: "2", text: "Bid" },
    ];
    this.loginData = !Cookies.get("artfungibleUserloginSuccess")
      ? []
      : JSON.parse(Cookies.get("artfungibleUserloginSuccess"));
    this.state = {
      userList: "",
      itemListByToken: [],
      allData: "",
      selected: this.options[0].value,
      resalePrice: "",
      startSale: 0,
      endSale: 0,
      optionError: "",
      priceError: "",
      startError: "",
      endError: "",
      bidDataError: "",
      getMybid: [],
      myitembids: [],
      getWishListData: [],
      isDialogOpen: false,
      isPopupError: false,
      isPopupSuccess: false,
      msg: false,
      seoList: "",
      membershipCardBalance: false,
      membershipCardBalance1: false,

      newMembershipAddress: "",
      totalSupply: "",
      totalSupply1 :"",

      memberShipType:"",
      memberShipType1:"",

      tabSelected: 2,
      itemListByTokenPhysicalNFT: [],
      getWishListDataPhysical: [],
      requestForNftData: [],
      myitembidsPhysical: [],
      setOpen1: false,
      trackingNumber: "",
      trackingLink: "",
      dataSaved: "",
      remark: "",
    };
    const {
      match: { params },
    } = this.props;
    this.walletAddress = params.walletAddress;
    this.DateTimePickerSelect = this.DateTimePickerSelect.bind(this);
  }

  componentDidMount() {
    this.getArtistDetailByUserIdAction();
    this.getlistItemByMintAction();
    this.getmyBidItem();
    this.getMyBidsApi();
    this.getMyBidsApiPhysical();
    this.getWishListAction();
    this.listItemByMintPhysicalNFTAction();
    this.getWishListActionPhysical();
    var url = window.location.href;
    var split = url.split("/");
    let lastSecondSegment = split[split.length - 2];
    this.getSeoAPI(lastSecondSegment);
    this.getMembershipCard();
    this.physicalNFTOrders();
    this.getmyBidItemPhysical();
  }

  async getMembershipCard() {
    try {
      // const providers = await getCurrentProvider();
      const web3 = new Web3(config.ethRpcUrl);
      const web31 = new Web3(config.baseRpcUrl);

      const userAddress = this.loginData.data.walletAddress;
      let membershipCardObject = new web3.eth.Contract(
        config.memberShipABI,
        config.memberShipContract
      );
      let membershipCardObject1 = new web31.eth.Contract(
        config.memberShipABI,
        config.memberShipContractBase
      );
      const balanceOf = await membershipCardObject.methods
        .balanceOf(userAddress)
        .call();
      const totalSupply = await membershipCardObject.methods
        .totalSupply()
        .call();

        const balanceOf1 = await membershipCardObject1.methods
        .balanceOf(userAddress)
        .call();
      const totalSupply1 = await membershipCardObject1.methods
        .totalSupply()
        .call();

      if (parseInt(balanceOf) > 0) {
        this.setState({
          membershipCardBalance: true,
          totalSupply: totalSupply,
          memberShipType:"ETH"
        });
      }
      if (parseInt(balanceOf1) > 0) {
        this.setState({
          membershipCardBalance1: true,
          totalSupply1: totalSupply1,
          memberShipType1:"BASE"
        });
      }

    } catch (error) {}
  }

  async transferMembershipCard(e) {
    e.preventDefault();

    try {
      // const providers = await getCurrentProvider();
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(config.chainIdTx) }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: Web3.utils.toHex(config.chainIdTx),
                  chainName: "ETH",
                  rpcUrls: [`${config.ethRpcUrl}`],
                  nativeCurrency: {
                    name: "ETH",
                    symbol: "ETH",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://etherscan.io/"],
                },
              ],
            });
          } catch (addError) {
            console.error(addError);
          }
        }
      }
      const web3 = new Web3(config.ethRpcUrl);
      const userAddress = this.loginData.data.walletAddress;
      let membershipCardObject = new web3.eth.Contract(
        config.memberShipABI,
        config.memberShipContract
      );
      const balanceOf = await membershipCardObject.methods
        .balanceOf(userAddress)
        .call();
      if (balanceOf > 0) {
        let tokenOfOwnerByIndex = await membershipCardObject.methods
          .tokenOfOwnerByIndex(userAddress, 0)
          .call();
        let safeTransferFrom =
          await membershipCardObject.methods.safeTransferFrom(
            userAddress,
            this.state.newMembershipAddress,
            tokenOfOwnerByIndex
          );
        this.setState({
          isDialogOpen: true,
        });
        let encoded_tx = safeTransferFrom.encodeABI();

        let gasPrice = await web3.eth.getGasPrice();

        let gasLimit = await web3.eth.estimateGas({
          gasPrice: web3.utils.toHex(gasPrice),
          to: config.memberShipContract,
          from: userAddress,
          data: encoded_tx,
          // value: price,
        });
        let trx = await web3.eth.sendTransaction({
          gasPrice: web3.utils.toHex(gasPrice),
          gas: web3.utils.toHex(gasLimit),
          to: config.memberShipContract,
          from: userAddress,
          data: encoded_tx,
          //value: price,
        });
        if (trx.transactionHash) {
          setTimeout(() => {
            window.location.reload();
          }, 500);
          //   await this.removeNFTFromSaleApi(trx.transactionHash, contractAddres,
          //     tokenId);
        }
      } else {
        // alert("do not have any memebership card");
      }
    } catch (error) {}
  }

  async transferMembershipCardBase(e) {
    e.preventDefault();

    try {
      // const providers = await getCurrentProvider();

      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(config.chainIdSepTx) }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: Web3.utils.toHex(config.chainIdSepTx),
                  chainName: "Base Sepolia",
                  rpcUrls: [`${config.baseRpcUrl}`],
                  nativeCurrency: {
                    name: "ETH",
                    symbol: "ETH",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://etherscan.io/"],
                },
              ],
            });
          } catch (addError) {
            console.error(addError);
          }
        }
      }

      const web3 = new Web3(config.baseRpcUrl);
      const userAddress = this.loginData.data.walletAddress;
      let membershipCardObject = new web3.eth.Contract(
        config.memberShipABI,
        config.memberShipContractBase
      );
      const balanceOf = await membershipCardObject.methods
        .balanceOf(userAddress)
        .call();
      if (balanceOf > 0) {
        let tokenOfOwnerByIndex = await membershipCardObject.methods
          .tokenOfOwnerByIndex(userAddress, 0)
          .call();
        let safeTransferFrom =
          await membershipCardObject.methods.safeTransferFrom(
            userAddress,
            this.state.newMembershipAddress,
            tokenOfOwnerByIndex
          );
        this.setState({
          isDialogOpen: true,
        });
        let encoded_tx = safeTransferFrom.encodeABI();

        let gasPrice = await web3.eth.getGasPrice();

        let gasLimit = await web3.eth.estimateGas({
          gasPrice: web3.utils.toHex(gasPrice),
          to: config.memberShipContractBase,
          from: userAddress,
          data: encoded_tx,
          // value: price,
        });
        let trx = await web3.eth.sendTransaction({
          gasPrice: web3.utils.toHex(gasPrice),
          gas: web3.utils.toHex(gasLimit),
          to: config.memberShipContractBase,
          from: userAddress,
          data: encoded_tx,
          //value: price,
        });
        if (trx.transactionHash) {
          setTimeout(() => {
            window.location.reload();
          }, 500);
          //   await this.removeNFTFromSaleApi(trx.transactionHash, contractAddres,
          //     tokenId);
        }
      } else {
        // alert("do not have any memebership card");
      }
    } catch (error) {}
  }

  async getSeoAPI(lastSecondSegment) {
    await axios({
      method: "post",
      url: `${config.apiUrl}/getSEO`,
      data: { pageName: lastSecondSegment },
    })
      .then(async (response) => {
        if (response.data.success === true) {
          this.setState({
            seoList: response.data.data,
          });
        } else if (response.data.success === false) {
        }
      })
      .catch((err) => {});
  }

  async getmyBidItem() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}newBudsOnmyItem`,
        data: { user_id: this.loginData.data.id },
        headers: { Authorization: this.loginData.Token },
      }).then((response) => {
        if (response.data.success === true) {
          this.setState({
            myitembids: response.data.data,
          });
        } else {
          this.setState({
            myitembids: [],
          });
        }
      });
    } catch (err) {
      this.setState({
        myitembids: [],
      });
    }
  }

  async getmyBidItemPhysical() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}newBudsOnmyItemPhysical`,
        data: { user_id: this.loginData.data.id },
        headers: { Authorization: this.loginData.Token },
      }).then((response) => {
        if (response.data.success === true) {
          this.setState({
            myitembidsPhysical: response.data.data.filter(
              (item) => item.bid_id != null
            ),
          });
        } else {
          this.setState({
            myitembidsPhysical: [],
          });
        }
      });
    } catch (err) {
      this.setState({
        myitembidsPhysical: [],
      });
    }
  }

  async getMyBidsApi() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}getMybidsAPI`,
        data: { user_id: this.loginData.data.id },
        headers: { Authorization: this.loginData.Token },
      }).then((response) => {
        if (response.data.success === true) {
          this.setState({
            getMybid: response.data.data,
          });
        } else {
          this.setState({
            getMybid: [],
          });
        }
      });
    } catch (err) {
      this.setState({
        getMybid: [],
      });
    }
  }

  async getMyBidsApiPhysical() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}getMybidsAPIPhysical`,
        data: { user_id: this.loginData.data.id },
        headers: { Authorization: this.loginData.Token },
      }).then((response) => {
        if (response.data.success === true) {
          this.setState({
            getMybidPhysical: response.data.data,
          });
        } else {
          this.setState({
            getMybidPhysical: [],
          });
        }
      });
    } catch (err) {
      this.setState({
        getMybidPhysical: [],
      });
    }
  }

  async getArtistDetailByUserIdAction() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}getuserprofile`,
        data: { walletAddress: this.walletAddress },
        headers: { Authorization: this.loginData.Token },
      }).then((response) => {
        this.setState({
          userList: response.data.response,
        });
      });
    } catch (err) {}
  }

  async getlistItemByMintAction() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}listItemByMint`,
        data: { user_id: this.loginData.data.id },
        headers: { Authorization: this.loginData.Token },
      }).then((response) => {
        if (response.data.success === true) {
          this.setState({
            itemListByToken: response.data.response,
          });
          // const uniqueIds = new Set(array.map((obj) => obj.id));

          // // Iterate over unique ids
          // uniqueIds.forEach((id) => {
          //   // Find the last object for current id
          //   const idObjects = array.reverse().find((obj) => obj.id === id);

          //   this.setState({
          //     itemListByToken: [idObjects],
          //   });
          // });
        } else {
          this.setState({
            itemListByToken: [],
          });
        }
      });
    } catch (err) {
      this.setState({
        itemListByToken: [],
      });
    }
  }

  async listItemByMintPhysicalNFTAction() {
    // console.log('fffffffffffff',this.loginData.data.walletAddress)
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}listItemByMintPhysicalNFT`,
        data: { user_id: this.loginData.data.id },
        headers: { Authorization: this.loginData.Token },
      }).then((response) => {
        if (response.data.success === true) {
          this.setState({
            itemListByTokenPhysicalNFT: response.data.response.filter(
              (item) =>
                item.owner_address == "" ||
                item.owner_address.toUpperCase() ==
                  this.loginData.data.walletAddress.toUpperCase()
            ),
          });
          // const uniqueIds = new Set(array.map((obj) => obj.id));

          // // Iterate over unique ids
          // uniqueIds.forEach((id) => {
          //   // Find the last object for current id
          //   const idObjects = array.reverse().find((obj) => obj.id === id);

          //   this.setState({
          //     itemListByToken: [idObjects],
          //   });
          // });
        } else {
          this.setState({
            itemListByTokenPhysicalNFT: [],
          });
        }
      });
    } catch (err) {
      this.setState({
        itemListByTokenPhysicalNFT: [],
      });
    }
  }

  async getitemId(item) {
    this.setState({
      allData: item,
    });
  }

  DateTimePickerSelect(e) {
    let d = e.value;
    const timestamp = new Date(d).getTime();

    if (e.name == "start_date") {
      this.setState({ startSale: timestamp });
      this.setState({ startError: "" });
    } else {
      this.setState({ endSale: timestamp });
      this.setState({ endError: "" });
    }

    this.setState({
      [e.name]: d,
    });
  }
  async onChangeSaleType(e) {
    this.setState({ optionError: "" });
    let value = e.target.value;
    this.setState({ selected: value });
  }

  handleChange1 = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  async onChangeResaleAmount(e) {
    this.setState({ priceError: "" });

    let value = e.target.value;

    this.setState({ resalePrice: value });
  }
  async onChangenewMembershipAddress(e) {
    let value = e.target.value;

    this.setState({ newMembershipAddress: value });
  }
  bidIncreasingChange = (e) => {
    this.setState({ bidDataError: "" });

    this.setState((prevState) => ({
      allData: {
        ...prevState.allData,
        [e.target.name]: e.target.value,
      },
    }));
  };

  async putOnSale(e) {
    e.preventDefault();

    const rpcUrl =
      this.state.allData.artistType == 0 ? config.ethRpcUrl : config.baseRpcUrl;
    if (this.state.allData.artistType == 0) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(config.chainIdTx) }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: Web3.utils.toHex(config.chainIdTx),
                  chainName: "ETH",
                  rpcUrls: [`${rpcUrl}`],
                  nativeCurrency: {
                    name: "ETH",
                    symbol: "ETH",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://etherscan.io/"],
                },
              ],
            });
          } catch (addError) {
            console.error(addError);
          }
        }
      }
    } else if (this.state.allData.artistType == 1) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(config.chainIdSepTx) }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: Web3.utils.toHex(config.chainIdSepTx),
                  chainName: "Base Sepolia",
                  rpcUrls: [`${rpcUrl}`],
                  nativeCurrency: {
                    name: "ETH",
                    symbol: "ETH",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://etherscan.io/"],
                },
              ],
            });
          } catch (addError) {
            console.error(addError);
          }
        }
      }
    }
    try {
      const providers = await getCurrentProvider();
      const web3 = new Web3(window.ethereum);

      let verify = await this.verification();

      if (verify) {
        this.setState({
          isDialogOpen: true,
        });
        const user_id = this.loginData.data.id;
        const id = this.state.allData.id;
        const artist = this.state.allData.contract;
        const tokenId = this.state.allData.token_id;
        let price1 = this.state.resalePrice;

        let price = (price1 * 10 ** 18).toLocaleString("fullwide", {
          useGrouping: false,
        });
        const saleType = this.state.selected;
        const startAuction = this.state.startSale;
        const endAuction = this.state.endSale;
        const userAddress = this.loginData.data.walletAddress;
        let apiSelect =
          this.state.allData.physicalNFTType == 1
            ? `${config.apiUrl}updateNFTTimePhysical`
            : `${config.apiUrl}updateNFTTime`;

        await axios({
          method: "post",
          url: apiSelect,
          data: {
            id: this.state.allData.id,
            expiry_date: endAuction,
            start_date: startAuction,
            sell_type: saleType,
            bid_increasing_amount: this.state.allData.bid_increasing_amount,
            price: price1,
          },
          headers: { Authorization: this.loginData.Token },
        });

        const bidSetContract = new web3.eth.Contract(
          config.artistAbi,
          this.state.allData.contract
        );

        let getMinimumBidPercentageOfToken = await bidSetContract.methods
          .getMinimumBidPercentageOfToken(tokenId)
          .call();
        getMinimumBidPercentageOfToken = parseInt(
          getMinimumBidPercentageOfToken
        );
        // minimum bid percentage agr change hua h to

        //   (this.state.allData.bid_increasing_amount * 1000 !=
        //   getMinimumBidPercentageOfToken) && this.state.allData.saleType ==2
        // )
        if (
          this.state.allData.bid_increasing_amount * 1000 !=
            getMinimumBidPercentageOfToken &&
          this.state.selected == 2
        ) {
          let putNftOnSale =
            await bidSetContract.methods.setMinimumBidPercentage(
              (this.state.allData.bid_increasing_amount * 100).toString(),
              tokenId
            );

          let encoded_tx = putNftOnSale.encodeABI();

          let gasPrice = await web3.eth.getGasPrice();

          let gasLimit = await web3.eth.estimateGas({
            gasPrice: web3.utils.toHex(gasPrice),
            to: this.state.allData.contract,
            from: userAddress,
            data: encoded_tx,
          });

          let trx = await web3.eth.sendTransaction({
            gasPrice: web3.utils.toHex(gasPrice),
            gas: web3.utils.toHex(gasLimit),
            to: this.state.allData.contract,
            from: userAddress,
            data: encoded_tx,
          });

          if (trx) {
            let isApprovedForAll = await bidSetContract.methods
              .isApprovedForAll(userAddress, config.marketplaceContract)
              .call();
            if (!isApprovedForAll) {
              let setApprovalForAll = await bidSetContract.methods.approve(
                config.marketplaceContract,
                tokenId
              );

              let encoded_tx = setApprovalForAll.encodeABI();

              let gasPrice = await web3.eth.getGasPrice();

              let gasLimit = await web3.eth.estimateGas({
                gasPrice: web3.utils.toHex(gasPrice),
                to: this.state.allData.contract,
                from: userAddress,
                data: encoded_tx,
              });

              let trx1 = await web3.eth.sendTransaction({
                gasPrice: web3.utils.toHex(gasPrice),
                gas: web3.utils.toHex(gasLimit),
                to: this.state.allData.contract,
                from: userAddress,
                data: encoded_tx,
              });

              if (trx1.transactionHash) {
                const marketplaceContract = new web3.eth.Contract(
                  this.state.allData.physicalNFTType == 1
                    ? config.abiplacePhysicalMarketplace
                    : config.abiMarketplace,
                  this.state.allData.physicalNFTType == 1
                    ? config.marketplacePhysicalContract
                    : this.state.allData.artistType == 1
                    ? config.marketplaceContractBase
                    : config.marketplaceContract
                );

                let putNftOnSale =
                  await marketplaceContract.methods.putNftOnSale(
                    artist,
                    tokenId,
                    price,
                    saleType
                  );

                let encoded_tx1 = putNftOnSale.encodeABI();

                let gasPrice1 = await web3.eth.getGasPrice();

                let gasLimit1 = await web3.eth.estimateGas({
                  gasPrice: web3.utils.toHex(gasPrice1),
                  to:
                    this.state.allData.physicalNFTType == 1
                      ? config.marketplacePhysicalContract
                      : this.state.allData.artistType == 1
                      ? config.marketplaceContractBase
                      : config.marketplaceContract,
                  from: userAddress,
                  data: encoded_tx1,
                });

                let trx = await web3.eth.sendTransaction({
                  gasPrice: web3.utils.toHex(gasPrice),
                  gas: web3.utils.toHex(gasLimit1),
                  to:
                    this.state.allData.physicalNFTType == 1
                      ? config.marketplacePhysicalContract
                      : this.state.allData.artistType == 1
                      ? config.marketplaceContractBase
                      : config.marketplaceContract,
                  from: userAddress,
                  data: encoded_tx1,
                });

                if (trx.transactionHash) {
                  let data = {
                    id: id,
                    user_id: user_id,
                    amount: price1,
                    start_date: startAuction,
                    expiry_date: endAuction,
                    sell_type: saleType,
                    hash: trx.transactionHash,
                    address: userAddress,
                    bid_increasing_amount:
                      this.state.allData.bid_increasing_amount,
                  };
                  // await this.putOnSaleApi(data);
                  // alert("1");
                  this.setState({
                    isPopupSuccess: true,
                    msg: "Nft Resold Successfully",
                  });
                  setTimeout(() => {
                    this.setState({
                      isPopupSuccess: false,
                      isDialogOpen: false,
                    });
                  }, 1000);
                  setTimeout(() => {
                    window.location.href = `${config.baseUrl}membership/${this.loginData.data.walletAddress}`;
                  }, 1000);
                }
                return;
              }
            }

            const marketplaceContract = new web3.eth.Contract(
              config.abiMarketplace,
              config.marketplaceContract
            );

            let putNftOnSale = await marketplaceContract.methods.putNftOnSale(
              artist,
              tokenId,
              price,
              saleType
            );

            let encoded_tx = putNftOnSale.encodeABI();

            let gasPrice = await web3.eth.getGasPrice();

            let gasLimit = await web3.eth.estimateGas({
              gasPrice: web3.utils.toHex(gasPrice),
              to:
                this.state.allData.physicalNFTType == 1
                  ? config.marketplacePhysicalContract
                  : config.marketplaceContract,
              from: userAddress,
              data: encoded_tx,
            });

            let trx = await web3.eth.sendTransaction({
              gasPrice: web3.utils.toHex(gasPrice),
              gas: web3.utils.toHex(gasLimit),
              to:
                this.state.allData.physicalNFTType == 1
                  ? config.marketplacePhysicalContract
                  : config.marketplaceContract,
              from: userAddress,
              data: encoded_tx,
            });

            if (trx.transactionHash) {
              let data = {
                id: id,
                user_id: user_id,
                amount: price1,
                start_date: startAuction,
                expiry_date: endAuction,
                sell_type: saleType,
                hash: trx.transactionHash,
                address: userAddress,
                bid_increasing_amount: this.state.allData.bid_increasing_amount,
              };
              // alert("2");
              this.setState({
                isPopupSuccess: true,
                msg: "Nft Resold Successfully",
              });
              setTimeout(() => {
                this.setState({
                  isPopupSuccess: false,
                  isDialogOpen: false,
                });
              }, 1000);
              setTimeout(() => {
                window.location.href = `${config.baseUrl}membership/${this.loginData.data.walletAddress}`;
              }, 1000);
              // await this.putOnSaleApi(data);
            }
          }
          return;
        }

        let isApprovedForAll = await bidSetContract.methods
          .isApprovedForAll(
            userAddress,
            this.state.allData.physicalNFTType == 1
              ? config.marketplacePhysicalContract
              : this.state.allData.artistType == 1
              ? config.marketplaceContractBase
              : config.marketplaceContract
          )
          .call();

        // aprroved nhi hoga

        if (!isApprovedForAll) {
          let setApprovalForAll = await bidSetContract.methods.approve(
            this.state.allData.physicalNFTType == 1
              ? config.marketplacePhysicalContract
              : this.state.allData.artistType == 1
              ? config.marketplaceContractBase
              : config.marketplaceContract,
            tokenId
          );

          let encoded_tx1 = setApprovalForAll.encodeABI();

          let gasPrice1 = await web3.eth.getGasPrice();

          let gasLimit1 = await web3.eth.estimateGas({
            gasPrice: web3.utils.toHex(gasPrice1),
            to: this.state.allData.contract,
            from: userAddress,
            data: encoded_tx1,
          });

          let trx1 = await web3.eth.sendTransaction({
            gasPrice: web3.utils.toHex(gasPrice1),
            gas: web3.utils.toHex(gasLimit1),
            to: this.state.allData.contract,
            from: userAddress,
            data: encoded_tx1,
          });

          if (trx1.transactionHash) {
            const marketplaceContract = new web3.eth.Contract(
              this.state.allData.physicalNFTType == 1
                ? config.abiplacePhysicalMarketplace
                : config.abiMarketplace,
              this.state.allData.physicalNFTType == 1
                ? config.marketplacePhysicalContract
                : this.state.allData.artistType == 1
                ? config.marketplaceContractBase
                : config.marketplaceContract
            );

            let putNftOnSale = await marketplaceContract.methods.putNftOnSale(
              artist,
              tokenId,
              price,
              saleType
            );

            let encoded_tx = putNftOnSale.encodeABI();

            let gasPrice = await web3.eth.getGasPrice();

            let gasLimit = await web3.eth.estimateGas({
              gasPrice: web3.utils.toHex(gasPrice),
              to:
                this.state.allData.physicalNFTType == 1
                  ? config.marketplacePhysicalContract
                  : this.state.allData.artistType == 1
                  ? config.marketplaceContractBase
                  : config.marketplaceContract,
              from: userAddress,
              data: encoded_tx,
            });

            let trx = await web3.eth.sendTransaction({
              gasPrice: web3.utils.toHex(gasPrice),
              gas: web3.utils.toHex(gasLimit),
              to:
                this.state.allData.physicalNFTType == 1
                  ? config.marketplacePhysicalContract
                  : this.state.allData.artistType == 1
                  ? config.marketplaceContractBase
                  : config.marketplaceContract,
              from: userAddress,
              data: encoded_tx,
            });

            if (trx.transactionHash) {
              let data = {
                id: id,
                user_id: user_id,
                amount: price1,
                start_date: startAuction,
                expiry_date: endAuction,
                sell_type: saleType,
                hash: trx.transactionHash,
                address: userAddress,
                bid_increasing_amount: this.state.allData.bid_increasing_amount,
              };
              // alert("3");
              this.setState({
                isPopupSuccess: true,
                msg: "Nft Resold Successfully",
              });
              setTimeout(() => {
                this.setState({
                  isPopupSuccess: false,
                  isDialogOpen: false,
                });
              }, 1000);
              setTimeout(() => {
                window.location.href = `${config.baseUrl}membership/${this.loginData.data.walletAddress}`;
              }, 1000);
              // await this.putOnSaleApi(data);
            }
            return;
          }
        }
        // approved and minimum chnage nhi hoga

        const marketplaceContract = new web3.eth.Contract(
          this.state.allData.physicalNFTType == 1
            ? config.abiplacePhysicalMarketplace
            : config.abiMarketplace,
          this.state.allData.physicalNFTType == 1
            ? config.marketplacePhysicalContract
            : this.state.allData.artistType == 1
            ? config.marketplaceContractBase
            : config.marketplaceContract
        );

        let putNftOnSale = await marketplaceContract.methods.putNftOnSale(
          artist,
          tokenId,
          price,
          saleType
        );

        let encoded_tx = putNftOnSale.encodeABI();

        let gasPrice = await web3.eth.getGasPrice();

        let gasLimit = await web3.eth.estimateGas({
          gasPrice: web3.utils.toHex(gasPrice),
          to:
            this.state.allData.physicalNFTType == 1
              ? config.marketplacePhysicalContract
              : this.state.allData.artistType == 1
              ? config.marketplaceContractBase
              : config.marketplaceContract,
          from: userAddress,
          data: encoded_tx,
        });

        let trx = await web3.eth.sendTransaction({
          gasPrice: web3.utils.toHex(gasPrice),
          gas: web3.utils.toHex(gasLimit),
          to:
            this.state.allData.physicalNFTType == 1
              ? config.marketplacePhysicalContract
              : this.state.allData.artistType == 1
              ? config.marketplaceContractBase
              : config.marketplaceContract,
          from: userAddress,
          data: encoded_tx,
        });
        if (trx.transactionHash) {
          let data = {
            id: id,
            user_id: user_id,
            amount: price1,
            start_date: startAuction,
            expiry_date: endAuction,
            sell_type: saleType,
            hash: trx.transactionHash,
            address: userAddress,
            bid_increasing_amount: this.state.allData.bid_increasing_amount,
          };
          // await this.putOnSaleApi(data);
          // alert("4");
          this.setState({
            isPopupSuccess: true,
            msg: "Nft Resold Successfully",
          });
          setTimeout(() => {
            this.setState({
              isPopupSuccess: false,
              isDialogOpen: false,
            });
          }, 1000);
          setTimeout(() => {
            window.location.href = `${config.baseUrl}membership/${this.loginData.data.walletAddress}`;
          }, 1000);
        }
      }
    } catch (error) {
      this.setState({
        isDialogOpen: false,
      });

      let err = error.data ? error.data.message : error.message;

      this.setState({
        isPopupError: true,
        msg: err,
      });
      setTimeout(() => {
        this.setState({
          isPopupError: false,
        });
      }, 2000);
    }
  }

  async verification() {
    let optionError = "";
    let priceError = "";
    let startError = "";
    let endError = "";
    let bidDataError = "";

    const currentTimestamp = new Date().getTime();
    if (this.state.selected == 0) {
      optionError = "Please select an resale option.";
      this.setState({
        isDialogOpen: false,
      });
    }
    if (this.state.resalePrice == 0) {
      priceError = "Please set resale price higher then 0";
      this.setState({
        isDialogOpen: false,
      });
    }
    if (this.state.selected == 2) {
      if (this.state.startSale < currentTimestamp) {
        startError = "Start time is not correct. Please select higher date";
        this.setState({
          isDialogOpen: false,
        });
      }
      if (this.state.endSale < currentTimestamp) {
        endError = "End time is not correct. Please select higher date";
        this.setState({
          isDialogOpen: false,
        });
      }

      if (this.state.endSale <= this.state.startSale) {
        endError = "End time is not correct. Please select higher date";
        this.setState({
          isDialogOpen: false,
        });
      }
    }
    if (this.state.allData.bid_increasing_amount == 0) {
      bidDataError = "Please set Bid Percentage higher then 0";
      this.setState({
        isDialogOpen: false,
      });
    }

    if (
      optionError.length > 0 ||
      priceError.length > 0 ||
      startError.length > 0 ||
      endError.length > 0 ||
      bidDataError.length > 0
    ) {
      this.setState({ optionError: optionError });
      this.setState({ priceError: priceError });
      this.setState({ startError: startError });
      this.setState({ endError: endError });
      this.setState({ bidDataError: bidDataError });

      return false;
    }

    this.setState({ optionError: optionError });
    this.setState({ priceError: priceError });
    this.setState({ startError: startError });
    this.setState({ endError: endError });
    this.setState({ bidDataError: bidDataError });

    return true;
  }

  async putOnSaleApi(data) {
    this.setState({
      isDialogOpen: true,
    });
    try {
      // resaleItemPhysical
      await axios({
        method: "post",
        url: `${
          this.state.allData.physicalNFTType == 1
            ? config.apiUrl + "resaleItemPhysicalClone"
            : config.apiUrl + "resaleItem"
        }`,
        headers: { Authorization: this.loginData.Token },
        data: data,
      }).then((response) => {
        if (response.data.success === true) {
          this.setState({
            isPopupSuccess: true,
            msg: "Nft Resold Successfully",
          });
          setTimeout(() => {
            this.setState({
              isPopupSuccess: false,
              isDialogOpen: false,
            });
          }, 1000);
          setTimeout(() => {
            window.location.href = `${config.baseUrl}membership/${this.loginData.data.walletAddress}`;
          }, 1000);
        } else {
          this.setState({
            isPopupError: true,
            msg: response.data.msg,
          });
          setTimeout(() => {
            this.setState({
              isPopupError: false,
            });
          }, 2000);
        }
      });
    } catch (error) {
      this.setState({
        isDialogOpen: false,
      });
    }
  }

  async AcceptOrRejectBid(e, result, item) {
    e.preventDefault();
    try {
      var bid_id = item.bid_id;
      var artist = item.contract;
      var tokenId = item.token_id;
      var itemId = item.id;
      const providers = await getCurrentProvider();
      const web3 = new Web3(providers);

      const userAddress = this.loginData.data.walletAddress;

      const user_id = this.loginData.data.id;
      const rpcUrl =
        item.artistType == 1 ? config.baseRpcUrl : config.ethRpcUrl;
      if (item.artistType == 1) {
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: Web3.utils.toHex(config.chainIdSepTx) }],
          });
        } catch (switchError) {
          if (switchError.code === 4902) {
            try {
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: Web3.utils.toHex(config.chainIdSepTx),
                    chainName: "Base Sepolia",
                    rpcUrls: [`${rpcUrl}`],
                    nativeCurrency: {
                      name: "ETH",
                      symbol: "ETH",
                      decimals: 18,
                    },
                    blockExplorerUrls: ["https://etherscan.io/"],
                  },
                ],
              });
            } catch (addError) {
              console.error(addError);
            }
          }
        }
      } else {
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: Web3.utils.toHex(config.chainIdTx) }],
          });
        } catch (switchError) {
          if (switchError.code === 4902) {
            try {
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: Web3.utils.toHex(config.chainIdTx),
                    chainName: "ETH",
                    rpcUrls: [`${rpcUrl}`],
                    nativeCurrency: {
                      name: "ETH",
                      symbol: "ETH",
                      decimals: 18,
                    },
                    blockExplorerUrls: ["https://etherscan.io/"],
                  },
                ],
              });
            } catch (addError) {
              console.error(addError);
            }
          }
        }
      }

      const marketplaceContract = new web3.eth.Contract(
        item.physicalNFTType == 1
          ? config.abiplacePhysicalMarketplace
          : config.abiMarketplace,
        item.physicalNFTType == 1
          ? config.marketplacePhysicalContract
          : item.artistType == 1
          ? config.marketplaceContractBase
          : config.marketplaceContract
      );
      this.setState({
        isDialogOpen: true,
      });
      let transactionData;
      if (result == 1) {
        transactionData = await marketplaceContract.methods.acceptBid(
          artist,
          tokenId
        );
      } else {
        transactionData = await marketplaceContract.methods.rejectBid(
          artist,
          tokenId
        );
      }

      let encoded_tx = transactionData.encodeABI();

      let gasPrice = await web3.eth.getGasPrice();

      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to:
          item.physicalNFTType == 1
            ? config.marketplacePhysicalContract
            : item.artistType == 1
            ? config.marketplaceContractBase
            : config.marketplaceContract,
        from: userAddress,
        data: encoded_tx,
      });

      let trx = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to:
          item.physicalNFTType == 1
            ? config.marketplacePhysicalContract
            : item.artistType == 1
            ? config.marketplaceContractBase
            : config.marketplaceContract,
        from: userAddress,
        data: encoded_tx,
      });

      if (trx.transactionHash) {
        let data = {
          user_id: user_id,
          bid_id: bid_id,
          item_id: itemId,
          transfer_hash: trx.transactionHash,
          result: result,
          physicalNft: item.physicalNFTType,
          sellerAddress: this.loginData.data.walletAddress,
        };
        this.setState({
          isPopupSuccess: true,
          msg: result == 1 ? "Bid is accepted" : "Bid is rejected",
        });
        setTimeout(() => {
          this.setState({
            isPopupSuccess: false,
          });
        }, 2000);

        setTimeout(() => {
          window.location.href = `${config.baseUrl}membership/${this.loginData.data.walletAddress}`;
        }, 2500);

        // item.physicalNFTType == 1
        //   ? await this.callApiPhysical(data)
        //   : await this.callApi(data);
      }
    } catch (error) {
      let err = error.data ? error.data.message : error.message;

      this.setState({
        isPopupError: true,
        msg: err,
      });
      setTimeout(() => {
        this.setState({
          isPopupError: false,
        });
      }, 2000);
      this.setState({
        isDialogOpen: false,
      });
    }
  }

  async callApi(data) {
    try {
      let result;
      if (data.result == 1) {
        result = "bidAccept";
      } else {
        result = "bidReject";
      }
      await axios({
        method: "post",
        url: `${config.apiUrl}${result}`,
        headers: { Authorization: this.loginData.Token },
        data: data,
      }).then((response) => {
        if (response.data.success === true) {
          this.setState({
            isPopupSuccess: true,
            msg: response.data.msg,
          });
          setTimeout(() => {
            this.setState({
              isPopupSuccess: false,
            });
          }, 2000);

          setTimeout(() => {
            window.location.href = `${config.baseUrl}membership/${this.loginData.data.walletAddress}`;
          }, 2500);
        } else {
          this.setState({
            isPopupError: true,
            msg: response.data.msg,
          });
          setTimeout(() => {
            this.setState({
              isPopupError: false,
            });
          }, 2000);
        }
      });
    } catch (error) {}
  }
  async callApiPhysical(data) {
    try {
      let result;
      if (data.result == 1) {
        result = "bidAcceptPhysical";
      } else {
        result = "bidRejectPhysical";
      }
      await axios({
        method: "post",
        url: `${config.apiUrl}${result}`,
        headers: { Authorization: this.loginData.Token },
        data: data,
      }).then((response) => {
        if (response.data.success === true) {
          this.setState({
            isPopupSuccess: true,
            msg: response.data.msg,
          });
          setTimeout(() => {
            this.setState({
              isPopupSuccess: false,
            });
          }, 2000);

          setTimeout(() => {
            window.location.href = `${config.baseUrl}membership/${this.loginData.data.walletAddress}`;
          }, 2500);
        } else {
          this.setState({
            isPopupError: true,
            msg: response.data.msg,
          });
          setTimeout(() => {
            this.setState({
              isPopupError: false,
            });
          }, 2000);
        }
      });
    } catch (error) {}
  }
  async getWishListActionPhysical() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}listWishlistPhysical`,
        data: { user_id: this.loginData.data.id },
        headers: { Authorization: this.loginData.Token },
      }).then((response) => {
        this.setState({
          getWishListDataPhysical: response.data.data,
        });
      });
    } catch (err) {
      this.setState({
        getWishListDataPhysical: [],
      });
    }
  }
  async getWishListAction() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}listWishlist`,
        data: { user_id: this.loginData.data.id },
        headers: { Authorization: this.loginData.Token },
      }).then((response) => {
        this.setState({
          getWishListData: response.data.data,
        });
      });
    } catch (err) {
      this.setState({
        getWishListData: [],
      });
    }
  }

  async getWishListActionPhysical() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}listWishlistPhysical`,
        data: { user_id: this.loginData.data.id },
        headers: { Authorization: this.loginData.Token },
      }).then((response) => {
        this.setState({
          getWishListDataPhysical: response.data.data,
        });
      });
    } catch (err) {
      this.setState({
        getWishListDataPhysical: [],
      });
    }
  }

  async onOpenModal1(record, deliveryStatus) {
    this.setState({
      dataSaved: record,
      deliveryStatusData: deliveryStatus,
      setOpen1: true,
    });
  }
  async onCloseModal1() {
    this.setState({
      setOpen1: false,
      dataSaved: "",
      deliveryStatusData: "",
    });
  }

  async physicalNFTOrders() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}requestForNft`,
        data: {
          user_id: this.loginData.data.id,
          address: this.loginData.data.walletAddress,
        },
        headers: { Authorization: this.loginData.Token },
      }).then((response) => {
        if (response.data.success == true) {
          this.setState({
            requestForNftData: response.data.response,
          });
        } else {
          this.setState({
            requestForNftData: [],
          });
        }
      });
    } catch (err) {
      this.setState({
        requestForNftData: [],
      });
    }
  }

  async removeWishListAction(item) {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}removeWishlist`,
        data: {
          user_id: this.loginData.data.id,
          wishlist_id: item.wishlist_id,
        },
        headers: { Authorization: this.loginData.Token },
      }).then((response) => {
        this.setState({
          isPopupSuccess: true,
          msg: response.data.msg,
        });
        setTimeout(() => {
          this.setState({
            isPopupSuccess: false,
          });
        }, 2000);
        this.getWishListAction();
      });
    } catch (err) {}
  }

  async openPhysicalNft(item) {
    window.open(`${config.baseUrl}physicalnftview/${item.id}`);
  }

  async tabClick(type) {
    this.setState({
      tabSelected: type,
    });
  }

  async confirmPhysicalNft(item, statusType) {
    const rpcUrl = item.artistType == 1 ? config.baseRpcUrl : config.ethRpcUrl;
    if (item.artistType == 1) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(config.chainIdSepTx) }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: Web3.utils.toHex(config.chainIdSepTx),
                  chainName: "Base Sepolia",
                  rpcUrls: [`${rpcUrl}`],
                  nativeCurrency: {
                    name: "ETH",
                    symbol: "ETH",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://etherscan.io/"],
                },
              ],
            });
          } catch (addError) {
            console.error(addError);
          }
        }
      }
    } else {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(config.chainIdTx) }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: Web3.utils.toHex(config.chainIdTx),
                  chainName: "ETH",
                  rpcUrls: [`${rpcUrl}`],
                  nativeCurrency: {
                    name: "ETH",
                    symbol: "ETH",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://etherscan.io/"],
                },
              ],
            });
          } catch (addError) {
            console.error(addError);
          }
        }
      }
    }
    confirmAlert({
      title: "Confirm to submit",
      message: `${
        statusType == 1
          ? "Are you sure that you have recieved the NFT."
          : "Are you sure that you dispute this NFT?"
      } `,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const providers = await getCurrentProvider();
              const web3 = new Web3(providers);
              var currentNetwork = web3.currentProvider.chainId;
              let balance = await web3.eth.getBalance(
                this.loginData.data.walletAddress
              );
              this.setState({
                isDialogOpen: true,
              });

              let marketPlaceContractObject = new web3.eth.Contract(
                config.abiplacePhysicalMarketplace,
                config.marketplacePhysicalContract
              );

              let directMint;

              if (item.firstPurchase == 0) {
                directMint =
                  await marketPlaceContractObject.methods.confirmRecieveingPhysicalNft(
                    item.contract,
                    item.id,
                    statusType == 1 ? true : false
                  );
              } else if (item.firstPurchase == 1) {
                directMint = await marketPlaceContractObject.methods.confirmBuy(
                  item.contract,
                  item.token_id,
                  statusType == 1 ? true : false
                );
              }
              let encoded_tx = directMint.encodeABI();

              let gasPrice = await web3.eth.getGasPrice();
              let gasLimit = await web3.eth.estimateGas({
                gasPrice: web3.utils.toHex(gasPrice),
                to: config.marketplacePhysicalContract,
                from: this.loginData.data.walletAddress,
                data: encoded_tx,
                // value: exprice,
              });

              let trx = await web3.eth.sendTransaction({
                gasPrice: web3.utils.toHex(gasPrice),
                gas: web3.utils.toHex(gasLimit),
                to: config.marketplacePhysicalContract,
                from: this.loginData.data.walletAddress,
                data: encoded_tx,
                // value: exprice,
              });
              if (trx) {
                let artistContractObject = new web3.eth.Contract(
                  config.artistAbi,
                  item.contract
                );
                let tokenId;
                let balanceOfNFT;
                if (item.firstPurchase == 0 && statusType == 1) {
                  balanceOfNFT = await artistContractObject.methods
                    .balanceOf(this.loginData.data.walletAddress)
                    .call();
                  tokenId = await artistContractObject.methods
                    .tokenOfOwnerByIndex(
                      this.loginData.data.walletAddress,
                      parseInt(balanceOfNFT) - 1
                    )
                    .call();
                }

                // axios({
                //   method: "post",
                //   url: `${config.apiUrl}changeStatusPhysicalNFT`,
                //   headers: { Authorization: this.loginData.Token },
                //   data: {
                //     user_id: this.loginData.data.id,
                //     orderdeliveryStatus: statusType == 1 ? 2 : 3,
                //     item_id: item.id,
                //     token_id: item.firstPurchase == 0 ? tokenId : item.token_id,
                //   },
                // })
                //   .then((res) => {
                // this.componentDidMount()
                this.setState({
                  isPopupSuccess: true,
                  isDialogOpen: false,
                  msg: "Status Updated successfully!!",
                });
                setTimeout(() => {
                  this.setState({
                    isPopupSuccess: false,
                  });
                }, 2000);
                setTimeout(() => {
                  window.location.reload();
                }, 3000);
                // this.componentDidMount()
                // })
                // .catch((error) => {});
              }
            } catch (err) {
              if (err.message.toString().split("insufficient funds")[1]) {
                this.setState({
                  isPopupError: true,
                  msg: "Transaction reverted : " + err.message,
                });
                setTimeout(() => {
                  this.setState({
                    isPopupError: false,
                  });
                }, 2000);
              } else {
                if (err.toString().split("execution reverted:")[1]) {
                  this.setState({
                    isPopupError: true,
                    msg:
                      "Transaction reverted : " +
                      err
                        .toString()
                        .split("execution reverted:")[1]
                        .toString()
                        .split("{")[0],
                  });
                  setTimeout(() => {
                    this.setState({
                      isPopupError: false,
                    });
                  }, 2000);
                } else {
                  this.setState({
                    isPopupError: true,
                    msg: err.message,
                  });
                  setTimeout(() => {
                    this.setState({
                      isPopupError: false,
                    });
                  }, 2000);
                }
              }
              this.setState({
                isDialogOpen: false,
              });
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  copieBtn() {
    toast.success(`Copied!!`);
  }

  async deliveredStatusChanged(data, status) {
    this.setState({
      setOpen1: false,
    });
    try {
      confirmAlert({
        title: "Confirm to submit",
        message: `You want to ${
          status === 1
            ? "Sent"
            : data.deliveryStatus === 2
            ? "Confirm"
            : data.deliveryStatus === 3
            ? "Intervenue"
            : ""
        } this Order!`,

        buttons: [
          {
            label: "Yes",
            onClick: () => {
              axios({
                method: "post",
                url: `${config.apiUrl}/mintedOrderedPhysicalStatusChange`,
                data: {
                  owner_id: data.owner_id,
                  deliveryStatus: status,
                  item_id: data.item_id,
                  trackingNumber: this.state.trackingNumber,
                  trackingLink: this.state.trackingLink,
                  remark: this.state.remark,
                },
                headers: { Authorization: this.loginData.Token },
              })
                .then((result) => {
                  this.setState({
                    isPopupSuccess: true,
                    msg: result.data.msg,
                  });
                  setTimeout(() => {
                    this.setState({
                      isPopupSuccess: false,
                    });
                  }, 2000);
                  setTimeout(() => {
                    window.location.reload();
                  }, 3000);
                })
                .catch((error) => {});
            },
          },
          {
            label: "No",
            onClick: () => {
              if (status == 1) {
                this.setState({
                  setOpen1: true,
                });
              }
            },
          },
        ],
      });
    } catch (error) {}
  }


  collectionLinkFeat = async (item,typeData) => {
    console.log('gggggggggggg',item)
    const formattedCreatorName = item.creatorName.replace(/\s+/g, '-'); // Replaces spaces with hyphens
    const formattedCollectionName = item.collectionName.replace(/\s+/g, '-'); // Replaces spaces with hyphens
    const formattedArtworkName = item.name.replace(/\s+/g, '-'); // Replaces spaces with hyphens


      this.props.history.push(`${config.baseUrl}${formattedCreatorName}/${formattedCollectionName}/${formattedArtworkName}`, {
          state: { itemId: item.itemId,type:typeData },
        });
  };

  render() {
    return (
      <>
        <div className="body_bg">
          <Header1 />
          <ToastContainer />

          <Helmet>
            <title>{this.state.seoList?.title}</title>
            <meta title={this.state.seoList?.pageTitle} content="IE=edge" />
            <meta
              name="description"
              content={this.state.seoList?.description
                ?.replace(/<[^>]*>?/gm, "")
                .substring(0, 170)}
            />
          </Helmet>
          <Dialog
            title="Warning"
            icon="warning-sign"
            className="transaction-process"
            style={{
              color: "red",
              zIndex: "1111",
            }}
            isOpen={this.state.isDialogOpen}
            isCloseButtonShown={false}
          >
            <div className="text-center">
              <BarLoader color="#e84747" height="2" />
              <br />
              <h4 style={{ color: "#000" }}>Transaction under process...</h4>
              <p style={{ color: "red" }}>
                Please do not refresh page or close tab.
              </p>
              <div></div>
            </div>
          </Dialog>
          <Popup
            isPopupError={this.state.isPopupError}
            isPopupSuccess={this.state.isPopupSuccess}
            msg={this.state.msg}
          />
          {/*---------------------- Advantage Section Start -------------------- */}

          <section className="membership mt-lg-5 mt-3">
            <div className="container-fluid px-lg-5">
              {/* <div className='row'>
                        <div className='col-12 text-center mb-4'>
                           <h2 className='tophead'>Feature Collection</h2>
                        </div>
                     </div> */}
              <div className="row">
                <div className="col-12">
                  <div>
                    <img
                      src={
                        this.state.userList?.backGroundImage
                          ? config.imageUrl1 +
                            this.state.userList?.backGroundImage
                          : "mercafor_dashboard/assets/img/memberback.webp"
                      }
                      alt="profileimg"
                      style={{
                        height: "250px",
                        // width: "1188px",
                      }}
                      className="memberback"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                  {/* <div className="cameraico">
                    <a href="#javascript" className="">
                      {" "}
                      <img
                        src="mercafor_dashboard/assets/img/camera.svg"
                        alt="camera"
                        className="cameraicon"
                      />
                      <div className="input-group mb-3">
                        <div className="custom-file ">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="inputGroupFile01"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="inputGroupFile01"
                          >
                            Choose file
                          </label>
                        </div>
                      </div>
                    </a>
                  </div> */}

                  <div className="profilebox ">
                    <div className="choose_file mb-lg-0 mb-4">
                      {/* <a href="#"> */}
                      <img
                        src={
                          this.state.userList?.image
                            ? config.imageUrl1 + this.state.userList?.image
                            : "https://www.svgrepo.com/show/384674/account-avatar-profile-user-11.svg"
                        }
                        alt="profileimg"
                        style={{
                          height: "191px",
                          width: "191px",
                          borderRadius: "100px",
                        }}
                        className="profileimg"
                      />

                      {/* <div className="input-group mb-3">
                          <div className="custom-file ">
                            <input
                              type="file"
                              onChange={this.handleImagePreview}
                              className="custom-file-input"
                              id="inputGroupFile01"
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="inputGroupFile01"
                            >
                              Choose file
                            </label>
                          </div>
                        </div> */}
                      {/* </a> */}
                    </div>

                    <div
                      className="profiletext ml-lg-3 ml-md-4 text-lg-left text-md-left text-center"
                      style={{ margin: "-16px" }}
                    >
                      <div className="editbox">
                        {/* <h4>Tony Jade </h4> <span><i className="far fa-pen"></i></span> */}
                        {/* <img src="mercafor_dashboard/assets/img/editicon.png" alt="editicon" className="" /> */}
                        <h4>
                          {this.state?.userList?.user_name
                            ? this.state?.userList?.user_name
                            : "Unnamed"}{" "}
                        </h4>{" "}
                        {/* <span>
                          {" "}
                          <img
                            src="mercafor_dashboard/assets/img/editicon.svg"
                            alt="editicon"
                            className=" ml-3"
                          />
                        </span> */}
                      </div>
                      <p
                        style={{ marginBottom: "16px", paddingBottom: "15px" }}
                      >
                        {" "}
                        {this.state?.userList?.description
                          ? this.state?.userList?.description
                          : ""}
                      </p>

                      <a
                        href={`${config.baseUrl}setting`}
                        className="gosetting"
                      >
                        Go to Setting
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/*---------------------- Advantage Section End -------------------- */}

          {/*---------------------- Membership Section Start -------------------- */}

          <section className="membership_tabs py-5 mt-5">
            <div className="container-fluid px-lg-5">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 my-5 pt-5">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="MemberShip-tab"
                        data-toggle="tab"
                        href="#MemberShip"
                        role="tab"
                        aria-controls="MemberShip"
                        aria-selected="true"
                        onClick={this.tabClick.bind(this, 1)}
                      >
                        MemberShip Card
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="home-tab"
                        data-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        onClick={this.tabClick.bind(this, 2)}
                      >
                        Digital Artwork
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="physicalNft-tab"
                        data-toggle="tab"
                        href="#physicalNft"
                        role="tab"
                        aria-controls="physicalNft"
                        aria-selected="true"
                        onClick={this.tabClick.bind(this, 3)}
                      >
                        Physical Artwork
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="contact-tab"
                        data-toggle="tab"
                        href="#wishlist"
                        role="tab"
                        aria-controls="contact"
                        aria-selected="false"
                        onClick={this.tabClick.bind(this, 4)}
                      >
                        Wish List
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              {this.state.tabSelected === 2 ? (
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="false"
                    >
                      Collected
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="profile-tab"
                      data-toggle="tab"
                      href="#profile"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      My Bids
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="contact-tab"
                      data-toggle="tab"
                      href="#contact"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      Bids on my NFTs
                    </a>
                  </li>
                </ul>
              ) : (
                ""
              )}

              {this.state.tabSelected === 3 ? (
                <>
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="collectedPhysical-tab"
                        data-toggle="tab"
                        href="#collectedPhysical"
                        role="tab"
                        aria-controls="collectedPhysical"
                        aria-selected="false"
                      >
                        Collected Physical
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="myPhysicalOrders-tab"
                        data-toggle="tab"
                        href="#myPhysicalOrders"
                        role="tab"
                        aria-controls="myPhysicalBid"
                        aria-selected="false"
                      >
                        Orders
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="myPhysicalBid-tab"
                        data-toggle="tab"
                        href="#myPhysicalBid"
                        role="tab"
                        aria-controls="myPhysicalBid"
                        aria-selected="false"
                      >
                        My Physical NFT Bids
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="bidsOnMyPhysicalNFT-tab"
                        data-toggle="tab"
                        href="#bidsOnMyPhysicalNFT"
                        role="tab"
                        aria-controls="bidsOnMyPhysicalNFT"
                        aria-selected="false"
                      >
                        Bids on my Physical NFTs
                      </a>
                    </li>
                  </ul>
                  {/* //===========================  physical tabs ========================= */}
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12 mb-4">
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="collectedPhysical"
                          role="tabpanel"
                          aria-labelledby="collectedPhysical-tab"
                        >
                          {/* <div className="row">
                        <div className="col-12 mb-4">
                          <h2 className="tophead">Membership</h2>
                        </div>
                      </div> */}
                          <div className="row">
                            {this.state.itemListByTokenPhysicalNFT.length ===
                            0 ? (
                              <div className="col-lg-12 col-md-12 col-12 mb-4">
                                <br />
                                <Fade right>
                                  <div className="card feature">
                                    <h3 className="text-center">
                                    <div className="d-flex justify-content-center" style={{marginBottom: "-20px"}}>
                              <dotlottie-player src="https://lottie.host/2779598a-ca3c-4fc6-aeb8-25216948d698/L7Y2Nq9xlE.json" background="transparent" speed={1} style={{"width":"200px","height":"200px"}} loop autoPlay />
                          </div>
                                      <h2>Sorry, no results found!</h2>

                                      <h5>NO COLLECTED NFT'S</h5>
                                    </h3>
                                  </div>
                                </Fade>
                              </div>
                            ) : (
                              this.state.itemListByTokenPhysicalNFT.map(
                                (item) => {
                                  return (
                                    <div
                                      className="col-lg-3 col-md-6 col-12 mb-4"
                                      key={item.id}
                                    >
                                      <br />
                                      <Fade right>
                                        <div className="card feature">
                                          <div className="cardblock">
                                            {item.file_type === "image" ? (
                                              <a
                                                href={
                                                  config.pinataUrl +
                                                  item.ImageFileHash
                                                }
                                                target="_blank"
                                              >
                                                <img
                                                  className="card-img-top img-fluid"
                                                  src={
                                                    config.imageUrl1 +
                                                    item.image
                                                  }
                                                  alt="membershipimg"
                                                  style={{
                                                    width: "275px",
                                                    height: "320px",
                                                  }}
                                                />
                                              </a>
                                            ) : item.file_type === "video" ? (
                                              <Player
                                                className="card-img-top img-fluid"
                                                style={{
                                                  width: "275px",
                                                  height: "320px",
                                                }}
                                                src={`${config.imageUrl1}${item.image}`}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <div className="card-body">
                                            {/* <p className="card-text d-flex justify-content-between align-items-center">
                                        {item.name}
                                      </p> */}
                                            <div className="d-flex justify-content-between">
                                              <h5 className="card-title">
                                                {/* {item.sell_type === '1'? 'Fix Price':"Auction"} */}
                                                {item.name?.length >
                                                MAX_LENGTH ? (
                                                  <span
                                                    title={item.name}
                                                    className="collab-storename"
                                                  >
                                                    {`${item.name.substring(
                                                      0,
                                                      MAX_LENGTH
                                                    )}...`}
                                                  </span>
                                                ) : (
                                                  <span
                                                    title={item.name}
                                                    className="collab-storename"
                                                  >
                                                    {item.name}
                                                  </span>
                                                )}
                                              </h5>
                                              <div className="timercount">
                                                <h6>
                                                  {item.price}{" "}
                                                  {item.artistType == 1
                                                    ? config.currencyTypeSep
                                                    : config.currencyType}
                                                </h6>
                                              </div>
                                            </div>
                                            {/* 
                                          {this.state.allData.is_on_sale ===
                                          1 ? (
                                            <button
                                              type="button"
                                              data-toggle="modal"
                                              data-target="#exampleModal"
                                              onClick={this.getitemId.bind(
                                                this,
                                                item
                                              )}
                                              className="btn btn-buy-primary w-100 my-3"
                                            >
                                              Resale
                                            </button>
                                          ) : (
                                            <button
                                              type="button"
                                              data-toggle="modal"
                                              data-target="#exampleModal"
                                              onClick={this.getitemId.bind(
                                                this,
                                                item
                                              )}
                                              className="btn btn-buy-primary w-100 my-3"
                                            >
                                              Resale
                                            </button>
                                          )} */}

                                            {item.orderdeliveryStatus == 0 ? (
                                              <div className="text-center mt-2">
                                                <span
                                                  className="freeshipping"
                                                  style={{ color: "red" }}
                                                >
                                                  {" "}
                                                  Not Sent
                                                </span>
                                                <div
                                                  className="col-md-12"
                                                  style={{ marginTop: "18px" }}
                                                >
                                                  <button
                                                    type="button"
                                                    onClick={this.confirmPhysicalNft.bind(
                                                      this,
                                                      item,
                                                      2
                                                    )}
                                                    className="btn btn-buy-primary w-100 my-3"
                                                  >
                                                    Dispute
                                                  </button>
                                                </div>
                                              </div>
                                            ) : item.orderdeliveryStatus ==
                                              1 ? (
                                              <>
                                                <div className="text-center mt-2">
                                                  <span
                                                    className="freeshipping"
                                                    style={{ color: "green" }}
                                                  >
                                                    {" "}
                                                    NFT Delivered <br />
                                                    Tracking Link:{" "}
                                                    <a
                                                      href={item.trackingLink}
                                                      target="_blank"
                                                    >
                                                      {item.trackingLink}
                                                    </a>
                                                  </span>
                                                </div>
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <button
                                                      type="button"
                                                      onClick={this.confirmPhysicalNft.bind(
                                                        this,
                                                        item,
                                                        1
                                                      )}
                                                      className="btn btn-buy-primary w-100 my-3"
                                                    >
                                                      Confirm
                                                    </button>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <button
                                                      type="button"
                                                      onClick={this.confirmPhysicalNft.bind(
                                                        this,
                                                        item,
                                                        2
                                                      )}
                                                      className="btn btn-buy-primary w-100 my-3"
                                                    >
                                                      Dispute
                                                    </button>
                                                  </div>
                                                </div>
                                              </>
                                            ) : item.orderdeliveryStatus ==
                                              2 ? (
                                              <button
                                                type="button"
                                                data-toggle="modal"
                                                data-target="#exampleModal"
                                                onClick={this.getitemId.bind(
                                                  this,
                                                  item
                                                )}
                                                className="btn btn-buy-primary w-100 my-3"
                                              >
                                                Resale
                                              </button>
                                            ) : item.orderdeliveryStatus ==
                                              3 ? (
                                              <div className="text-center mt-2">
                                                <span
                                                  className="freeshipping"
                                                  style={{ color: "red" }}
                                                >
                                                  {" "}
                                                  Dispute
                                                </span>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </Fade>
                                    </div>
                                  );
                                }
                              )
                            )}
                          </div>
                        </div>

                        <div
                          className="tab-pane fade"
                          id="myPhysicalOrders"
                          role="tabpanel"
                          aria-labelledby="myPhysicalOrders-tab"
                        >
                          <section className="itematable py-lg-5 pt-3">
                            <div className="container-fluid px-lg-5">
                              <div className="row">
                                <div className="col-lg-8 col-md-12 col-12 mx-auto">
                                  <Bounce top>
                                    <div className="table-responsive itemscontent">
                                      <table className="table table-hover mb-lg-0">
                                        <thead>
                                          <tr>
                                            <th>Sr. No.</th>
                                            <th>Artwork Name</th>

                                            <th>Ordered By</th>
                                            <th>Shipping State</th>
                                            <th>Shipping Address</th>
                                            <th>Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.requestForNftData
                                            ?.length > 0 ? (
                                            this.state.requestForNftData?.map(
                                              (record, index) => (
                                                <tr key={record.id}>
                                                  <td>{index + 1}</td>
                                                  <td>{record.itemName}</td>
                                                  <td>
                                                    <>
                                                      {record?.buyerAddress?.substring(
                                                        0,
                                                        4
                                                      ) +
                                                        "...." +
                                                        record?.buyerAddress?.substring(
                                                          record?.buyerAddress
                                                            ?.length - 4
                                                        )}
                                                      &nbsp;{" "}
                                                      <CopyToClipboard
                                                        text={
                                                          record.buyerAddress
                                                        }
                                                      >
                                                        <span
                                                          title="Click to Copy"
                                                          className="mr-copylink"
                                                          id="token-buy-button"
                                                          onClick={
                                                            this.copieBtn
                                                          }
                                                          style={{
                                                            cursor: "pointer",
                                                            color:
                                                              "rgb(157 81 255)",
                                                          }}
                                                        >
                                                          <i className="fa fa-copy "></i>
                                                        </span>
                                                      </CopyToClipboard>
                                                    </>
                                                  </td>

                                                  <td>
                                                    <div>
                                                      {
                                                        <>
                                                          {record.deliveryStatus ==
                                                          0 ? (
                                                            <>
                                                              <p
                                                                style={{
                                                                  color: "red",
                                                                }}
                                                              >
                                                                Not Sent
                                                              </p>
                                                              <div
                                                                className="col-md-12"
                                                                style={{
                                                                  marginTop:
                                                                    "18px",
                                                                }}
                                                              >
                                                                <button
                                                                  type="button"
                                                                  onClick={this.confirmPhysicalNft.bind(
                                                                    this,
                                                                    record,
                                                                    2
                                                                  )}
                                                                  className="btn btn-buy-primary w-100 my-3"
                                                                >
                                                                  Dispute
                                                                </button>
                                                              </div>
                                                            </>
                                                          ) : record.deliveryStatus ==
                                                            1 ? (
                                                            <p
                                                              style={{
                                                                color: "green",
                                                              }}
                                                            >
                                                              Sent
                                                            </p>
                                                          ) : record.deliveryStatus ==
                                                            2 ? (
                                                            <p
                                                              style={{
                                                                color: "green",
                                                              }}
                                                            >
                                                              Confirmed
                                                            </p>
                                                          ) : record.deliveryStatus ==
                                                            3 ? (
                                                            <p
                                                              style={{
                                                                color: "red",
                                                              }}
                                                            >
                                                              Disputed
                                                            </p>
                                                          ) : record.deliveryStatus ==
                                                            4 ? (
                                                            <p
                                                              style={{
                                                                color: "red",
                                                              }}
                                                            >
                                                              Reject
                                                            </p>
                                                          ) : (
                                                            "N/A"
                                                          )}
                                                        </>
                                                      }
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {record.address},
                                                    {record.apartment
                                                      ? record.apartment + ","
                                                      : ""}
                                                    <br />
                                                    {record.city},
                                                    {record.postCode},
                                                    {record.countryName}
                                                    <br />
                                                    {record.phoneNumber}
                                                  </td>
                                                  <td>
                                                    <Fragment>
                                                      <div className="dropdown actionbutton">
                                                        <div className="dropdown-content">
                                                          {record.deliveryStatus ==
                                                          0 ? (
                                                            <a href="javascript:void(0)">
                                                              <button
                                                                className=" btn-danger"
                                                                onClick={this.onOpenModal1.bind(
                                                                  this,
                                                                  record,
                                                                  1
                                                                )}
                                                                data-toggle="tooltip"
                                                                data-original-title="Close"
                                                              >
                                                                <i
                                                                  className="fa fa-hand-peace-o"
                                                                  aria-hidden="true"
                                                                ></i>{" "}
                                                                Sent By Owner{" "}
                                                              </button>
                                                            </a>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </div>
                                                      </div>
                                                    </Fragment>
                                                  </td>
                                                </tr>
                                              )
                                            )
                                          ) : (
                                            <tr>
                                              <td
                                                colSpan="7"
                                                className="text-center"
                                              >
                                                No Order Found
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </Bounce>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>

                        <div
                          className="tab-pane fade"
                          id="myPhysicalBid"
                          role="tabpanel"
                          aria-labelledby="myPhysicalBid-tab"
                        >
                          <section className="itematable py-lg-5 pt-3">
                            <div className="container-fluid px-lg-5">
                              <div className="row">
                                <div className="col-lg-8 col-md-12 col-12 mx-auto">
                                  <Bounce top>
                                    <div className="table-responsive itemscontent">
                                      <table className="table table-hover mb-lg-0">
                                        <thead>
                                          <tr>
                                            <th>Sr. No.</th>
                                            <th>Image</th>

                                            <th>Bid Price</th>
                                            <th>Date And Time</th>
                                            <th>Status</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.getMybidPhysical.length >
                                          0 ? (
                                            this.state.getMybidPhysical.map(
                                              (item, index) => (
                                                <tr key={item.id}>
                                                  <td>{index + 1}</td>
                                                  <td>{item.name}</td>
                                                  <td>
                                                    {item.bid_price}&nbsp;
                                                    {item.artistType == 0
                                                      ? config.currencyType
                                                      : config.currencyTypeSep}
                                                  </td>
                                                  <td>
                                                    {moment(
                                                      item.datetime
                                                    ).format("LL")}
                                                  </td>
                                                  <td>
                                                    {item.status == 3
                                                      ? "Over Bid"
                                                      : item.status == 0
                                                      ? "Pending"
                                                      : item.status == 1
                                                      ? "Accepted"
                                                      : "Rejected"}
                                                  </td>
                                                </tr>
                                              )
                                            )
                                          ) : (
                                            <tr>
                                              <td
                                                colSpan="7"
                                                className="text-center"
                                              >
                                                No Bid Found
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </Bounce>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>

                        <div
                          className="tab-pane fade"
                          id="bidsOnMyPhysicalNFT"
                          role="tabpanel"
                          aria-labelledby="bidsOnMyPhysicalNFT-tab"
                        >
                          <section className="itematable py-lg-5 pt-3">
                            <div className="container-fluid px-lg-5">
                              <div className="row">
                                <div className="col-lg-8 col-md-12 col-12 mx-auto">
                                  <Bounce top>
                                    <div className="table-responsive itemscontent">
                                      <table className="table table-hover mb-lg-0">
                                        <thead>
                                          <tr>
                                            <th>Sr. No.</th>
                                            <th>Image</th>
                                            <th>Bid Price</th>
                                            <th>Date And Time</th>
                                            <th>Status</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.myitembidsPhysical
                                            ?.length > 0 ? (
                                            this.state.myitembidsPhysical?.map(
                                              (item, index) => (
                                                <tr key={item.id}>
                                                  <td>{index + 1}</td>
                                                  <td>{item.name}</td>
                                                  <td>
                                                    {item.bid_price}&nbsp;
                                                    {item.artistType == 0
                                                      ? config.currencyType
                                                      : config.currencyTypeSep}
                                                  </td>
                                                  <td>
                                                    {moment(
                                                      item.datetime
                                                    ).format("LL")}
                                                  </td>
                                                  <td>
                                                    {item.status == 3 ? (
                                                      "Over Bid"
                                                    ) : item.status == 0 ? (
                                                      <>
                                                        <button
                                                          className="btn btn-reset-primary mb-3"
                                                          style={{
                                                            padding: "7px 8px",
                                                          }}
                                                          onClick={(e) => {
                                                            this.AcceptOrRejectBid(
                                                              e,
                                                              1,
                                                              item
                                                            );
                                                          }}
                                                        >
                                                          Accept
                                                        </button>
                                                        &nbsp;
                                                        <button
                                                          className="btn btn-reset-primary mb-3"
                                                          style={{
                                                            padding: "7px 8px",
                                                          }}
                                                          onClick={(e) => {
                                                            this.AcceptOrRejectBid(
                                                              e,
                                                              0,
                                                              item
                                                            );
                                                          }}
                                                        >
                                                          Reject
                                                        </button>
                                                      </>
                                                    ) : item.status == 1 ? (
                                                      "Accepted"
                                                    ) : (
                                                      "Rejected"
                                                    )}
                                                  </td>
                                                </tr>
                                              )
                                            )
                                          ) : (
                                            <tr>
                                              <td
                                                colSpan="7"
                                                className="text-center"
                                              >
                                                No Bids on My NFT
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </Bounce>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {this.state.tabSelected === 2 ? (
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12 mb-4">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        {/* <div className="row">
                        <div className="col-12 mb-4">
                          <h2 className="tophead">Membership</h2>
                        </div>
                      </div> */}
                        <div className="row">
                          {this.state.itemListByToken.length === 0 ? (
                            <div className="col-lg-12 col-md-12 col-12 mb-4">
                              <br />

                              <Fade right>
                                <div className="card feature">
                                  <h3 className="text-center">
                                  <div className="d-flex justify-content-center" style={{marginBottom: "-20px"}}>
                              <dotlottie-player src="https://lottie.host/2779598a-ca3c-4fc6-aeb8-25216948d698/L7Y2Nq9xlE.json" background="transparent" speed={1} style={{"width":"200px","height":"200px"}} loop autoPlay />
                          </div>
                                    <h2>Sorry, no results found!</h2>

                                    <h5>NO COLLECTED NFT'S</h5>
                                  </h3>
                                </div>
                              </Fade>
                            </div>
                          ) : (
                            this.state.itemListByToken.map((item) => {
                              return (
                                <div
                                  className="col-lg-3 col-md-6 col-12 mb-4"
                                  key={item.id}
                                >
                                  <Fade right>
                                    <div className="card feature">
                                      <div className="cardblock">
                                        {item.file_type === "image" ? (
                                          <a
                                            href={
                                              config.pinataUrl +
                                              item.ImageFileHash
                                            }
                                            target="_blank"
                                          >
                                            <img
                                              className="card-img-top img-fluid"
                                              src={
                                                config.imageUrl1 + item.image
                                              }
                                              alt="membershipimg"
                                              style={{
                                                width: "275px",
                                                height: "320px",
                                              }}
                                            />
                                          </a>
                                        ) : item.file_type === "video" ? (
                                          <Player
                                            className="card-img-top img-fluid"
                                            style={{
                                              width: "275px",
                                              height: "320px",
                                            }}
                                            src={`${config.imageUrl1}${item.image}`}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="card-body">
                                        {/* <p className="card-text d-flex justify-content-between align-items-center">
                                        {item.name}
                                      </p> */}
                                        <div className="d-flex justify-content-between">
                                          <h5 className="card-title">
                                            {/* {item.sell_type === '1'? 'Fix Price':"Auction"} */}
                                            {item.name?.length > MAX_LENGTH ? (
                                              <span
                                                title={item.name}
                                                className="collab-storename"
                                              >
                                                {`${item.name.substring(
                                                  0,
                                                  MAX_LENGTH
                                                )}...`}
                                              </span>
                                            ) : (
                                              <span
                                                title={item.name}
                                                className="collab-storename"
                                              >
                                                {item.name}
                                              </span>
                                            )}
                                          </h5>
                                          <div className="timercount">
                                            <h6>
                                              {item.price}{" "}
                                              {item.artistType == 0
                                                ? config.currencyType
                                                : config.currencyTypeSep}
                                            </h6>
                                          </div>
                                        </div>

                                        {this.state.allData.is_on_sale === 1 ? (
                                          <button
                                            type="button"
                                            data-toggle="modal"
                                            data-target="#exampleModal"
                                            onClick={this.getitemId.bind(
                                              this,
                                              item
                                            )}
                                            className="btn btn-buy-primary w-100 my-3"
                                          >
                                            Resale
                                          </button>
                                        ) : (
                                          <button
                                            type="button"
                                            data-toggle="modal"
                                            data-target="#exampleModal"
                                            onClick={this.getitemId.bind(
                                              this,
                                              item
                                            )}
                                            className="btn btn-buy-primary w-100 my-3"
                                          >
                                            Resale
                                          </button>
                                        )}

                                        {
                                          // item.firstBuyer !=
                                          // this.loginData.data.id ? (
                                          //   ""
                                          // ) :
                                          item.physicalNftCheck == 2 ? (
                                            item.physold == null ? (
                                              <div>
                                                <button
                                                  type="button"
                                                  onClick={this.openPhysicalNft.bind(
                                                    this,
                                                    item
                                                  )}
                                                  className="btn btn-buy-primary orderprintbtn w-100 "
                                                >
                                                  Order Prints{" "}
                                                  {/* <span className="ml-4">
                                              <i class="fas fa-chevron-down"></i>
                                            </span> */}
                                                </button>
                                                <div className="text-center mt-2">
                                                  <span className="freeshipping">
                                                    {" "}
                                                    Free Shipping
                                                  </span>
                                                </div>
                                              </div>
                                            ) : (
                                              <>
                                                <div>
                                                  <button
                                                    type="button"
                                                    disabled
                                                    className="btn btn-buy-primary orderprintbtn w-100 "
                                                  >
                                                    Order Prints{" "}
                                                    {/* <span className="ml-4">
                                                <i class="fas fa-chevron-down"></i>
                                              </span> */}
                                                  </button>
                                                  <div className="text-center mt-2">
                                                    <span
                                                      className="freeshipping"
                                                      style={{
                                                        fontWeight: "700",
                                                      }}
                                                    >
                                                      {" "}
                                                      Order Status: &nbsp;
                                                      {item.deliveryStatus ==
                                                      1 ? (
                                                        <label
                                                          style={{
                                                            color: "green",
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          Order Placed
                                                        </label>
                                                      ) : item.deliveryStatus ==
                                                        2 ? (
                                                        <label
                                                          style={{
                                                            color: "green",
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          Shipped
                                                        </label>
                                                      ) : item.deliveryStatus ==
                                                        3 ? (
                                                        <label
                                                          style={{
                                                            color: "green",
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          Delivered
                                                        </label>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </span>
                                                  </div>
                                                </div>
                                              </>
                                            )
                                          ) : (
                                            ""
                                          )
                                        }
                                      </div>
                                    </div>
                                  </Fade>
                                </div>
                              );
                            })
                          )}
                        </div>
                      </div>

                      <div
                        className="tab-pane fade"
                        id="profile"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                      >
                        <section className="itematable py-lg-5 pt-3">
                          <div className="container-fluid px-lg-5">
                            <div className="row">
                              <div className="col-lg-8 col-md-12 col-12 mx-auto">
                                <Bounce top>
                                  <div className="table-responsive itemscontent">
                                    <table className="table table-hover mb-lg-0">
                                      <thead>
                                        <tr>
                                          <th>Sr. No.</th>
                                          <th>Image</th>

                                          <th>Bid Price</th>
                                          <th>Date And Time</th>
                                          <th>Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.getMybid.length > 0 ? (
                                          this.state.getMybid.map(
                                            (item, index) => (
                                              <tr key={item.id}>
                                                <td>{index + 1}</td>
                                                <td>{item.name}</td>
                                                <td>
                                                  {item.bid_price}&nbsp;
                                                  {config.currencyType}
                                                </td>
                                                <td>
                                                  {moment(item.datetime).format(
                                                    "LL"
                                                  )}
                                                </td>
                                                <td>
                                                  {item.status == 3
                                                    ? "Over Bid"
                                                    : item.status == 0
                                                    ? "Pending"
                                                    : item.status == 1
                                                    ? "Accepted"
                                                    : "Rejected"}
                                                </td>
                                              </tr>
                                            )
                                          )
                                        ) : (
                                          <tr>
                                            <td
                                              colSpan="7"
                                              className="text-center"
                                            >
                                              No Bid Found
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </Bounce>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>

                      <div
                        className="tab-pane fade"
                        id="contact"
                        role="tabpanel"
                        aria-labelledby="contact-tab"
                      >
                        <section className="itematable py-lg-5 pt-3">
                          <div className="container-fluid px-lg-5">
                            <div className="row">
                              <div className="col-lg-8 col-md-12 col-12 mx-auto">
                                <Bounce top>
                                  <div className="table-responsive itemscontent">
                                    <table className="table table-hover mb-lg-0">
                                      <thead>
                                        <tr>
                                          <th>Sr. No.</th>
                                          <th>Image</th>
                                          <th>Bid Price</th>
                                          <th>Date And Time</th>
                                          <th>Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.myitembids?.length > 0 ? (
                                          this.state.myitembids?.map(
                                            (item, index) => (
                                              <tr key={item.id}>
                                                <td>{index + 1}</td>
                                                <td>{item.name}</td>
                                                <td>
                                                  {item.bid_price}&nbsp;
                                                  {config.currencyType}
                                                </td>
                                                <td>
                                                  {moment(item.datetime).format(
                                                    "LL"
                                                  )}
                                                </td>
                                                <td>
                                                  {item.status == 3 ? (
                                                    "Over Bid"
                                                  ) : item.status == 0 ? (
                                                    <>
                                                      <button
                                                        className="btn btn-reset-primary mb-3"
                                                        style={{
                                                          padding: "7px 8px",
                                                        }}
                                                        onClick={(e) => {
                                                          this.AcceptOrRejectBid(
                                                            e,
                                                            1,
                                                            item
                                                          );
                                                        }}
                                                      >
                                                        Accept
                                                      </button>
                                                      &nbsp;
                                                      <button
                                                        className="btn btn-reset-primary mb-3"
                                                        style={{
                                                          padding: "7px 8px",
                                                        }}
                                                        onClick={(e) => {
                                                          this.AcceptOrRejectBid(
                                                            e,
                                                            0,
                                                            item
                                                          );
                                                        }}
                                                      >
                                                        Reject
                                                      </button>
                                                    </>
                                                  ) : item.status == 1 ? (
                                                    "Accepted"
                                                  ) : (
                                                    "Rejected"
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          )
                                        ) : (
                                          <tr>
                                            <td
                                              colSpan="7"
                                              className="text-center"
                                            >
                                              No Bids on My NFT
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </Bounce>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.tabSelected == 4 ? (
                <div
                  className="tab-pane fade"
                  id="wishlist"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  {/* <div className="row">
              <div className="col-12 mb-4">
                <h2 className="tophead">WishList</h2>
              </div>
            </div> */}
                  <div className="row">
                    {/* <div className="col-lg-3 col-md-6 col-12 mb-4"> */}
                    {this.state.getWishListData.length === 0 &&
                    this.state.getWishListDataPhysical.length === 0 ? (
                      <Fade bottom>
                        <div className="card feature">
                          <div className="">
                            <h4 className="text-center">
                            <div className="d-flex justify-content-center" style={{marginBottom: "-20px"}}>
                              <dotlottie-player src="https://lottie.host/2779598a-ca3c-4fc6-aeb8-25216948d698/L7Y2Nq9xlE.json" background="transparent" speed={1} style={{"width":"200px","height":"200px"}} loop autoPlay />
                          </div>
                              <h2>Sorry, no results found!</h2>
                              <h5> No Nft Found In WishList</h5>
                            </h4>
                          </div>
                        </div>
                      </Fade>
                    ) : (
                      <>
                        {this.state.getWishListData.map((item) => (
                          <div
                            className="col-lg-3 col-md-6 col-12 mb-4"
                            key={item.wishlist_id}
                          >
                            <Fade bottom>
                              <div className="card feature">
                                <div className="cardblock">
                                  <a
                                  href= "javascript:void(0)"
                                  onClick={this.collectionLinkFeat.bind(this,item,1)}
                                    target="_blank"
                                  >
                                    <img
                                      className="card-img-top img-fluid"
                                      style={{
                                        width: "275px",
                                        height: "320px",
                                      }}
                                      src={config.imageUrl1 + item.image}
                                      alt="physical3"
                                    />
                                  </a>
                                </div>
                                <div className="card-body">
                                  <p className="card-text d-flex justify-content-between align-items-center">
                                    {item.name}
                                    <span className="ehttext">
                                      {item.price} {config.currencyType}
                                    </span>
                                  </p>
                                  <button
                                    onClick={this.removeWishListAction.bind(
                                      this,
                                      item,
                                      1
                                    )}
                                    type="button"
                                    className="btn btn-buy-primary w-100"
                                  >
                                    Remove from Wishlist
                                  </button>
                                </div>
                              </div>
                            </Fade>
                          </div>
                        ))}
                        {this.state.getWishListDataPhysical.map((item) => (
                          <div
                            className="col-lg-3 col-md-6 col-12 mb-4"
                            key={item.wishlist_id}
                          >
                            <Fade bottom>
                              <div className="card feature">
                                <div className="cardblock">
                                  <a
href= "javascript:void(0)"
onClick={this.collectionLinkFeat.bind(this,item,2)}                                    target="_blank"
                                  >
                                    <img
                                      className="card-img-top img-fluid"
                                      style={{
                                        width: "275px",
                                        height: "320px",
                                      }}
                                      src={config.imageUrl1 + item.image}
                                      alt="physical3"
                                    />
                                  </a>
                                </div>
                                <div className="card-body">
                                  <p className="card-text d-flex justify-content-between align-items-center">
                                    {item.name}
                                    <span className="ehttext">
                                      {item.price} {config.currencyType}
                                    </span>
                                  </p>
                                  <button
                                    onClick={this.removeWishListAction.bind(
                                      this,
                                      item,
                                      2
                                    )}
                                    type="button"
                                    className="btn btn-buy-primary w-100"
                                  >
                                    Remove from Wishlist
                                  </button>
                                </div>
                              </div>
                            </Fade>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.tabSelected == 1 ? (
                <div
                  className="tab-pane fade show"
                  id="MemberShip"
                  role="tabpanel"
                  aria-labelledby="MemberShip-tab"
                >
                  {this.state.membershipCardBalance ? (
                    <>
                      <div className="row">
                        <div className="col-12 mb-4">
                          <h2 className="tophead">AFGenesis</h2>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-6 col-12 mb-4">
                          <Fade top>
                            <div className="card feature">
                              <div className="cardblock">
                                <img
                                  className="card-img-top img-fluid"
                                  src="mercafor_dashboard/assets/img/memberShipCard.jpeg"
                                  alt="physical2"
                                />
                              </div>
                              <div className="card-body">
                                <p className="card-text d-flex justify-content-between align-items-center">
                                  Unique Membership ID No. #
                                  {this.state.totalSupply} {this.state.memberShipType}
                                </p>

                                <button
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#transferModel"
                                  className="btn btn-buy-primary w-100 mt-5"
                                >
                                  Transfer
                                </button>
                              </div>
                            </div>
                          </Fade>
                        </div>
                      </div>
                    </>
                  ) :this.state.membershipCardBalance1 ?(
                    <>
                      <div className="row">
                        <div className="col-12 mb-4">
                          <h2 className="tophead">AFGenesis</h2>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-6 col-12 mb-4">
                          <Fade top>
                            <div className="card feature">
                              <div className="cardblock">
                                <img
                                  className="card-img-top img-fluid"
                                  src="mercafor_dashboard/assets/img/memberShipCard.jpeg"
                                  alt="physical2"
                                />
                              </div>
                              <div className="card-body">
                                <p className="card-text d-flex justify-content-between align-items-center">
                                  Unique Membership ID No. #
                                  {this.state.totalSupply1} {this.state.memberShipType1}
                                </p>

                                <button
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#transferModel1"
                                  className="btn btn-buy-primary w-100 mt-5"
                                >
                                  Transfer
                                </button>
                              </div>
                            </div>
                          </Fade>
                        </div>
                      </div>
                    </>
                  ): (
                    <div>
                      <div className="card feature">
                        <div className="">
                          <h4 className="text-center">
                            <img src="images/noData.png" />

                            <h5> No Memebership card found</h5>
                          </h4>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </section>

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data-backdrop="static"
            data-keyboard="false"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content" style={{ background: "#fff" }}>
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Resale
                  </h5>
                  <button
                    type="button"
                    className="close"
                    style={{ color: "#000" }}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="form-group">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Please Select Sale type
                      </label>
                      <select
                        className="form-control py-2"
                        value={this.state.selected}
                        onChange={(e) => {
                          this.onChangeSaleType(e);
                        }}
                        name="sell_type"
                      >
                        {this.options.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.text}
                          </option>
                        ))}
                      </select>
                    </div>
                    <span style={{ color: "red" }}>
                      {this.state.optionError}
                    </span>
                    <div className="form-group">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Please Enter New{" "}
                        {this.state.allData.artistType == 0
                          ? config.currencyType
                          : config.currencyTypeSep}{" "}
                        Price
                      </label>
                      {/* style={{ borderColor: (this.state.amountError) ? 'red' : '', color: '#333' }} */}
                      <input
                        type="text"
                        onKeyPress={(event) => {
                          if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={this.state.resalePrice}
                        name="price"
                        onChange={(e) => {
                          this.onChangeResaleAmount(e);
                        }}
                        className="form-control"
                        id="recipient-name"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.priceError}
                      </span>
                    </div>
                    {this.state.selected == "2" ? (
                      <>
                        <label
                          htmlFor="recipient-name"
                          className="col-form-label"
                        >
                          Sale Starts
                        </label>
                        <div className="form-group">
                          <DateTimePicker
                            onChange={(e) =>
                              this.DateTimePickerSelect({
                                value: e,
                                name: "start_date",
                              })
                            }
                            minDate={new Date()}
                            value={this.state.start_date}
                            name="start_date"
                            className="form-control"
                          />
                          <span style={{ color: "red" }}>
                            {this.state.startError}
                          </span>
                        </div>

                        <label
                          htmlFor="recipient-name"
                          className="col-form-label"
                        >
                          Sale Ends
                        </label>
                        <div className="form-group">
                          <DateTimePicker
                            onChange={(e) =>
                              this.DateTimePickerSelect({
                                value: e,
                                name: "expiry_dateResale",
                              })
                            }
                            minDate={this.state.start_date}
                            value={this.state.expiry_dateResale}
                            name="expiry_dateResale"
                            className="form-control"
                          />
                          <span style={{ color: "red" }}>
                            {this.state.endError}
                          </span>
                        </div>

                        <div className="form-group">
                          <label
                            htmlFor="recipient-name"
                            className="col-form-label"
                          >
                            Bid Increasing percentage
                          </label>
                          {/* style={{ borderColor: (this.state.amountError) ? 'red' : '', color: '#333' }} */}
                          <input
                            type="number"
                            value={this.state.allData.bid_increasing_amount}
                            name="bid_increasing_amount"
                            onChange={(e) => {
                              this.bidIncreasingChange(e);
                            }}
                            className="form-control"
                            id="recipient-name"
                          />
                          <span style={{ color: "red" }}>
                            {this.state.bidDataError}
                          </span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {/* <div className="form-group">
            <label htmlFor="recipient-name" className="col-form-label">Resale Fee : {parseFloat(this.state.resaleFeeInBNB).toFixed(4)} ETH <small> ( {parseFloat(this.state.resaleFeeInUSD).toFixed(2)} USD)</small></label>
          </div> */}
                  </form>
                  <div className="form-group">
                    <ul>
                      {/* <li>Note : </li> */}
                      {/* <li>Please first approve your NFT for resale, then click on resale.</li> */}
                    </ul>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    // disabled={
                    //   this.state.allData.sell_type == 2
                    //     ? !this.state.start_date || !this.state.expiry_dateResale
                    //     : ""
                    // }
                    style={{ width: "100%", marginBottom: "-10px" }}
                    className="btn btn-buy-primary w-100 my-3"
                    onClick={(e) => {
                      this.putOnSale(e);
                    }}
                  >
                    {this.state.allData.is_on_sale === 1
                      ? "Resale Again"
                      : "Resale"}
                  </button>
                  {/* `${this.state.approveBtnText === true ? 'Processing...' : 'Approve NFT'}`} */}
                  {/* disabled={this.state.isApproveNFT || this.state.approveBtnText} */}
                  {/* {(this.state.approveBtnText || this.state.resaleBtnText) ?

                                    <p style={{ width: '100%' }}><BarLoader color="#e84747" height="2" style={{ marginTop: '-21px' }} /></p>
                                    : ''}

                                <button type="button" style={{ width: '100%', marginBottom: '-10px' }} className="btn btn-success" onClick={this.approveNFT.bind(this)} disabled={this.state.isApproveNFT || this.state.approveBtnText}>{`${this.state.approveBtnText === true ? 'Processing...' : 'Approve NFT'}`}</button>
                                <br />
                                <button type="button" disabled={!this.state.isApproveNFT || this.state.resaleBtnText} style={{ width: '100%' }} onClick={this.resalePayment.bind(this)} className="btn btn-success" >{`${this.state.resaleBtnText ? 'Processing...' : 'Resale'}`}</button> */}
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="transferModel"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="transferModelLabel"
            aria-hidden="true"
            data-backdrop="static"
            data-keyboard="false"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content" style={{ background: "#fff" }}>
                <div className="modal-header">
                  <h5 className="modal-title" id="transferModelLabel">
                    Resale
                  </h5>
                  <button
                    type="button"
                    className="close"
                    style={{ color: "#000" }}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="form-group">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Please Enter New Address
                      </label>
                      {/* style={{ borderColor: (this.state.amountError) ? 'red' : '', color: '#333' }} */}
                      <input
                        type="text"
                        className="form-control"
                        //id="recipient-name"
                        value={this.state.newMembershipAddress}
                        name="price"
                        onChange={(e) => {
                          this.onChangenewMembershipAddress(e);
                        }}
                      />
                    </div>
                  </form>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    disabled={!this.state.newMembershipAddress}
                    style={{ width: "100%", marginBottom: "-10px" }}
                    className="btn btn-buy-primary w-100 mt-5"
                    onClick={(e) => {
                      this.transferMembershipCard(e);
                    }}
                    // onClick={(e) => {
                    //   this.transferMembershipCard(e);
                    // }}
                  >
                    Transfer
                  </button>
                </div>
              </div>
            </div>
          </div>



          <div
            className="modal fade"
            id="transferModel1"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="transferModelLabel"
            aria-hidden="true"
            data-backdrop="static"
            data-keyboard="false"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content" style={{ background: "#fff" }}>
                <div className="modal-header">
                  <h5 className="modal-title" id="transferModelLabel">
                    Resale
                  </h5>
                  <button
                    type="button"
                    className="close"
                    style={{ color: "#000" }}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="form-group">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Please Enter New Address
                      </label>
                      {/* style={{ borderColor: (this.state.amountError) ? 'red' : '', color: '#333' }} */}
                      <input
                        type="text"
                        className="form-control"
                        //id="recipient-name"
                        value={this.state.newMembershipAddress}
                        name="price"
                        onChange={(e) => {
                          this.onChangenewMembershipAddress(e);
                        }}
                      />
                    </div>
                  </form>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    disabled={!this.state.newMembershipAddress}
                    style={{ width: "100%", marginBottom: "-10px" }}
                    className="btn btn-buy-primary w-100 mt-5"
                    onClick={(e) => {
                      this.transferMembershipCardBase(e);
                    }}
                    // onClick={(e) => {
                    //   this.transferMembershipCard(e);
                    // }}
                  >
                    Transfer
                  </button>
                </div>
              </div>
            </div>
          </div>

          <Modal
            open={this.state.setOpen1}
            onClose={this.onCloseModal1.bind(this)}
            center
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Physical Artwork Sent
              </h5>
            </div>

            <div className="modal-body">
              <div className="genesischeck">
                <h5>Shipping Information:</h5>
                {/* <p>
                    {" "}
                    <button type="submit" className="btn btn-primary">
                      Send Email Again
                    </button>
                  </p> */}

                <hr style={{ marginTop: "0px", marginBottom: "0px" }} />
                <div className="row">
                  <div className="col-md-12 col-6">
                    <label
                      style={{ color: "#000" }}
                      for="inputPassword3"
                      class="control-label"
                    >
                      Tracking Number <span className="error">*</span>
                    </label>
                    <input
                      name="trackingNumber"
                      value={this.state.trackingNumber}
                      class="form-control"
                      onChange={this.handleChange1}
                      placeholder="Tracking Number"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 col-6">
                    <label
                      style={{ color: "#000" }}
                      for="inputPassword3"
                      class="control-label"
                    >
                      Tracking Link
                    </label>
                    <input
                      name="trackingLink"
                      value={this.state.trackingLink}
                      class="form-control"
                      onChange={this.handleChange1}
                      placeholder="Tracking Link"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 col-6">
                    <label
                      style={{ color: "#000" }}
                      for="inputPassword3"
                      class="control-label"
                    >
                      Remark
                    </label>
                    <textarea
                      class="form-control"
                      placeholder="Remark"
                      name="remark"
                      value={this.state.remark}
                      onChange={this.handleChange1}
                      rows="3"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer border-0 footerdiv">
              {/* <button
                  type="submit"
                  disabled={!this.state.agreed}
                  className="btn btn-buy-primary px-5"
                >
                  Mint
                </button> */}
              <div className="row">
                <div className="col-md-6 col-6">
                  <button
                    type="submit"
                    disabled={!this.state.trackingNumber}
                    onClick={this.deliveredStatusChanged.bind(
                      this,
                      this.state.dataSaved,
                      1
                    )}
                    className="btn btn-buy-primary px-5"
                  >
                    Ok
                  </button>
                </div>
                <div className="col-md-6 col-6">
                  <button
                    type="submit"
                    onClick={this.onCloseModal1.bind(this)}
                    className="btn btn-buy-primary px-5"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal>

          {/*---------------------- Membership Section End -------------------- */}

          <Footer1 />
        </div>
      </>
    );
  }
}
