import React, { Component } from 'react';


export default class footer extends Component {

   constructor(props) {
      super(props)
   }
   componentDidMount() {

   }

   render() {
      return (

         <>



            <footer className="footer_part">
               <div className="footer_top">
                  <div className="container-fluid px-5">
                     <div className="row">
                        <div className="col-sm-8 col-lg-3">
                           <div className="single_footer_part">
                              <a href="index.html" className="footer_logo_iner"> <img src="img/coin-fmx.gif" alt="#" className="Main_logo" /
                              > </a>
                              <p>Heaven fruitful doesn't over lesser days appear creeping seasons so behold bearing
                                 days
                                 open
                              </p>
                              <div className="footer_icon social_icon">
                                 <ul className="list-unstyled">
                                    <li><a href="https://www.facebook.com/Art-Fungible-105126271909007" target="_blank" className="single_social_icon"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="https://twitter.com/art_fungible" target="_blank" className="single_social_icon"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="https://www.instagram.com/artfungible/" target="_blank" className="single_social_icon"><i className="fab fa-instagram"></i></a></li>
                                    <li><a href="#" className="single_social_icon"><i className="fab fa-telegram"></i></a></li>
                                    <li><a href="#" className="single_social_icon"><i className="fab fa-youtube"></i></a></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-sm-6 col-lg-2">
                           <div className="single_footer_part">
                              <h4>Products</h4>
                              <ul>
                                 <li><a href="#">Platform</a></li>
                                 <li><a href="#">Wallet</a></li>
                                 <li><a href="#">Marketplace</a></li>
                                 <li><a href="#">Beam</a></li>
                                 <li><a href="#">Network</a></li>
                              </ul>
                           </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                           <div className="single_footer_part">
                              {/* <h4>Resources</h4>
                           <ul className="list-unstyled">
                              <li><a href="#javascript"> Blog</a></li>
                              <li><a href="#javascript">Docs</a></li>
                              <li><a href="#javascript">Press kit</a></li>
                              <li><a href="#javascript">Timeline</a></li>
                              <li><a href="#javascript">Newsletter</a></li>
                            
                           </ul>  */}
                              <h4>Quick link</h4>
                              <div className="row">
                                 <div className="col-sm-6">
                                    <ul className="list-unstyled">
                                       <li><a href="#javascript"> Home</a></li>
                                       <li><a href="#javascript">Introduction</a></li>
                                       <li><a href="#javascript">Marketplace</a></li>
                                       <li><a href="#javascript">Profile</a></li>


                                    </ul>
                                 </div>
                                 <div className="col-sm-6">
                                    <ul className="list-unstyled">
                                       <li><a href="#javascript">Acitivity</a></li>
                                       <li><a href="#javascript">Settings</a></li>
                                       <li><a href="#javascript">FAQ</a></li>
                                       <li><a href="#javascript">Contact Us</a></li>

                                    </ul>
                                 </div>
                              </div>

                           </div>
                        </div>
                        <div className="col-sm-6 col-lg-2">
                           <div className="single_footer_part">
                              <h4>About</h4>
                              <ul>
                                 <li><a href="#">Why mercafor.crypto</a></li>
                                 <li><a href="#">Company</a></li>
                                 <li><a href="#">Career</a></li>
                                 <li><a href="#">Coin</a></li>
                                 <li><a href="#">Contact Us</a></li>
                              </ul>
                           </div>
                        </div>

                        <div className="col-sm-6 col-lg-2">
                           <div className="single_footer_part">
                              <h4>Help</h4>
                              <ul>
                                 <li><a href="#">Help Center</a></li>
                                 <li><a href="#">Product Status</a></li>
                                 <li><a href="#">Request a Feature</a></li>
                                 <li><a href="#">Coin</a></li>
                                 <li><a href="#">Submit a ticket</a></li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="copygight_text">
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-lg-8">
                           <div className="copyright_text">
                              <a>
                                 Copy right &copy;<script data-cfasync="false" src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script><script>to 2022</script> All rights reserved <i className="ti-heart" aria-hidden="true"></i> by <a href="#  " target="_blank">mercafor.crypto</a>
                              </a>
                           </div>
                        </div>
                        <div className="col-lg-4 text-right">

                           <img src="img/gallery/w.png" className="button_play" />
                           <img src="img/gallery/g.png" className="button_play" />

                        </div>
                     </div>
                  </div>
               </div>
            </footer>

         </>

      )
   }
}