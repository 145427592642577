import React, { Component, Fragment } from "react";
import Footer1 from "../directives/footer1";
import Header1 from "../directives/header1";
import axios from "axios";
import config from "../config/config";
import Cookies from "js-cookie";
import { getCurrentProvider } from "../components/provider";
import Web3 from "web3";
import { Fade, Zoom, Bounce } from "react-reveal";
import Slider from "react-slick";
import { Dialog, Classes } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import BarLoader from "react-bar-loader";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import Popup from "../popup";
import { Helmet } from "react-helmet";
import Countdown, { zeroPad } from "react-countdown";
import { toast } from "react-toastify";
import { Player } from "video-react";
const MAX_LENGTH = 520;

export default class itemactivity extends Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    this.itemId = params.itemId;
    this.type = params.type;
    this.loginData = !Cookies.get("artfungibleUserloginSuccess")
      ? []
      : JSON.parse(Cookies.get("artfungibleUserloginSuccess"));
    this.state = {
      listSingleItemData: "",
      collectionList: [],
      bidPrice: "",
      liveCryptoPrice: [],
      itemActivityData: [],
      getCollectionDetailByArtistIdData: [],
      isDialogOpen: false,
      isPopupError: false,
      isPopupSuccess: false,
      msg: false,
      latestBidData: [],
      latestOwner: [],
      expData1: "",
      seoList: "",
      newDateData: "",
      shippingAddressData: "",
    };

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    const day = ("0" + currentDate.getDate()).slice(-2);
    const hours = ("0" + currentDate.getHours()).slice(-2);
    const minutes = ("0" + currentDate.getMinutes()).slice(-2);
    const seconds = ("0" + currentDate.getSeconds()).slice(-2);

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    setTimeout(() => {
      this.setState({
        newDateData: formattedDate,
      });
    });
  }

  componentDidMount() {
    const receivedData = this.props.location.state;
    if (receivedData) {
        this.itemId = receivedData.state.itemId;
        this.type = receivedData.state.type;
    } else {
        
    }

    if (this.type === 1) {
        this.getItemDetailAction();
    } else if (this.type === 2) {
        this.getItemDetailActionPhysical();
    }
    
    this.itemViewAction();
    // this.livePriceAPI();
    this.getProductsbidsAPIAction();
    
    setInterval(() => {
        if (this.state.shippingAddressData?.shippingAddress === undefined) {
            this.getshippingAddressAPI();
        }
    }, 2000);
    
    var url = window.location.href;
    var split = url.split("/");
    let lastSecondSegment = split[split.length - 2];
    this.getSeoAPI(lastSecondSegment);
}


  async getshippingAddressAPI() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}userShippingAddress`,
        data: { userId: this.loginData.data.id },
        headers: { Authorization: this.loginData.Token },
      }).then((response) => {
        if (response.data.success === true) {
          this.setState({
            shippingAddressData: response.data,
          });
        } else {
          this.setState({
            shippingAddressData: "",
          });
        }
      });
    } catch (err) {
      this.setState({
        shippingAddressData: "",
      });
    }
  }

  async getSeoAPI(lastSecondSegment) {
    await axios({
      method: "post",
      url: `${config.apiUrl}/getSEO`,
      data: { pageName: lastSecondSegment },
    })
      .then(async (response) => {
        if (response.data.success === true) {
          this.setState({
            seoList: response.data.data,
          });
        } else if (response.data.success === false) {
        }
      })
      .catch((err) => {});
  }

  async getItemDetailAction() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}listSingleItem`,
        data: {
          item_id: this.itemId,
          user_id: this.loginData?.data?.id ? this.loginData?.data?.id : "0",
        },
      }).then((response) => {
        if (response.data.success === true) {
          const timezoneOffset = new Date().getTimezoneOffset();

          let newDate = new Date(response.data.response.expiry_date1);
          newDate.setMinutes(newDate.getMinutes() - timezoneOffset);
          let expiry_date1 = moment(newDate).format("YYYY-MM-DD HH:mm:SS"); // Add the modified date to the ite

          this.setState({
            expData1: expiry_date1,
          });
          // this.state.listSingleItemData.expiry_date1 = expiry_date1
          this.setState({
            listSingleItemData: response.data.response,
          });
          // const timezoneOffset = new Date().getTimezoneOffset() * -1;
          // let newDate = new Date(response.data.response.expiry_date1);
          // newDate.setMinutes(newDate.getMinutes() + timezoneOffset);
          // this.setState({
          //   listSingleItemData:response.data.response,
          // })
          this.getCollectionDetailByArtistIdAction(response.data.response);
        } else {
          this.setState({
            listSingleItemData: "",
          });
        }
      });
    } catch (err) {
      this.setState({
        listSingleItemData: "",
      });
    }
  }

  async getItemDetailActionPhysical() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}listSingleItemPhysical`,
        data: {
          item_id: this.itemId,
          user_id: this.loginData?.data?.id ? this.loginData?.data?.id : "0",
        },
      }).then((response) => {
        if (response.data.success === true) {
          const timezoneOffset = new Date().getTimezoneOffset();

          let newDate = new Date(response.data.response.expiry_date1);
          newDate.setMinutes(newDate.getMinutes() - timezoneOffset);
          let expiry_date1 = moment(newDate).format("YYYY-MM-DD HH:mm:SS"); // Add the modified date to the ite

          this.setState({
            expData1: expiry_date1,
          });
          // this.state.listSingleItemData.expiry_date1 = expiry_date1
          this.setState({
            listSingleItemData: response.data.response,
          });
          // const timezoneOffset = new Date().getTimezoneOffset() * -1;
          // let newDate = new Date(response.data.response.expiry_date1);
          // newDate.setMinutes(newDate.getMinutes() + timezoneOffset);
          // this.setState({
          //   listSingleItemData:response.data.response,
          // })
          this.getCollectionDetailByArtistIdAction(response.data.response);
        } else {
          this.setState({
            listSingleItemData: "",
          });
        }
      });
    } catch (err) {
      this.setState({
        listSingleItemData: "",
      });
    }
  }

  async buyNft(e) {
    e.preventDefault();
    const rpcUrl = this.state.listSingleItemData.artistType == 1 ? config.baseRpcUrl : config.ethRpcUrl;

    if (this.loginData.length === 0) {
      this.setState({
        isPopupError: true,
        msg: "Connect Wallet First",
      });
      setTimeout(() => {
        this.setState({
          isPopupError: false,
        });
      }, 2000);
    } else if (
      this.type == 2 &&
      this.state.shippingAddressData?.shippingAddress == ""
    ) {
      window.location.href = `${config.baseUrl}setting`;
    } else if (this.state.listSingleItemData.artistType == 0) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(config.chainIdTx) }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: Web3.utils.toHex(config.chainIdTx),
                  chainName: "ETH",
                  rpcUrls: [`${rpcUrl}`],
                  nativeCurrency: {
                    name: "ETH",
                    symbol: "ETH",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://etherscan.io/"],
                },
              ],
            });
          } catch (addError) {
            
          }
        }
      }
    }else if (this.state.listSingleItemData.artistType == 1) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(config.chainIdSepTx) }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: Web3.utils.toHex(config.chainIdSepTx),
                  chainName: "Base Sepolia",
                  rpcUrls: [`${rpcUrl}`],
                  nativeCurrency: {
                    name: "ETH",
                    symbol: "ETH",
                    decimals: 18,
                  },
                  blockExplorerUrls: ["https://etherscan.io/"],
                },
              ],
            });
          } catch (addError) {
            
          }
        }
      }
    }
    try {
      const providers = await getCurrentProvider();
      const web3 = new Web3(providers);

      let tokenId = this.state.listSingleItemData.token_id;
      let contractAddres = this.state.listSingleItemData.contract;
      let marketplaceContractObject = new web3.eth.Contract(
        this.type == 2
          ? config.abiplacePhysicalMarketplace
          : config.abiMarketplace,
          this.type == 2
          ? config.marketplacePhysicalContract
          : this.state.listSingleItemData.artistType == 1
          ? config.marketplaceContractBase
          : config.marketplaceContract
      );
      let getTokenDetails = await marketplaceContractObject.methods
        .getTokenDetails(contractAddres, tokenId)
        .call();
      let returnAmountApi = false;
      if (
        getTokenDetails.lastBidder !=
        "0x0000000000000000000000000000000000000000"
      ) {
        returnAmountApi = true;
      }
      let price = getTokenDetails.price;

      if (getTokenDetails.saleType == 2) {
        if (this.state.bidPrice <= 0) {
          this.setState({
            isPopupError: true,
            msg: "Invalid Amount !!",
          });
          setTimeout(() => {
            this.setState({
              isPopupError: false,
            });
          }, 2000);
          return;
        }
        price = parseInt(this.state.bidPrice * 10 ** 18).toString();
        if (
          price <= getTokenDetails.price &&
          price <= getTokenDetails.lastBid
        ) {
          this.setState({
            isPopupError: true,
            msg: "Amount should be greater than last bid !!",
          });
          setTimeout(() => {
            this.setState({
              isPopupError: false,
            });
          }, 2000);

          return;
        }
      }
      let userAddress = this.loginData.data.walletAddress;
      let userBalance = await web3.eth.getBalance(userAddress);
      
      if (parseInt(userBalance) / 10 ** 18 < parseInt(price) / 10 ** 18) {
        this.setState({
          isPopupError: true,
          msg: "Insufficient fund",
        });
        setTimeout(() => {
          this.setState({
            isPopupError: false,
          });
        }, 2000);
        return;
      }

      
      let BuyNft = await marketplaceContractObject.methods.BuyNft(
        contractAddres,
        tokenId
      );
      this.setState({
        isDialogOpen: true,
      });
      let encoded_tx = BuyNft.encodeABI();

      let gasPrice = await web3.eth.getGasPrice();

      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to:
          this.type == 2
            ? config.marketplacePhysicalContract
            : this.state.listSingleItemData.artistType == 1
            ? config.marketplaceContractBase
            : config.marketplaceContract,
        from: userAddress,
        data: encoded_tx,
        value: price,
      });

      let trx = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to:
          this.type == 2
            ? config.marketplacePhysicalContract
            : this.state.listSingleItemData.artistType == 1
            ? config.marketplaceContractBase
            : config.marketplaceContract,
        from: userAddress,
        data: encoded_tx,
        value: price,
      });
      if (trx.transactionHash) {
        this.setState({
          isPopupSuccess: true,
          msg:
            getTokenDetails.saleType == 2
              ? "Bid Applied Successfully"
              : "Purchased Successfully",
        });
        setTimeout(() => {
          this.setState({
            isPopupSuccess: false,
          });
        }, 2000);
        this.setState({
          isDialogOpen: false,
        });
        setTimeout(() => {
          window.location.href = `${config.baseUrl}membership/${this.loginData.data.walletAddress}`;
        }, 2000);
      }
    } catch (err) {
      
      if (err.message.toString().split("insufficient funds")[1]) {
        this.setState({
          isPopupError: true,
          msg: "Transaction reverted : " + err.message,
        });
        setTimeout(() => {
          this.setState({
            isPopupError: false,
          });
        }, 2000);
      } else {
        this.setState({
          isDialogOpen: false,
        });
        if (err.toString().split("execution reverted:")[1]) {
          this.setState({
            isPopupError: true,
            msg:
              "Transaction reverted : " +
              err
                .toString()
                .split("execution reverted:")[1]
                .toString()
                .split("{")[0],
          });
          setTimeout(() => {
            this.setState({
              isPopupError: false,
            });
          }, 2000);
        } else {
          this.setState({
            isDialogOpen: false,
          });
          this.setState({
            isPopupError: true,
            msg: err.message,
          });
          setTimeout(() => {
            this.setState({
              isPopupError: false,
            });
          }, 2000);
        }
      }
      this.setState({
        spinLoader: "0",
        isDialogOpen: false,
      });
      return false;
    }
  }

  async cancelListing(e) {
    e.preventDefault();
    if (this.loginData.length === 0) {
      this.setState({
        isPopupError: true,
        msg: "Connect Wallet First",
      });
      setTimeout(() => {
        this.setState({
          isPopupError: false,
        });
      }, 2000);
    } else {
      // alert('1')
      try {
        const rpcUrl =
          this.state.listSingleItemData.artistType == 1
            ? config.baseRpcUrl
            : config.ethRpcUrl;

        if (this.state.listSingleItemData.artistType == 1) {
          try {
            await window.ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: Web3.utils.toHex(config.chainIdSepTx) }],
            });
          } catch (switchError) {
            if (switchError.code === 4902) {
              try {
                await window.ethereum.request({
                  method: "wallet_addEthereumChain",
                  params: [
                    {
                      chainId: Web3.utils.toHex(config.chainIdSepTx),
                      chainName: "Base Sepolia",
                      rpcUrls: [`${rpcUrl}`],
                      nativeCurrency: {
                        name: "ETH",
                        symbol: "ETH",
                        decimals: 18,
                      },
                      blockExplorerUrls: [
                        "https://base-sepolia.blockscout.com",
                      ],
                    },
                  ],
                });
              } catch (addError) {
                
              }
            }
          }
        } else {
          try {
            await window.ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: Web3.utils.toHex(config.chainIdTx) }],
            });
          } catch (switchError) {
            if (switchError.code === 4902) {
              try {
                await window.ethereum.request({
                  method: "wallet_addEthereumChain",
                  params: [
                    {
                      chainId: Web3.utils.toHex(config.chainIdTx),
                      chainName: "ETH",
                      rpcUrls: [`${rpcUrl}`],
                      nativeCurrency: {
                        name: "ETH",
                        symbol: "ETH",
                        decimals: 18,
                      },
                      blockExplorerUrls: [
                        "https://base-sepolia.blockscout.com",
                      ],
                    },
                  ],
                });
              } catch (addError) {
                
              }
            }
          }
        }

        let userAddress = this.loginData.data.walletAddress;
        const providers = await getCurrentProvider();
        const web3 = new Web3(providers);

        let tokenId = this.state.listSingleItemData.token_id;
        let contractAddres = this.state.listSingleItemData.contract;

        let marketplaceContractObject = new web3.eth.Contract(
          this.type == 2
            ? config.abiplacePhysicalMarketplace
            : config.abiMarketplace,
          this.type == 2
            ? config.marketplacePhysicalContract
            : this.state.listSingleItemData.artistType == 1
            ? config.marketplaceContractBase
            : config.marketplaceContract
        );
        let getTokenDetails = await marketplaceContractObject.methods
          .getTokenDetails(contractAddres, tokenId)
          .call();

        if (
          getTokenDetails.lastBidder !=
          "0x0000000000000000000000000000000000000000"
        ) {
          alert(
            "Someone is already bid on this NFT please accept or reject it!!"
          );
          return;
        }
        let price = getTokenDetails.price;
        // if (getTokenDetails.saleType == 1) {
        let RemoveNftFromSale =
          await marketplaceContractObject.methods.removeNftFromSale(
            contractAddres,
            tokenId.toString()
          );

        this.setState({
          isDialogOpen: true,
        });
        let encoded_tx = RemoveNftFromSale.encodeABI();

        let gasPrice = await web3.eth.getGasPrice();

        let gasLimit = await web3.eth.estimateGas({
          gasPrice: web3.utils.toHex(gasPrice),
          to:
            this.type == 2
              ? config.marketplacePhysicalContract
              : this.state.listSingleItemData.artistType == 1
              ? config.marketplaceContractBase
              : config.marketplaceContract,
          from: userAddress,
          data: encoded_tx,
          // value: price,
        });
        let trx = await web3.eth.sendTransaction({
          gasPrice: web3.utils.toHex(gasPrice),
          gas: web3.utils.toHex(gasLimit),
          to:
            this.type == 2
              ? config.marketplacePhysicalContract
              : this.state.listSingleItemData.artistType == 1
              ? config.marketplaceContractBase
              : config.marketplaceContract,
          from: userAddress,
          data: encoded_tx,
          //value: price,
        });
        if (trx.transactionHash) {
          this.setState({
            isPopupSuccess: true,
            msg: "Nft will be Unlist shortly",
          });
          setTimeout(() => {
            this.setState({
              isPopupSuccess: false,
            });
          }, 2000);
          this.setState({
            isDialogOpen: false,
          });
          setTimeout(() => {
            window.location.href = `${config.baseUrl}membership/${this.loginData.data.walletAddress}`;
          }, 2000);
          // await this.removeNFTFromSaleApi(
          //   trx.transactionHash,
          //   contractAddres,
          //   tokenId
          // );
        }
        // }
      } catch (err) {
        if (err.message.toString().split("insufficient funds")[1]) {
          this.setState({
            isPopupError: true,
            msg: "Transaction reverted : " + err.message,
          });
          setTimeout(() => {
            this.setState({
              isPopupError: false,
            });
          }, 2000);
        } else {
          this.setState({
            isDialogOpen: false,
          });
          if (err.toString().split("execution reverted:")[1]) {
            this.setState({
              isPopupError: true,
              msg:
                "Transaction reverted : " +
                err
                  .toString()
                  .split("execution reverted:")[1]
                  .toString()
                  .split("{")[0],
            });
            setTimeout(() => {
              this.setState({
                isPopupError: false,
              });
            }, 2000);
          } else {
            this.setState({
              isDialogOpen: false,
            });
            this.setState({
              isPopupError: true,
              msg: err.message,
            });
            setTimeout(() => {
              this.setState({
                isPopupError: false,
              });
            }, 2000);
          }
        }
        this.setState({
          spinLoader: "0",
          isDialogOpen: false,
        });
        return false;
      }
    }
  }

  async removeNFTFromSaleApi(hash, contractAddres, tokenId) {
    try {
      this.setState({
        isDialogOpen: true,
      });
      let dataa = {
        contract: contractAddres,
        user_id: this.loginData.data.id,
        item_id: this.state.listSingleItemData.itemId,
        trx_currency:
          this.state.listSingleItemData.artistType == 0
            ? config.currencyType
            : config.currencyTypeSep,
        owner: this.loginData.data.first_name,
        transactionHash: hash,
        tokenId: this.state.listSingleItemData.token_id,
        trx_type: 1,
        cryptoPrice: 0,
        itemName: this.state.listSingleItemData.token_id.name,
        owner_id: this.loginData.data.id,
      };

      await axios({
        method: "post",
        url: `${
          this.type == 1
            ? config.apiUrl + "removeNFTFromSale"
            : config.apiUrl + "removeNFTFromSalePhysical"
        }`,
        headers: { Authorization: this.loginData.Token },
        data: dataa,
      }).then((response) => {
        if (response.data.success === true) {
          this.setState({
            isPopupSuccess: true,
            msg: response.data.msg,
          });
          setTimeout(() => {
            this.setState({
              isPopupSuccess: false,
            });
          }, 2000);
          this.setState({
            isDialogOpen: false,
          });
          setTimeout(() => {
            window.location.href = `${config.baseUrl}membership/${this.loginData.data.walletAddress}`;
          }, 2000);
        } else {
          this.setState({
            isPopupError: true,
            msg: response.data.msg,
          });
          setTimeout(() => {
            this.setState({
              isPopupError: false,
            });
          }, 2000);
          this.setState({});
        }
      });
    } catch (err) {
      this.setState({
        isDialogOpen: false,
      });
    }
  }

  changeBidPrice(e) {
    this.setState({ bidPrice: e.target.value });
  }

  async itemViewAction() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}itemView`,
        headers: { Authorization: this.loginData.Token },
        data: {
          user_id: this.loginData.data.id,
          item_id: this.itemId,
        },
      }).then((response) => {
        if (response.data.success === true) {
        } else {
        }
      });
    } catch (err) {
      // alert('45')
    }
  }

  async itemLikedAction() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}itemLike`,
        headers: { Authorization: this.loginData.Token },
        data: {
          user_id: this.loginData.data.id,
          item_id: this.itemId,
        },
      }).then((response) => {
        if (response.data.success === true) {
          this.getItemDetailAction();
        } else {
        }
      });
    } catch (err) {
      // alert('45')
    }
  }

  async WishListAction() {
    if (this.loginData.length === 0) {
      this.setState({
        isPopupError: true,
        msg: "Connect Wallet First",
      });
      setTimeout(() => {
        this.setState({
          isPopupError: false,
        });
      }, 2000);
    } else {
      try {
        await axios({
          method: "post",
          url: `${
            this.type == 1
              ? config.apiUrl + "addWishlist"
              : config.apiUrl + "addWishlistPhysical"
          }`,
          headers: { Authorization: this.loginData.Token },
          data: {
            user_id: this.loginData.data.id,
            item_id: this.itemId,
          },
        }).then((response) => {
          if (response.data.success === true) {
            this.setState({
              isPopupSuccess: true,
              msg: response.data.msg,
            });
            setTimeout(() => {
              this.setState({
                isPopupSuccess: false,
              });
            }, 2000);
            this.getItemDetailAction();
          } else {
            this.setState({
              isPopupError: true,
              msg: response.data.msg,
            });
            setTimeout(() => {
              this.setState({
                isPopupError: false,
              });
            }, 2000);
          }
        });
      } catch (err) {
        // alert('45')
      }
    }
  }

  async getProductsbidsAPIAction() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}getProductsbidsAPI`,
        headers: { Authorization: this.loginData.Token },
        data: {
          item_id: this.itemId,
          nft_type: this.type,
        },
      }).then((response) => {
        if (response.data.success === true) {
          this.setState({
            itemActivityData: response.data.data,
            latestBidData: response.data.data.filter(
              (item) => item.nft_activity_type === "Bid"
            ),
            latestOwner: response.data.data.filter(
              (item) =>
                item.nft_activity_type === "Purchase" ||
                item.nft_activity_type === "Resale" ||
                item.nft_activity_type === "Bid Accepted"
            ),

            // latestOwner: response.data.data.filter(
            //   (item) => item.nft_activity_type === "Resale"
            // ),
          });
        } else {
        }
      });
    } catch (err) {
      // alert('45')
    }
  }

  async getCollectionDetailByArtistIdAction(artistId) {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}getCollectionDetailByArtistId`,
        headers: { Authorization: this.loginData.Token },
        data: {
          id: artistId.creator,
        },
      }).then((response) => {
        if (response.data.success === true) {
          this.setState({
            getCollectionDetailByArtistIdData: response.data.data,
          });
        } else {
          this.setState({
            getCollectionDetailByArtistIdData: [],
          });
        }
      });
    } catch (err) {
      this.setState({
        getCollectionDetailByArtistIdData: [],
      });
      // alert('45')
    }
  }

  livePriceAPI = async () => {
    await axios({
      method: "get",
      url: "https://api.coingecko.com/api/v3/coins/markets?vs_currency=USD&ids=ethereum&order=market_cap_desc&per_page=100&page=1&sparkline=false",
    }).then((response) => {
      this.setState({
        liveCryptoPrice: response.data,
      });
    });
  };

  copieBtn = async () => {
    this.setState({
      isPopupSuccess: true,
      msg: "copied",
    });
    setTimeout(() => {
      this.setState({
        isPopupSuccess: false,
      });
    }, 2000);
  };

  getTimeOfStartDate(dateTime) {
    var date = new Date(dateTime); // some mock date
    var milliseconds = date.getTime();
    return milliseconds;
  }

  CountdownTimer({ days, hours, minutes, seconds, completed }) {
    if (completed) {
      // Render a completed state
      return "Starting";
    } else {
      // Render a countdowns
      var dayPrint = days > 0 ? days + "d" : "";
      return (
        <span>
          {dayPrint} {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s
        </span>
      );
    }
  }



  collectionLink = async (item) => {
    const formattedName = item.name.replace(/\s+/g, '-'); // Replaces spaces with hyphens
    const formattedCollectionName = item.collectionName.replace(/\s+/g, '-'); // Replaces spaces with hyphens
      this.props.history.push(`${config.baseUrl}${formattedName}/${formattedCollectionName}`, {
          state: { collectionId: item.collectionId },
        });    
  };

  artistLink= async (item) => {
    
    const formattedName = item.creatorName.replace(/\s+/g, '-'); // Replaces spaces with hyphens

    this.props.history.push(`${config.baseUrl}${formattedName}`, {
      state: { artistId: item.creator },
    });
  };

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,

      slidesToShow:
        this.state.getCollectionDetailByArtistIdData.length > 3
          ? 4
          : this.state.getCollectionDetailByArtistIdData.length,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
      ],
    };
    return (
      <>
        <div className="body_bg">
          <Helmet>
            <title>{this.state.seoList?.title}</title>
            <meta title={this.state.seoList?.pageTitle} content="IE=edge" />
            <meta
              name="description"
              content={this.state.seoList?.description
                ?.replace(/<[^>]*>?/gm, "")
                .substring(0, 170)}
            />
          </Helmet>
          <Header1 />
          <Popup
            isPopupError={this.state.isPopupError}
            isPopupSuccess={this.state.isPopupSuccess}
            msg={this.state.msg}
          />
          <Dialog
            title="Warning"
            icon="warning-sign"
            className="transaction-process"
            style={{
              color: "red",
              zIndex: "1111",
            }}
            isOpen={this.state.isDialogOpen}
            isCloseButtonShown={false}
          >
            <div className="text-center">
              <BarLoader color="#e84747" height="2" />
              <br />
              <h4 style={{ color: "#000" }}>Transaction under process...</h4>
              <p style={{ color: "red" }}>
                Please do not refresh page or close tab.
              </p>
              <div></div>
            </div>
          </Dialog>

          {/*---------------------- Item Activity Section Start -------------------- */}

          <section className="itemactivity pt-lg-5 pt-3">
            <div className="container-fluid px-lg-5">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 mb-4">
                  <Fade left>
                    <div className="card afgenesiscard">
                      <div className="row g-0">
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="itemcard mb-lg-0 mb-md-5">
                            {this.state.listSingleItemData.file_type ===
                            "image" ? (
                              <a
                                href={
                                  config.pinataUrl +
                                  this.state.listSingleItemData.ImageFileHash
                                }
                                target="_blank"
                              >
                                <img
                                  className="afgenesisimg item img-fluid"
                                  src={
                                    config.imageUrl1 +
                                    this.state.listSingleItemData.image
                                  }
                                  style={{ width: "650px", height: "504px" }}
                                  alt="itemback"
                                />
                              </a>
                            ) : this.state.listSingleItemData.file_type ===
                              "video" ? (
                              <Player
                                className="afgenesisimg item img-fluid"
                                style={{ width: "650px", height: "504px" }}
                                alt="itemback"
                                src={`${config.imageUrl1}${this.state.listSingleItemData.image}`}
                              />
                            ) : (
                              ""
                            )}

                            <div className="viewbox">
                              <div>
                                {/* <a href='#' className='custombtn view'><span className='mr-2'><i className="fal fa-eye"></i></span>6 Views</a> */}
                                {/* <a href='#' className='custombtn view'><span className='mr-2'><img className="" src="mercafor_dashboard/assets/img/eye.svg" alt="eye" /></span>6 Views</a> */}
                                <span className="custombtn view">
                                  <span className="mr-2">
                                    <img
                                      className=""
                                      src="mercafor_dashboard/assets/img/eye.svg"
                                      alt="eye"
                                    />
                                  </span>
                                  {this.state.listSingleItemData.viewCount}{" "}
                                  Views
                                </span>
                              </div>

                              <div>
                                {/* <a href='#' className='custombtn view'><span className='mr-2'><i className="fal fa-eye"></i></span>6 Views</a> */}
                                {/* <a href='#' className='custombtn view'><span className='mr-2'><img className="" src="mercafor_dashboard/assets/img/eye.svg" alt="eye" /></span>6 Views</a> */}
                                <span className="custombtn view">
                                  <span
                                    style={{ cursor: "pointer" }}
                                    className="mr-2"
                                    onClick={this.itemLikedAction.bind(
                                      this,
                                      this.state.listSingleItemData
                                    )}
                                  >
                                    <img
                                      className=""
                                      src="mercafor_dashboard/assets/img/itemheart.svg"
                                      alt="itemheart"
                                    />
                                  </span>
                                  {this.state.listSingleItemData.likeCount}{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="card-body px-0">
                            {/* <div className=" mb-3">
                              <a
                                href="#"
                                className="custombtn listsale"
                                style={{ fontSize: "16px", fontWeight: "400" }}
                              >
                                Physical Artwork
                              </a>
                            </div> */}
                            <div className="genesistext">
                              <h4 className="mt-md-5">
                                {this.state.listSingleItemData.name}
                              </h4>
                              <h6 className="my-4">
                                {this.state.listSingleItemData.sell_type === 2
                                  ? "Initial Bid Price: "
                                  : "Price: "}
                                {this.state.listSingleItemData.price}{" "}
                                {this.state.listSingleItemData.artistType == 0
                                  ? config.currencyType
                                  : config.currencyTypeSep}
                                {/* ($
                                {this.state?.liveCryptoPrice[0]?.current_price *
                                  this.state?.listSingleItemData?.price}
                                ) */}
                              </h6>
                              {this.state.listSingleItemData.sell_type === 2 ? (
                                <>
                                  <h6 className="my-4">
                                    Sale Ends in :&nbsp;
                                    {this.state.expData1 >
                                    this.state.newDateData ? (
                                      <Countdown
                                        date={this.getTimeOfStartDate(
                                          this.state.expData1
                                        )}
                                        renderer={this.CountdownTimer}
                                      />
                                    ) : (
                                      "Sale End"
                                    )}
                                  </h6>

                                  <h6 className="my-4">
                                    {this.state.latestBidData[0]?.amount
                                      ? "Minimum bid price :" +
                                          this.state.listSingleItemData
                                            ?.anyBid ===
                                        0
                                        ? parseFloat(
                                            (this.state.listSingleItemData
                                              ?.bid_increasing_amount *
                                              this.state.listSingleItemData
                                                .price) /
                                              100 +
                                              this.state.listSingleItemData
                                                .price
                                          ) +
                                          " " +
                                          (this.state.listSingleItemData
                                            .artistType == 0
                                            ? config.currencyType
                                            : config.currencyTypeSep)
                                        : this.state.listSingleItemData
                                            ?.lastBid === 0
                                        ? "Current Bid Price: " +
                                          parseFloat(
                                            this.state.listSingleItemData.price
                                          ) +
                                          " " +
                                          (this.state.listSingleItemData
                                            .artistType == 0
                                            ? config.currencyType
                                            : config.currencyTypeSep)
                                        : "Minimum bid price :" +
                                          parseFloat(
                                            (this.state.listSingleItemData
                                              ?.bid_increasing_amount *
                                              this.state.latestBidData[0]
                                                ?.amount) /
                                              100 +
                                              this.state.latestBidData[0]
                                                ?.amount
                                          ).toFixed(4) +
                                          " " +
                                          (this.state.listSingleItemData
                                            .artistType == 0
                                            ? config.currencyType
                                            : config.currencyTypeSep)
                                      : "No Bids Yet"}{" "}
                                    {/* ($
                              {this.state?.liveCryptoPrice[0]?.current_price *
                                this.state?.listSingleItemData?.price}
                              ) */}
                                  </h6>
                                </>
                              ) : (
                                ""
                              )}
                              <div className="tonyjade mb-3">
                                <p>
                                  By{" "}
                                  <a
                                  onClick={this.artistLink.bind(this, this.state.listSingleItemData)}

                                    href="javascript:void(0)"
                                    // {`${config.baseUrl}collection/${this.state.listSingleItemData.creator}`}
                                  >
                                    {this.state.listSingleItemData.creatorName}
                                  </a>
                                </p>
                                <p>
                                  Owned by{" "}
                                  <a
    href={
        this.state.listSingleItemData.artistType == 0 
            ? config.blockchinUrlAddress + (
                this.state.listSingleItemData.ownerName
                    ? this.state.listSingleItemData.ownerName
                    : this.state?.latestOwner[0]?.address
                        ? this.state?.latestOwner[0]?.address
                        : this.state.listSingleItemData.owner_address
              )
            : config.blockchinUrlSep + (
                this.state.listSingleItemData.ownerName
                    ? this.state.listSingleItemData.ownerName
                    : this.state?.latestOwner[0]?.address
                        ? this.state?.latestOwner[0]?.address
                        : this.state.listSingleItemData.owner_address
              )
    }
    target="_blank"
>                            {this.state.listSingleItemData.ownerName
                                      ? this.state.listSingleItemData.ownerName
                                      : this.state?.latestOwner[0]?.address ? this.state?.latestOwner[0]?.address?.substring(
                                          0,
                                          6
                                        ) +
                                        "...." +
                                        this.state?.latestOwner[0]?.address?.substring(
                                          this.state?.latestOwner[0]?.address
                                            ?.length - 6
                                        ):this.state.listSingleItemData.owner_address?.substring(
                                          0,
                                          6
                                        ) +
                                        "...." +
                                        this.state.listSingleItemData.owner_address?.substring(
                                          this.state.listSingleItemData.owner_address
                                            ?.length - 6
                                        )}
                                    &nbsp;
                                  </a>
                                  <CopyToClipboard
                                    text={
                                      this.state.listSingleItemData.artistType == 0 
            ? config.blockchinUrlAddress + (
                this.state.listSingleItemData.ownerName
                    ? this.state.listSingleItemData.ownerName
                    : this.state?.latestOwner[0]?.address
                        ? this.state?.latestOwner[0]?.address
                        : this.state.listSingleItemData.owner_address
              )
            : config.blockchinUrlSep + (
                this.state.listSingleItemData.ownerName
                    ? this.state.listSingleItemData.ownerName
                    : this.state?.latestOwner[0]?.address
                        ? this.state?.latestOwner[0]?.address
                        : this.state.listSingleItemData.owner_address
              )
                                    }
                                  >
                                    <span
                                      title="Click to Copy"
                                      className="mr-copylink"
                                      id="token-buy-button"
                                      onClick={this.copieBtn}
                                      style={{
                                        cursor: "pointer",
                                        color: "rgb(157 81 255)",
                                      }}
                                    >
                                      <i className="fa fa-copy "></i>
                                    </span>
                                  </CopyToClipboard>
                                </p>
                              </div>
                            </div>
                            {this.state.listSingleItemData.description?.length >
                            MAX_LENGTH ? (
                              <p
                                className="mb-2 configrat"
                                title={
                                  this.state.listSingleItemData.description
                                }
                              >
                                {`${this.state.listSingleItemData.description.substring(
                                  0,
                                  MAX_LENGTH
                                )}...`}
                              </p>
                            ) : (
                              <p
                                className="mb-2 configrat"
                                title={
                                  this.state.listSingleItemData.description
                                }
                              >
                                {this.state.listSingleItemData.description}
                              </p>
                            )}
                            {this.state.listSingleItemData.is_sold === 1 ? (
                              <label style={{ color: "red", fontSize: "26px" }}>
                                Sold Out
                              </label>
                            ) : (
                              <>
                                <div className="pt-5 listing_box">
                                  {(this.state.listSingleItemData.ownerName
                                    ? this.state.listSingleItemData.ownerName
                                    : this.state?.latestOwner[0]?.address) ===
                                  this.loginData.data?.walletAddress ? (
                                    this.state.listSingleItemData.sell_type ===
                                    1 ? (
                                      <>
                                        <span className="mr-3 ownernft">
                                          You are owner of this NFT
                                        </span>
                                        <br></br>

                                        <button
                                          type="button"
                                          className="btn btn-reset-primary mb-3"
                                          onClick={this.cancelListing.bind(
                                            this
                                          )}
                                        >
                                          Cancel Listing
                                        </button>
                                      </>
                                    ) : this.state.listSingleItemData.anyBid ===
                                      0 ? (
                                      <>
                                        <span className="mr-3 ownernft">
                                          You are owner of this NFT
                                        </span>

                                        <button
                                          onClick={this.cancelListing.bind(
                                            this
                                          )}
                                          type="submit"
                                          className="btn btn-reset-primary mb-3"
                                        >
                                          Cancel Listing
                                        </button>
                                      </>
                                    ) : (
                                      <span className="mr-3 ownernft">
                                        You are owner of this NFT
                                      </span>
                                    )
                                  ) : this.state.listSingleItemData
                                      .sell_type === 1 ? (
                                    <button
                                      type="button"
                                      className="btn btn-reset-primary mb-3"
                                      onClick={(e) => {
                                        this.buyNft(e);
                                      }}
                                    >
                                      Buy Now
                                    </button>
                                  ) : (
                                    <button
                                      data-toggle="modal"
                                      data-target="#exampleModal"
                                      disabled={
                                        this.state.expData1 <
                                        this.state.newDateData
                                      }
                                      type="button"
                                      className="btn btn-reset-primary mb-3"
                                    >
                                      Place Bid
                                    </button>
                                  )}
                                  &nbsp;&nbsp;
                                  {this.state.listSingleItemData.isWishList ===
                                  0 ? (
                                    <button
                                      onClick={this.WishListAction.bind(this)}
                                      type="button"
                                      className="btn btn-reset-primary mb-3"
                                    >
                                      Add To wishlist
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn-reset-primary mb-3"
                                      disabled
                                    >
                                      Already In wishlist
                                    </button>
                                  )}
                                </div>
                              </>
                            )}
                            <div className="metadata">
                              <a
                                target="_blank"
                                href={
                                  (this.state.listSingleItemData
                                            .artistType == 0 ? config.blockchinUrlAddress: config.blockchinUrlSep) +
                                            (this.state.listSingleItemData
                                            .artistType == 0 ? config.marketplaceContract: config.marketplaceContractBase)
                                }
                              >
                                <h6 className="">Blockchain view</h6>
                              </a>

                              <a
                                target="_blank"
                                href={
                                  config.ipfsUrl +
                                  this.state.listSingleItemData.ipfs_hash
                                }
                              >
                                <h6 className="ml-4">IPFS view</h6>
                              </a>

                              <h6 className="ml-4">
                                Token ID:{" "}
                                {this.state.listSingleItemData.token_id}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </section>

          {/*---------------------- Item Activity Section End -------------------- */}

          {/*---------------------- Item Table Section Start -------------------- */}

          <section className="itematable py-lg-5 pt-3">
            <div className="container-fluid px-lg-5">
              <div className="row">
                <div className="col-12 text-center mb-4">
                  <h2 className="tophead">Item Activity</h2>
                  {/* <p>Our artists are at the bleeding edge of generative art, We also partner with traditional artists, and give them the support they need to relese generative art.</p> */}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8 col-md-12 col-12 mx-auto">
                  <Bounce top>
                    <div className="table-responsive itemscontent">
                      <table className="table table-hover mb-lg-0">
                        <thead>
                          <tr>
                            <th>Event</th>
                            <th>Price</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.itemActivityData.length == 0 ?  
                          
                          <tr>
                                <td colSpan="6" className="text-center">No Activity found</td>
                                </tr>:
                          this.state.itemActivityData.map((item) => (
                            <Fragment key={item.id}>
                              <tr>
                                <td>{item.nft_activity_type}</td>
                                <td>
                                  {item.amount}{" "}
                                  {this.state.listSingleItemData.artistType == 0
                                    ? config.currencyType
                                    : config.currencyTypeSep}
                                </td>
                                <td>
                                  {" "}
                                  {item.from_user == "null" ? (
                                    this.state.listSingleItemData.creatorName
                                  ) : item.nft_activity_type_id == 3 ? (
                                    this.state.listSingleItemData.creatorName
                                  ) : item.nft_activity_type_id == 2 ? (
                                    <>
                                      <div>
                                        {item?.address?.substring(0, 4) +
                                          "...." +
                                          item?.address?.substring(
                                            item?.address?.length - 4
                                          )}
                                        <CopyToClipboard text={item.address}>
                                          <span
                                            title="Click to Copy"
                                            className="mr-copylink"
                                            id="token-buy-button"
                                            onClick={this.copieBtn}
                                            style={{
                                              cursor: "pointer",
                                              color: "rgb(157 81 255)",
                                            }}
                                          >
                                            &nbsp;
                                            <i className="fa fa-copy "></i>
                                          </span>
                                        </CopyToClipboard>
                                      </div>
                                    </>
                                  ) : item.nft_activity_type_id == 5 ? (
                                    <>
                                      <div>
                                        {item?.address?.substring(0, 4) +
                                          "...." +
                                          item?.address?.substring(
                                            item?.address?.length - 4
                                          )}
                                        <CopyToClipboard text={item.address}>
                                          <span
                                            title="Click to Copy"
                                            className="mr-copylink"
                                            id="token-buy-button"
                                            onClick={this.copieBtn}
                                            style={{
                                              cursor: "pointer",
                                              color: "rgb(157 81 255)",
                                            }}
                                          >
                                            &nbsp;
                                            <i className="fa fa-copy "></i>
                                          </span>
                                        </CopyToClipboard>
                                      </div>
                                    </>
                                  ) : item.nft_activity_type_id === 4 ? (
                                    item.from_name ? item.from_name :
                                    <>
                                    <div>
                                      {item?.sellerAddress?.substring(0, 4) +
                                        "...." +
                                        item?.sellerAddress?.substring(
                                          item?.sellerAddress?.length - 4
                                        )}
                                      <CopyToClipboard text={item.sellerAddress}>
                                        <span
                                          title="Click to Copy"
                                          className="mr-copylink"
                                          id="token-buy-button"
                                          onClick={this.copieBtn}
                                          style={{
                                            cursor: "pointer",
                                            color: "rgb(157 81 255)",
                                          }}
                                        >
                                          &nbsp;
                                          <i className="fa fa-copy "></i>
                                        </span>
                                      </CopyToClipboard>
                                    </div>
                                  </>
                                  ) : item.nft_activity_type_id == 6 ? (
                                    <>
                                      <div>
                                        {item?.oactualOwner?.substring(0, 4) +
                                          "...." +
                                          item?.oactualOwner?.substring(
                                            item?.oactualOwner?.length - 4
                                          )}
                                        <CopyToClipboard text={item.oactualOwner}>
                                          <span
                                            title="Click to Copy"
                                            className="mr-copylink"
                                            id="token-buy-button"
                                            onClick={this.copieBtn}
                                            style={{
                                              cursor: "pointer",
                                              color: "rgb(157 81 255)",
                                            }}
                                          >
                                            &nbsp;
                                            <i className="fa fa-copy "></i>
                                          </span>
                                        </CopyToClipboard>
                                      </div>
                                    </>
                                  ) : item.nft_activity_type_id == 7 && this.type == 1 ? (
                                    <>
                                      <div>
                                        {item?.address?.substring(0, 4) +
                                          "...." +
                                          item?.address?.substring(
                                            item?.address?.length - 4
                                          )}
                                        <CopyToClipboard text={item.address}>
                                          <span
                                            title="Click to Copy"
                                            className="mr-copylink"
                                            id="token-buy-button"
                                            onClick={this.copieBtn}
                                            style={{
                                              cursor: "pointer",
                                              color: "rgb(157 81 255)",
                                            }}
                                          >
                                            &nbsp;
                                            <i className="fa fa-copy "></i>
                                          </span>
                                        </CopyToClipboard>
                                      </div>
                                    </>
                                  ): item.nft_activity_type_id == 7 && this.type == 2 ? (
                                    <>
                                      <div>
                                        {item?.sellerAddress?.substring(0, 4) +
                                          "...." +
                                          item?.sellerAddress?.substring(
                                            item?.sellerAddress?.length - 4
                                          )}
                                        <CopyToClipboard text={item.sellerAddress}>
                                          <span
                                            title="Click to Copy"
                                            className="mr-copylink"
                                            id="token-buy-button"
                                            onClick={this.copieBtn}
                                            style={{
                                              cursor: "pointer",
                                              color: "rgb(157 81 255)",
                                            }}
                                          >
                                            &nbsp;
                                            <i className="fa fa-copy "></i>
                                          </span>
                                        </CopyToClipboard>
                                      </div>
                                    </>
                                  ) : item.nft_activity_type_id == 8 ? (
                                    <>
                                      <div>
                                        {item?.from_user?.substring(0, 4) +
                                          "...." +
                                          item?.from_user?.substring(
                                            item?.from_user?.length - 4
                                          )}
                                        <CopyToClipboard text={item.from_user}>
                                          <span
                                            title="Click to Copy"
                                            className="mr-copylink"
                                            id="token-buy-button"
                                            onClick={this.copieBtn}
                                            style={{
                                              cursor: "pointer",
                                              color: "rgb(157 81 255)",
                                            }}
                                          >
                                            &nbsp;
                                            <i className="fa fa-copy "></i>
                                          </span>
                                        </CopyToClipboard>
                                      </div>
                                    </>
                                  ): (
                                    item.to_name
                                  )}
                                </td>
                                <td>
                                  {item.to_user === 0 &&
                                  item.from_user === null ? (
                                    "-"
                                  ) : item.nft_activity_type_id === 3 ? (
                                    <>
                                      <div>
                                        {item?.address?.substring(0, 4) +
                                          "...." +
                                          item?.address?.substring(
                                            item?.address?.length - 4
                                          )}
                                        <CopyToClipboard text={item.address}>
                                          <span
                                            title="Click to Copy"
                                            className="mr-copylink"
                                            id="token-buy-button"
                                            onClick={this.copieBtn}
                                            style={{
                                              cursor: "pointer",
                                              color: "rgb(157 81 255)",
                                            }}
                                          >
                                            &nbsp;
                                            <i className="fa fa-copy "></i>
                                          </span>
                                        </CopyToClipboard>
                                      </div>
                                    </>
                                  ) : item.nft_activity_type_id === 2 ? (
                                    <>-</>
                                  )  : item.nft_activity_type_id == 4 ? (
                                    item.to_name? item.to_name :
                                    <>
                                    <div>
                                      {item?.address?.substring(0, 4) +
                                        "...." +
                                        item?.address?.substring(
                                          item?.address?.length - 4
                                        )}
                                      <CopyToClipboard text={item.address}>
                                        <span
                                          title="Click to Copy"
                                          className="mr-copylink"
                                          id="token-buy-button"
                                          onClick={this.copieBtn}
                                          style={{
                                            cursor: "pointer",
                                            color: "rgb(157 81 255)",
                                          }}
                                        >
                                          &nbsp;
                                          <i className="fa fa-copy "></i>
                                        </span>
                                      </CopyToClipboard>
                                    </div>
                                  </>
                                  ) : item.nft_activity_type_id == 6 ? (
                                    item.to_name? item.to_name :
                                    <>
                                    <div>
                                      {item?.address?.substring(0, 4) +
                                        "...." +
                                        item?.address?.substring(
                                          item?.address?.length - 4
                                        )}
                                      <CopyToClipboard text={item.address}>
                                        <span
                                          title="Click to Copy"
                                          className="mr-copylink"
                                          id="token-buy-button"
                                          onClick={this.copieBtn}
                                          style={{
                                            cursor: "pointer",
                                            color: "rgb(157 81 255)",
                                          }}
                                        >
                                          &nbsp;
                                          <i className="fa fa-copy "></i>
                                        </span>
                                      </CopyToClipboard>
                                    </div>
                                  </>
                                  ): item.nft_activity_type_id == 8 ? (
                                   
                                    <div>
                                      {item?.address?.substring(0, 4) +
                                        "...." +
                                        item?.address?.substring(
                                          item?.address?.length - 4
                                        )}
                                      <CopyToClipboard text={item.address}>
                                        <span
                                          title="Click to Copy"
                                          className="mr-copylink"
                                          id="token-buy-button"
                                          onClick={this.copieBtn}
                                          style={{
                                            cursor: "pointer",
                                            color: "rgb(157 81 255)",
                                          }}
                                        >
                                          &nbsp;
                                          <i className="fa fa-copy "></i>
                                        </span>
                                      </CopyToClipboard>
                                    </div>
                                  ): (
                                    item.from_name
                                  )}
                                </td>
                                <td>
                                  {" "}
                                  {moment(item.datetime).format("YYYY-MM-DD")}
                                </td>
                              </tr>
                            </Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Bounce>
                </div>
              </div>
            </div>
          </section>

          {/*---------------------- Item Table Section End -------------------- */}

          {/*---------------------- Seasky Collection Section Start -------------------- */}

          <section className="seasky_collection py-5">
            <div className="container-fluid px-lg-5">
              <div className="row">
                <div className="col-12 text-center mb-4">
                  <h2 className="tophead">More from this collection</h2>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                  <div className="">
                    {/* <div className="owl-nav">
                      <div className="customPrevBtn">
                        <span>
                          <img
                            src="mercafor_dashboard/assets/img/arrowwhite.svg"
                            alt="arrowwhite"
                          />
                        </span>
                      </div>
                      <div className="customNextBtn">
                        <span>
                          <img
                            src="mercafor_dashboard/assets/img/arrowwhite.svg"
                            alt="arrowwhite"
                          />
                        </span>
                      </div>
                    </div> */}
                    <div className="">
                      <div className="">
                        {this.state.getCollectionDetailByArtistIdData.length ===
                        0 ? (
                          <h2 className="text-center">No Collection Found</h2>
                        ) : (
                          ""
                        )}
                        <Zoom top>
                          <Slider {...settings}>
                            {this.state.getCollectionDetailByArtistIdData.map(
                              (item) => {
                                return (
                                  <div className="item" key={item.collectionId}>
                                    <div className="card feature">
                                      <div className="">
                                        <a
                                          href="javascript:void(0)"
                                    onClick={this.collectionLink.bind(this,item)}

                                        >
                                          <img
                                            className="card-img-top img-fluid"
                                            src={
                                              config.imageUrl1 +
                                              item.collectionImage
                                            }
                                            alt="Jollykoh"
                                          />
                                        </a>
                                      </div>
                                      <div className="card-body">
                                        <h6
                                          className="card-title text-center"
                                          style={{
                                            fontFamily: "Catamaran",
                                            fontWeight: "700",
                                          }}
                                        >
                                          {item.collectionName}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </Slider>
                        </Zoom>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-lg-12 col-md-12 col-12 mb-5 mt-5">
                  <div className="text-center">
                    <a href="#" className="custombtn ">
                      View Collection
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </section>

          {/*---------------------- Seasky Collection Section End -------------------- */}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data-backdrop="static"
            data-keyboard="false"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content" style={{ background: "#fff" }}>
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Place Bid
                  </h5>
                  <button
                    type="button"
                    className="close"
                    style={{ color: "#000" }}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="form-group">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Please Enter New{" "}
                        {this.state.listSingleItemData.artistType == 0
                          ? config.currencyType
                          : config.currencyTypeSep}{" "}
                        Price
                      </label>
                      <input
                        type="text"
                        onKeyPress={(event) => {
                          if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={this.state.bidPrice}
                        name="Bid Amount"
                        onChange={(e) => {
                          this.changeBidPrice(e);
                        }}
                        className="form-control"
                        id="recipient-name"
                      />
                      <span style={{ color: "red" }}>
                        {this.state.priceError}
                      </span>
                    </div>
                  </form>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    style={{
                      width: "100%",
                      marginBottom: "-10px",
                      textAlign: "center",
                    }}
                    className="btn btn-reset-primary mb-3"
                    onClick={(e) => {
                      this.buyNft(e);
                    }}
                  >
                    Place Bid
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Footer1 />
        </div>
      </>
    );
  }
}
