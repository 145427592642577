import React, { Component } from 'react';
import Footer1 from '../directives/footer1';
import Header1 from '../directives/header1';
import axios from 'axios'
import config from '../config/config';
import moment from 'moment';
export default class blogDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            setBlogData: [],
            setfrontNews: 0
        }
        const { match: { params } } = this.props;
        this.id = params.id
    }

    async getDetailBlogs() {
        await axios.post(`${config.apiUrl}/getBlogDetail`, { 'blog_id': this.id },)
            .then(result => {
                if (result.data.success === true) {
                    this.setState({
                        setBlogData: result.data.response,
                    })
                }
                else if (result.data.success === false) {

                }
            })
            .catch(err => {
            })
    }

    componentDidMount() {
        this.getDetailBlogs()
    }

    render() {
        return (
            <>
                <Header1 />
                <div className="container px-lg-5">
                    <div className='row  mb-5'>
                        <div className='col-lg-12' style={{ marginTop: '25px' }}>
                            <div className='blogdetail'>
                        <h1 className="mb-1 nft_world111">
{this.state.setBlogData.heading}                            </h1>
                            <small style={{ marginTop: '8px' }} className="drop-subheading px-2"> {moment(this.state.setBlogData.date).format('LL')}</small>
                            <div className='position-relative'>
                                <div className='blogImage'>
                                <img src={config.imageUrl1 + this.state.setBlogData.image} width="100%" />
                                </div>
                                <div class="tag mt-2 px-2" style={{top:"0", bottom:"inherit"}}><p class="mb-0">{this.state.setBlogData.title}</p></div>
                            </div>
                            <p className='dexription-single' dangerouslySetInnerHTML={{ __html: this.state.setBlogData.description }}>
                                 
                                </p>
                            {/* <h1 className="mb-1 nft_world111">
                                {this.state.setBlogData.title}
                            </h1>
                            <small style={{ marginTop: '8px' }} className="drop-subheading"> {moment(this.state.setBlogData.date).format('LL')}</small>
                            <img style={{ marginTop: '15px' }} src={config.imageUrl1 + this.state.setBlogData.image} width="100%" />
                            <p className='dexription-single'>{this.state.setBlogData.description}</p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer1 />
            </>
        );
    }
}
