import React, { Component } from "react";
import Footer1 from "../directives/footer1";
import Header1 from "../directives/header1";
import axios from "axios";
import config from "../config/config";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
import { Fade } from "react-reveal";
import { getCurrentProvider } from "../components/provider";
import Web3 from "web3";
import { ToastContainer, toast } from "react-toastify";

import { Helmet } from "react-helmet";
import Countdown, { zeroPad } from "react-countdown";
import { Player } from "video-react";
import Select, { components } from "react-select";
import moment from "moment";
import Slider from "react-slick";
import "./css/marketplace.css";
import { FaChevronDown } from "react-icons/fa"; // Example using react-icons
import { Dropdown, IconButton } from "rsuite";
import FunnelIcon from "@rsuite/icons/Funnel";
import { ColorRing } from "react-loader-spinner";

export default class marketplaceNew extends Component {
  constructor(props) {
    super(props);
    this.loginData = !Cookies.get("artfungibleUserloginSuccess")
      ? []
      : JSON.parse(Cookies.get("artfungibleUserloginSuccess"));

    this.state = {
      getListItemAPIData: [],
      seoList: "",
      minPrice: "",
      maxPrice: "",
      isDropdownOpen: false, // Controls dropdown visibility

      searchAnything: "",
      nftTypeData: 0,
      getAllData: [],
      category_list: [],
      newDateData: "",
      getAllDataPhysical: [],
      getListItemAPIDataPhysical: [],
      loaderSpinner: true, // Loader starts as true until data is fetched
      dataFetched: false, // This will track if data is fully fetched
    };

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    const day = ("0" + currentDate.getDate()).slice(-2);
    const hours = ("0" + currentDate.getHours()).slice(-2);
    const minutes = ("0" + currentDate.getMinutes()).slice(-2);
    const seconds = ("0" + currentDate.getSeconds()).slice(-2);

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    setTimeout(() => {
      this.setState({
        newDateData: formattedDate,
      });
    });
  }

  componentDidMount() {
    this.getListItemAPI();
    this.getListItemAPIPhysical();
    this.categoryList();
    var parts = window.location.href.split("/");
    var lastSegment = parts.pop() || parts.pop(); // handle potential trailing slash

    this.getSeoAPI(lastSegment);
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    const { minPrice, maxPrice } = this.state;
    // this.closeDropdown(); // Close dropdown after action
    setTimeout(() => {
      this.setState({
        isDropdownOpen: false, // Ensure the dropdown closes properly
      });
    }, 500);
    // Basic validation to check if max price is greater than min price
    if (Number(maxPrice) < Number(minPrice)) {
      toast.error("Max price should be greater than or equal to min price.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    const matchedDataByPriceFil = this.state.getAllData.filter((item) => {
      return (
        (this.state.minPrice === "" || item.price >= this.state.minPrice) &&
        (this.state.maxPrice === "" || item.price <= this.state.maxPrice)
      );
    });

    const matchedDataByPricePhyFil = this.state.getAllDataPhysical.filter(
      (item) => {
        return (
          (this.state.minPrice === "" || item.price >= this.state.minPrice) &&
          (this.state.maxPrice === "" || item.price <= this.state.maxPrice)
        );
      }
    );

    // Update the state once with both filtered results
    this.setState({
      getListItemAPIData: matchedDataByPriceFil,
      getListItemAPIDataPhysical: matchedDataByPricePhyFil,
    });

    // You can now perform further actions, such as applying a filter
  };

  async categoryList() {
    axios({
      method: "get",
      url: `${config.apiUrl}/getAllCategory`,
    })
      .then((result) => {
        if (result.data.success === true) {
          this.setState({
            category_list: result.data.response,
          });
        } else if (result.data.success === false) {
          this.setState({
            category_list: [],
          });
        }
      })
      .catch((err) => {
        this.setState({
          category_list: [],
        });
      });
  }

  async getSeoAPI(lastSegment) {
    await axios({
      method: "post",
      url: `${config.apiUrl}/getSEO`,
      data: { pageName: lastSegment },
    })
      .then(async (response) => {
        if (response.data.success === true) {
          this.setState({
            seoList: response.data.data,
          });
        } else if (response.data.success === false) {
        }
      })
      .catch((err) => {});
  }

  handleSelectChange = (selectedOption) => {
    const matchedDataCAte = this.state.getAllData.filter(
      (item) => item.categoryId == selectedOption.value
    );
    const matchedDataCAtePhy = this.state.getAllDataPhysical.filter(
      (item) => item.categoryId == selectedOption.value
    );

    setTimeout(() => {
      this.setState({
        isDropdownOpen: false, // Ensure the dropdown closes properly
      });
    }, 500);
    // Combine all setState updates in a single call
    if (matchedDataCAte.length > 0 || matchedDataCAtePhy.length > 0) {
      this.setState({
        getListItemAPIData: matchedDataCAte,
        getListItemAPIDataPhysical: matchedDataCAtePhy,
        isDropdownOpen: false, // Ensure the dropdown closes properly
      });
    } else {
      // Close the dropdown even if no matching data is found
      this.setState({ isDropdownOpen: false });
    }
  };

  async getListItemAPI(item) {
    this.closeDropdown(); // Close dropdown after action

    await axios({
      method: "get",
      url: `${config.apiUrl}listitemUser`,
      headers: { Authorization: this.loginData.Token },
    }).then((response) => {
      if (response.data.success === true) {
        // this.setState({
        //   getListItemAPIData: modifiedData,
        // });

        if (!item) {
          let _getListItemAPIData = [];

          response.data.response.map((item) => {
            const timezoneOffset = new Date().getTimezoneOffset() * -1;

            let newDate = new Date(item.expiry_date);
            let stDate = new Date(item.start_date1);

            newDate.setMinutes(newDate.getMinutes() + timezoneOffset);
            item.expiry_date = moment(newDate).format("YYYY-MM-DD HH:mm:SS"); // Add the modified date to the item
            item.start_date1 = moment(stDate).format("YYYY-MM-DD HH:mm:SS"); // Add the modified date to the item

            if (item.firstPurchase === 1) {
              _getListItemAPIData.push(item);
            }
          });

          this.setState({
            getListItemAPIData: _getListItemAPIData,
            getAllData: _getListItemAPIData,
            loaderSpinner: false, // Loader stopped
            dataFetched: true, // Data fetched
          });
        } else if (item == 1) {
          // alert('12')

          this.setState({
            getListItemAPIData: response.data?.response.filter(
              (item) => item.firstPurchase === 1 && item.sell_type === 1
            ),
            getAllData: response.data?.response.filter(
              (item) => item.firstPurchase === 1 && item.sell_type === 1
            ),
            loaderSpinner: false, // Loader stopped
            dataFetched: true, // Data fetched
          });
        } else if (item == 2) {
          // alert('123')

          this.setState({
            getListItemAPIData: response.data?.response.filter(
              (item) => item.firstPurchase == 1 && item.sell_type == 2
            ),
            getAllData: response.data?.response.filter(
              (item) => item.firstPurchase == 1 && item.sell_type == 2
            ),
            loaderSpinner: false, // Loader stopped
            dataFetched: true, // Data fetched
          });
        }
      }
    });
  }
  async getListItemAPIPhysical(item) {
    this.closeDropdown(); // Close dropdown after action
    await axios({
      method: "get",
      url: `${config.apiUrl}listitemUserPhysical`,
      headers: { Authorization: this.loginData.Token },
    }).then((response) => {
      if (response.data.success == true) {
        // this.setState({
        //   getListItemAPIData: modifiedData,
        // });

        if (!item) {
          let _getListItemAPIData = [];

          response.data.response.map((item) => {
            const timezoneOffset = new Date().getTimezoneOffset() * -1;
            let newDate = new Date(item.expiry_date);
            let stDate = new Date(item.start_date1);

            newDate.setMinutes(newDate.getMinutes() + timezoneOffset);
            item.expiry_date = moment(newDate).format("YYYY-MM-DD HH:mm:SS"); // Add the modified date to the item
            item.start_date1 = moment(stDate).format("YYYY-MM-DD HH:mm:SS"); // Add the modified date to the item

            if (item.firstPurchase == 1) {
              _getListItemAPIData.push(item);
            }
          });

          this.setState({
            getListItemAPIDataPhysical: _getListItemAPIData,
            getAllDataPhysical: _getListItemAPIData,
            loaderSpinner: false, // Loader stopped
            dataFetched: true, // Data fetched
          });
        } else if (item == 1) {
          // alert('12')

          this.setState({
            getListItemAPIDataPhysical: response.data?.response.filter(
              (item) => item.firstPurchase == 1 && item.sell_type == 1
            ),
            getAllDataPhysical: response.data?.response.filter(
              (item) => item.firstPurchase == 1 && item.sell_type == 1
            ),
            loaderSpinner: false, // Loader stopped
            dataFetched: true, // Data fetched
          });
        } else if (item == 2) {
          this.setState({
            getListItemAPIDataPhysical: response.data?.response.filter(
              (item) => item.firstPurchase == 1 && item.sell_type == 2
            ),
            getAllDataPhysical: response.data?.response.filter(
              (item) => item.firstPurchase == 1 && item.sell_type == 2
            ),
            loaderSpinner: false, // Loader stopped
            dataFetched: true, // Data fetched
          });
        }
      }
    });
  }
  async buyNft(item) {
    try {
      if (this.loginData.length <= 0) {
        alert("Please connect wallet");
        return;
      }

      const providers = await getCurrentProvider();
      const web3 = new Web3(providers);
      let userAddress = this.loginData.data.walletAddress;

      if (item.firstPurchase === 0) {
        //first mint and then transfer
        await this.redeem(
          web3,
          item.owner_address,
          item.price,
          item.signature,
          item.contract,
          userAddress
        );
      } else {
        //directly buy from  marketplace
        await this.buyNftFunc(web3, item.contract, item.tokenID, userAddress);
      }
    } catch (error) {}
  }

  async redeem(
    web3,
    owner_address,
    itemprice,
    signature,
    contract,
    userAddress
  ) {
    try {
      let balance = await web3.eth.getBalance(userAddress);

      let artistContractObject = new web3.eth.Contract(
        config.artistAbi,
        contract
      );
      let price = itemprice * 10 ** 18;
      let getDiscountPrice = await artistContractObject.methods
        .getDiscountPrice(userAddress, price)
        .call();
      getDiscountPrice = parseInt(getDiscountPrice);
      if (balance < getDiscountPrice) {
        toast.error("Insufficient Balance");
        return;
      }

      let contractParameter = [owner_address, price, signature];

      let redeem = await artistContractObject.methods.redeem(
        userAddress,
        contractParameter
      );

      let encoded_tx = redeem.encodeABI();

      let gasPrice = await web3.eth.getGasPrice();

      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: contract,
        from: userAddress,
        data: encoded_tx,
        value: getDiscountPrice,
      });

      let trx = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: contract,
        from: userAddress,
        data: encoded_tx,
        value: getDiscountPrice,
      });
    } catch (err) {}
  }

  async buyNftFunc(web3, contract, tokenID, userAddress) {
    try {
      let balance = await web3.eth.getBalance(userAddress);

      let marketplaceContractObject = new web3.eth.Contract(
        config.abiMarketplace,
        config.marketplaceContract
      );

      const tokendetails = await marketplaceContractObject.methods
        .getTokenDetails(contract, tokenID)
        .call();
      let price = parseInt(tokendetails.price);
      if (balance < price) {
        toast.error("Insufficient Balance");
        return;
      }

      let BuyNft = await marketplaceContractObject.methods.BuyNft(
        contract,
        tokenID
      );

      let encoded_tx = BuyNft.encodeABI();

      let gasPrice = await web3.eth.getGasPrice();

      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: config.marketplaceContract,
        from: userAddress,
        data: encoded_tx,
        value: price,
      });

      let trx = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: config.marketplaceContract,
        from: userAddress,
        data: encoded_tx,
        value: price,
      });
    } catch (err) {}
  }

  refreshWindow() {
    window.location.reload();
  }

  getTimeOfStartDate(dateTime) {
    var date = new Date(dateTime); // some mock date
    var milliseconds = date.getTime();
    return milliseconds;
  }

  CountdownTimer({ days, hours, minutes, seconds, completed }) {
    if (completed) {
      // Render a completed state
      return "Starting";
    } else {
      // Render a countdowns
      var dayPrint = days > 0 ? days + "d" : "";
      return (
        <span>
          {dayPrint} {zeroPad(hours)}h {zeroPad(minutes)}m {zeroPad(seconds)}s
        </span>
      );
    }
  }

  nftType = async (id) => {
    this.setState({
      nftTypeData: id,
    });
  };

  PriceHeaderFilter = async (e) => {
    this.setState({ getListItemAPIData: [] });
    this.setState({ getListItemAPIDataPhysical: [] });

    if (e.target.value === "Lowtohigh") {
      var lowtohigh = this.state.getListItemAPIData.sort(
        (a, b) =>
          parseFloat(a.price).toFixed(6) - parseFloat(b.price).toFixed(6)
      );
      this.setState({ getListItemAPIData: lowtohigh });

      var lowtohigh = this.state.getListItemAPIDataPhysical.sort(
        (a, b) =>
          parseFloat(a.price).toFixed(6) - parseFloat(b.price).toFixed(6)
      );
      this.setState({ getListItemAPIDataPhysical: lowtohigh });
    } else if (e.target.value === "Hightolow") {
      var hightolow = this.state.getListItemAPIData.sort(
        (a, b) =>
          parseFloat(b.price).toFixed(6) - parseFloat(a.price).toFixed(6)
      );
      this.setState({ getListItemAPIData: hightolow });

      var hightolow = this.state.getListItemAPIDataPhysical.sort(
        (a, b) =>
          parseFloat(b.price).toFixed(6) - parseFloat(a.price).toFixed(6)
      );
      this.setState({ getListItemAPIDataPhysical: hightolow });
    } else if (e.target.value === "newesttooldest") {
      var newesttooldest = this.state.getListItemAPIData.sort(
        (a, b) => new Date(b.datetime) - new Date(a.datetime)
      );
      this.setState({ getListItemAPIData: newesttooldest });

      var newesttooldest = this.state.getListItemAPIDataPhysical.sort(
        (a, b) => new Date(b.datetime) - new Date(a.datetime)
      );
      this.setState({ getListItemAPIDataPhysical: newesttooldest });
    } else if (e.target.value === "mostLiked") {
      var likeCount = this.state.getListItemAPIData
        .slice()
        .sort((a, b) => b.likeCount - a.likeCount);
      this.setState({ getListItemAPIData: likeCount });

      var likeCount = this.state.getListItemAPIDataPhysical
        .slice()
        .sort((a, b) => b.likeCount - a.likeCount);
      this.setState({ getListItemAPIDataPhysical: likeCount });
    }
  };

  searchAnything = (e) => {
    const { value } = e.target;
    this.setState({ searchAnything: value });
    var regex = new RegExp(value.toUpperCase());
    const matchedData = this.state.getAllData.filter(
      (item) =>
        (item.name == null ? "" : item.name.toUpperCase().match(regex)) ||
        item.description.toUpperCase().match(regex)
    );

    const matchedDataPhy = this.state.getAllDataPhysical.filter(
      (item) =>
        (item.name == null ? "" : item.name.toUpperCase().match(regex)) ||
        item.description.toUpperCase().match(regex)
    );

    if (matchedData.length > 0 || matchedDataPhy.length > 0) {
      this.setState({ getListItemAPIData: matchedData });
      this.setState({ getListItemAPIDataPhysical: matchedDataPhy });
    } else {
      this.setState({ getListItemAPIData: [] });
      this.setState({ getListItemAPIDataPhysical: [] });
    }
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen,
    }));
  };

  // Function to close the dropdown after an action is clicked
  closeDropdown = () => {
    this.setState({ isDropdownOpen: false });
  };

  collectionLinkFeat = async (item, typeData) => {
    const formattedCreatorName = item.creatorName.replace(/\s+/g, "-"); // Replaces spaces with hyphens
    const formattedCollectionName = item.collectionName.replace(/\s+/g, "-"); // Replaces spaces with hyphens
    const formattedArtworkName = item.name.replace(/\s+/g, "-"); // Replaces spaces with hyphens

    this.props.history.push(
      `${config.baseUrl}${formattedCreatorName}/${formattedCollectionName}/${formattedArtworkName}`,
      {
        state: { itemId: item.id, type: typeData },
      }
    );
  };

  render() {
    const renderIconButton = (props, ref) => {
      return (
        <IconButton
          {...props}
          ref={ref}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
            >
              <rect
                opacity="0.1"
                width="40"
                height="40"
                rx="20"
                fill="#B7B7B7"
              />
              <path
                opacity="0.5"
                d="M23.0013 27.88C23.0413 28.18 22.9413 28.5 22.7113 28.71C22.3213 29.1 21.6913 29.1 21.3013 28.71L17.2913 24.7C17.0613 24.47 16.9613 24.16 17.0013 23.87V18.75L12.2113 12.62C11.8713 12.19 11.9513 11.56 12.3813 11.22C12.5713 11.08 12.7813 11 13.0013 11H27.0013C27.2213 11 27.4313 11.08 27.6213 11.22C28.0513 11.56 28.1313 12.19 27.7913 12.62L23.0013 18.75V27.88ZM15.0413 13L19.0013 18.06V23.58L21.0013 25.58V18.05L24.9613 13H15.0413Z"
                fill="black"
              />
            </svg>
          }
          circle
          color="blue"
          appearance="primary"
        />
      );
    };

    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        backgroundColor: "rgb(183, 183, 183  ,0.2)",
        fontSize: "16px",
        borderColor: state.isFocused ? "	transparent" : "transparent",
        boxShadow: state.isFocused ? "0 0 5px 	transparent" : "none",

        "&:hover": {
          borderColor: "transparent",
        },
      }),
      option: (provided, state) => ({
        ...provided,
        borderBottom: "1px solid rgb(0,0,0,0.2)",
        padding: "10px",
        fontSize: "18px",
        background: state.isSelected
          ? "var(--Logo-gradient, linear-gradient(90deg, rgba(249, 202, 63, 0.10) 0%, rgba(230, 38, 110, 0.10) 36.5%, rgba(109, 103, 167, 0.10) 70%, rgba(51, 171, 145, 0.10) 100%))"
          : state.isFocused
          ? "var(--Logo-gradient, linear-gradient(90deg, rgba(249, 202, 63, 0.10) 0%, rgba(230, 38, 110, 0.10) 36.5%, rgba(109, 103, 167, 0.10) 70%, rgba(51, 171, 145, 0.10) 100%))"
          : "white",
        color: state.isSelected ? "black" : "black",
        "&:last-child": {
          borderColor: "transparent",
        },
        "&:hover": {
          background:
            "var(--Logo-gradient, linear-gradient(90deg, rgba(249, 202, 63, 0.10) 0%, rgba(230, 38, 110, 0.10) 36.5%, rgba(109, 103, 167, 0.10) 70%, rgba(51, 171, 145, 0.10) 100%))",
        },
      }),
      singleValue: (provided) => ({
        ...provided,
        color: "black",
      }),
      menu: (provided) => ({
        ...provided,
        borderRadius: "4px",
        marginTop: "10px",
        padding: "0",
        border: "none",
        padding: "20px",
      }),
      menuList: (provided) => ({
        ...provided,
        padding: "0",
      }),
    };
    const CustomDropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="8"
            viewBox="0 0 16 8"
            fill="none"
          >
            <path d="M0 0L8 8L16 0L8 3.2L0 0Z" fill="black" />
          </svg>
        </components.DropdownIndicator>
      );
    };
    // const options = [
    //   { value: "Physical Artwork", label: "Physical Artwork" },
    //   { value: "Digital Artwork", label: "Digital Artwork" },
    //   { value: "Jewellery", label: "Jewellery" },
    // ];
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      arrows: false, // Remove arrows for this breakpoint
      dots: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <>
        <div className="body_bg">
          <Helmet>
            <title>{this.state.seoList?.title}</title>
            <meta title={this.state.seoList?.pageTitle} content="IE=edge" />
            <meta
              name="description"
              content={this.state.seoList?.description
                ?.replace(/<[^>]*>?/gm, "")
                .substring(0, 170)}
            />
          </Helmet>
          <ToastContainer />
          <Header1 />

          {/*---------------------- Marketplace New Section Start -------------------- */}
          <section className="nftslider">
            <div className="container px-lg-5">
              <div className="row">
                <div className="col-lg-12">
                  <div className="slider-container">
                    <Slider {...settings}>
                      <div
                        onClick={this.nftType.bind(this, 0)}
                        className={
                          this.state.nftTypeData == 0
                            ? "slidercard active"
                            : "slidercard"
                        }
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          className={"sliderBox"}
                        >
                          <img src={`${config.baseUrl}images/nft/s3.png`} />
                          <button className="text-center tag-center">
                            All
                          </button>
                        </div>
                      </div>
                      <div
                        onClick={this.nftType.bind(this, 1)}
                        className={
                          this.state.nftTypeData == 1
                            ? "slidercard active"
                            : "slidercard"
                        }
                      >
                        <div
                          className={"sliderBox"}
                          style={{ cursor: "pointer" }}
                        >
                          <img src={`${config.baseUrl}images/nft/s1.png`} />
                          <button className="text-center tag-center">
                            Digital
                          </button>
                        </div>
                      </div>
                      <div
                        onClick={this.nftType.bind(this, 2)}
                        className={
                          this.state.nftTypeData == 2
                            ? "slidercard active"
                            : "slidercard"
                        }
                      >
                        <div
                          className={"sliderBox"}
                          style={{ cursor: "pointer" }}
                        >
                          <img src={`${config.baseUrl}images/nft/s2.png`} />
                          <button className="text-center tag-center">
                            Physical
                          </button>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="market_content">
            <div className="container px-lg-5 feature_artworks ">
              <div className="row"></div>
              <div className="row">
                <div className="col-lg-3 col-md-5 col-12 pt-4 filter_desktop">
                  <div className="filterbox">
                    <div className="filterhead mb-3">
                      <h5 className="opacity-50">Filter</h5>
                      <div>
                        <button
                          type="button"
                          onClick={this.refreshWindow.bind(this)}
                          className="btn box-shadown-none border-0 resetBtn m-0"
                        >
                          Reset All
                        </button>
                      </div>
                    </div>
                    <hr className="h-line mb-2 opacity-50" />
                    <div className="totalitem mb-3">
                      <h4>Total items:</h4>
                      <span className="ml-2">
                        {this.state?.getListItemAPIData?.length +
                          this.state?.getListItemAPIDataPhysical?.length}
                      </span>
                    </div>
                    <div className="filter_inner">
                      <div className="innerFilter">
                        <h5 className="mb-0">
                          {/* NFT Type <span><i className="fal fa-chevron-down"></i></span> */}
                          Type{" "}
                        </h5>
                        <div className="card-body pb-0 px-2">
                          <div>
                            <form action="#">
                              <div className="mb-2 d-inline-block mr-3">
                                <input
                                  type="radio"
                                  id="test1"
                                  name="radio-group"
                                  onClick={() => {
                                    this.getListItemAPI(1);
                                    this.getListItemAPIPhysical(1);
                                  }}
                                />
                                <label htmlFor="test1">
                                  <div className="innerlabel">Fixed price</div>
                                </label>
                              </div>
                              <div className="mb-2 d-inline-block mr-3">
                                <input
                                  type="radio"
                                  id="test2"
                                  onClick={() => {
                                    this.getListItemAPI(2);
                                    this.getListItemAPIPhysical(2);
                                  }}
                                  name="radio-group"
                                />
                                <label htmlFor="test2">
                                  <div className=" innerlabel">Auction</div>
                                </label>
                              </div>
                              {/* <div className="mb-2 d-inline-block">
                                <input
                                  type="radio"
                                  id="test3"
                                  onClick={this.getListItemAPI.bind(this, 2)}
                                  name="radio-group"
                                />
                                <label htmlFor="test3">
                                  <div className="innerlabel">New</div>
                                </label>
                              </div> */}
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="innerFilter">
                        <h5 className="mb-0">
                          {/* NFT Type <span><i className="fal fa-chevron-down"></i></span> */}
                          Category{" "}
                        </h5>
                        <div className="card-body pb-0 px-0">
                          <div className=" mb-3">
                            <Select
                              options={this.state.category_list}
                              styles={customStyles}
                              components={{
                                DropdownIndicator: CustomDropdownIndicator,
                              }}
                              onChange={(selectedOption) =>
                                this.handleSelectChange(selectedOption)
                              } // For option selection
                            />
                          </div>
                        </div>
                      </div>
                      <div className="innerFilter">
                        <h5 className="mb-0">
                          {/* NFT Type <span><i className="fal fa-chevron-down"></i></span> */}
                          Price{" "}
                        </h5>
                        <div className="card-body pb-0 px-2">
                          <div>
                            <form onSubmit={this.handleFormSubmit}>
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="form-group mb-1">
                                  <input
                                    className="form-control mr-sm-2 border-0 box-shadow-none my-0"
                                    type="text"
                                    placeholder="Min"
                                    aria-label="Min"
                                    name="minPrice" // Set name for input
                                    value={this.state.minPrice} // Controlled input
                                    onChange={this.handleInputChange} // Handle input change
                                    style={{ borderRadius: "32px" }}
                                  />
                                </div>
                                <div className="mb-1 px-3">
                                  <h6 className="mb-0">to</h6>
                                </div>
                                <div className="form-group mb-1">
                                  <input
                                    className="form-control border-0 box-shadow-none my-0"
                                    type="text"
                                    placeholder="Max"
                                    aria-label="Max"
                                    name="maxPrice" // Set name for input
                                    value={this.state.maxPrice} // Controlled input
                                    onChange={this.handleInputChange} // Handle input change
                                    style={{ borderRadius: "32px" }}
                                  />
                                </div>
                              </div>
                              <button className="btn outline-btn box-shadow-none">
                                Apply
                              </button>

                              <div className="mb-2"></div>
                            </form>
                          </div>
                        </div>
                      </div>
                      {/* <div id="accordion">
                        <div className="card mb-3">
                          <div className="card-header" id="headingOne">
                            <h5 className="mb-0">
                              <button
                                className="btn btn-link px-1"
                                data-toggle="collapse"
                                data-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                               
                                Type{" "}
                                <span>
                                  <img
                                    src="mercafor_dashboard/assets/img/downarrow.svg
                                                                "
                                    style={{ width: "15px", height: "8px" }}
                                    className=""
                                    alt="downarrow"
                                  />
                                </span>
                              </button>
                            </h5>
                          </div>

                          <div
                            id="collapseOne"
                            className="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#accordion"
                          >
                            <div className="card-body pb-0 px-2">
                              <div>
                                <form action="#">
                                  <div className="mb-2">
                                    <input
                                      type="radio"
                                      id="test1"
                                      name="radio-group"
                                      onClick={this.getListItemAPI.bind(
                                        this,
                                        1
                                      )}
                                    />
                                    <label htmlFor="test1">Fixed price</label>
                                  </div>
                                  <div className="mb-2">
                                    <input
                                      type="radio"
                                      id="test2"
                                      onClick={this.getListItemAPI.bind(
                                        this,
                                        2
                                      )}
                                      name="radio-group"
                                    />
                                    <label htmlFor="test2">Auction</label>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-md-7 col-12 pt-4">
                  <div className="row mb-4 justify-content-end text-end filter_mobile">
                    <div className="col-lg-9 col-md-9 col-sm-9 col-12">
                      <div className="d-flex">
                        <Dropdown
                          renderToggle={renderIconButton}
                          open={this.state.isDropdownOpen}
                          onToggle={this.toggleDropdown}
                          className="mr-2 d-md-none"
                        >
                          <div className="filterbox">
                            <div className="filterhead mb-3">
                              <h5 className="opacity-50">Filter</h5>
                              <div>
                                <button
                                  type="button"
                                  onClick={this.refreshWindow.bind(this)}
                                  className="btn box-shadown-none border-0 resetBtn m-0"
                                >
                                  Reset All
                                </button>
                              </div>
                            </div>
                            <hr className="h-line mb-2 opacity-50" />
                            <div className="totalitem mb-3">
                              <h4>Total items:</h4>
                              <span className="ml-2">
                                {this.state?.getListItemAPIData?.length +
                                  this.state?.getListItemAPIDataPhysical
                                    ?.length}
                              </span>
                            </div>
                            <div className="filter_inner">
                              <div className="innerFilter">
                                <h5 className="mb-0">Type </h5>
                                <div className="card-body pb-0 px-2">
                                  <div>
                                    <form action="#">
                                      <div className="mb-2 d-inline-block mr-3">
                                        <input
                                          type="radio"
                                          id="test1"
                                          name="radio-group"
                                          onClick={() => {
                                            this.getListItemAPI(1);
                                            this.getListItemAPIPhysical(1);
                                          }}
                                        />
                                        <label htmlFor="test1">
                                          <div className="innerlabel">
                                            Fixed price
                                          </div>
                                        </label>
                                      </div>
                                      <div className="mb-2 d-inline-block mr-3">
                                        <input
                                          type="radio"
                                          id="test2"
                                          onClick={() => {
                                            this.getListItemAPI(2);
                                            this.getListItemAPIPhysical(2);
                                          }}
                                          name="radio-group"
                                        />
                                        <label htmlFor="test2">
                                          <div className=" innerlabel">
                                            Auction
                                          </div>
                                        </label>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                              <div className="innerFilter">
                                <h5 className="mb-0">Category </h5>
                                <div className="card-body pb-0 px-0">
                                  <div className=" mb-3">
                                    <Select
                                      options={this.state.category_list}
                                      styles={customStyles}
                                      components={{
                                        DropdownIndicator:
                                          CustomDropdownIndicator,
                                      }}
                                      onChange={(selectedOption) =>
                                        this.handleSelectChange(selectedOption)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="innerFilter">
                                <h5 className="mb-0">Price </h5>
                                <div className="card-body pb-0 px-2">
                                  <div>
                                    <form onSubmit={this.handleFormSubmit}>
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div className="form-group mb-1">
                                          <input
                                            className="form-control mr-sm-2 border-0 box-shadow-none my-0"
                                            type="text"
                                            placeholder="Min"
                                            aria-label="Min"
                                            name="minPrice"
                                            value={this.state.minPrice}
                                            onChange={this.handleInputChange}
                                            style={{ borderRadius: "32px" }}
                                          />
                                        </div>
                                        <div className="mb-1 px-3">
                                          <h6 className="mb-0">to</h6>
                                        </div>
                                        <div className="form-group mb-1">
                                          <input
                                            className="form-control border-0 box-shadow-none my-0"
                                            type="text"
                                            placeholder="Max"
                                            aria-label="Max"
                                            name="maxPrice"
                                            value={this.state.maxPrice}
                                            onChange={this.handleInputChange}
                                            style={{ borderRadius: "32px" }}
                                          />
                                        </div>
                                      </div>
                                      <button className="btn outline-btn box-shadow-none">
                                        Apply
                                      </button>

                                      <div className="mb-2"></div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Dropdown>

                        <div className="searchbox">
                          <span>
                            <img
                              src="mercafor_dashboard/assets/img/search.svg"
                              alt="search"
                            />
                          </span>
                          <input
                            className="form-control mr-sm-2"
                            type="search"
                            onChange={(e) => this.searchAnything(e)}
                            value={this.state.searchAnything}
                            placeholder="Search"
                            aria-label="Search"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                      <div className="sizeby mt-0">
                        <div className="dropdown">
                          <select
                            onChange={(e) => this.PriceHeaderFilter(e)}
                            className="form-control box-shadow-none"
                          >
                            <option value="newesttooldest">All</option>

                            <option value="Lowtohigh">
                              Price: Low to High
                            </option>
                            <option value="Hightolow">
                              Price: High to Low
                            </option>
                            <option value="newesttooldest">
                              Recently Listed
                            </option>
                            <option value="mostLiked">Most Liked</option>
                          </select>

                          {/* <button
                                className="btn btn-lowest-secondary dropdown-toggle m-0"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                All{" "}
                                <span>
                                  <img
                                    src="mercafor_dashboard/assets/img/downarrow.svg"
                                    style={{ width: "12px", height: "6px" }}
                                    className=""
                                    alt="downarrow"
                                  />
                                </span>
                              </button>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <a className="dropdown-item border-0" href="">
                                  Price: Low to High
                                </a>
                                <a className="dropdown-item" href="">
                                  Price: High to Low
                                </a>
                                <a className="dropdown-item" href="">
                                  Recently Listed
                                </a>
                                <a className="dropdown-item" href="">
                                  Most Liked
                                </a>
                              </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {this.state.loaderSpinner ? (
                      <div
                        className="text-center"
                        style={{ marginLeft: "30%" }}
                      >
                        <ColorRing
                          visible={true}
                          height="180"
                          width="180"
                          ariaLabel="color-ring-loading"
                          wrapperStyle={{}}
                          wrapperClass="color-ring-wrapper"
                          colors={[
                            "#e15b64",
                            "#f47e60",
                            "#f8b26a",
                            "#abbd81",
                            "#849b87",
                          ]}
                        />
                      </div>
                    ) : this.state.dataFetched &&
                      this.state?.getListItemAPIData?.length === 0 &&
                      this.state?.getListItemAPIDataPhysical?.length === 0 ? (
                      <div className="col-lg-12 col-md-12 col-12 mb-4">
                        <Fade left>
                          <div className="">
                            <div className="text-center">
                              <div
                                className="d-flex justify-content-center"
                                style={{ marginBottom: "-20px" }}
                              >
                                <dotlottie-player
                                  src="https://lottie.host/2779598a-ca3c-4fc6-aeb8-25216948d698/L7Y2Nq9xlE.json"
                                  background="transparent"
                                  speed={1}
                                  style={{ width: "200px", height: "200px" }}
                                  loop
                                  autoPlay
                                />
                              </div>
                              <h2>Sorry, no results found!</h2>
                              <h5>No NFT's Found For Purchase</h5>
                            </div>
                          </div>
                        </Fade>
                      </div>
                    ) : (
                      <>
                        {(this.state.nftTypeData === 1 ||
                          this.state.nftTypeData === 0) &&
                          this.state?.getListItemAPIData?.map((item) => (
                            <div
                              className="col-xl-4 col-lg-6 col-md-12 col-12 mb-4 column-custom"
                              key={item.id}
                            >
                              <div className="card feature border p-3">
                                <div className="item position-relative">
                                  <div className="position-relative">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={this.collectionLinkFeat.bind(
                                        this,
                                        item,
                                        1
                                      )}
                                    >
                                      {item?.file_type === "image" ? (
                                        <img
                                          className="card-img-top img-fluid"
                                          src={config.imageUrl1 + item.image}
                                          alt="NFT"
                                          style={{
                                            height: "250px",
                                            width: "100%",
                                          }}
                                        />
                                      ) : item.file_type === "video" ? (
                                        <Player
                                          className="marketplace-image"
                                          style={{
                                            height: "250px",
                                            width: "100%",
                                          }}
                                          src={`${config.imageUrl1}${item?.image}`}
                                        />
                                      ) : null}
                                    </a>
                                    <div className="tag">
                                      <p className="mb-0">Digital Artwork</p>
                                    </div>
                                  </div>
                                  <div className="card-body pb-0">
                                    <h5 className="card-title">
                                      <span title={item?.name}>
                                        {item?.name}
                                      </span>
                                    </h5>
                                    <small className="mb-0 x-small">
                                      {item.description}
                                    </small>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div>
                                        <h2 className="mb-0">
                                          {item?.sell_type === 1
                                            ? item.price
                                            : item.lastBid === 0
                                            ? item.price
                                            : (
                                                item.lastBid +
                                                (item.lastBid *
                                                  item.bid_increasing_amount) /
                                                  100
                                              ).toFixed(4)}{" "}
                                          <span className="h5 fw-400">
                                            {item.artistType === 0
                                              ? config.currencyType
                                              : config.currencyTypeSep}
                                          </span>
                                        </h2>
                                      </div>
                                      <div>
                                        <button
                                          className="btn btn-circle"
                                          onClick={this.collectionLinkFeat.bind(
                                            this,
                                            item,
                                            1
                                          )}
                                        >
                                          Buy
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                        {(this.state.nftTypeData === 2 ||
                          this.state.nftTypeData === 0) &&
                          this.state?.getListItemAPIDataPhysical?.map(
                            (item) => (
                              <div
                                className="col-xl-4 col-lg-6 col-md-12 col-12 mb-4 column-custom"
                                key={item.id}
                              >
                                <div className="card feature border p-3">
                                  <div className="item position-relative">
                                    <div className="position-relative">
                                      <a
                                        href="javascript:void(0)"
                                        onClick={this.collectionLinkFeat.bind(
                                          this,
                                          item,
                                          2
                                        )}
                                      >
                                        {item?.file_type === "image" ? (
                                          <img
                                            className="card-img-top img-fluid"
                                            src={config.imageUrl1 + item.image}
                                            alt="Physical Artwork"
                                            style={{
                                              height: "250px",
                                              width: "100%",
                                            }}
                                          />
                                        ) : item.file_type === "video" ? (
                                          <Player
                                            className="marketplace-image"
                                            style={{
                                              height: "250px",
                                              width: "100%",
                                            }}
                                            src={`${config.imageUrl1}${item?.image}`}
                                          />
                                        ) : null}
                                      </a>
                                      <div className="tag">
                                        <p className="mb-0">Physical Artwork</p>
                                      </div>
                                    </div>
                                    <div className="card-body pb-0">
                                      <h5 className="card-title">
                                        <span title={item?.name}>
                                          {item?.name}
                                        </span>
                                      </h5>
                                      <small className="mb-0 x-small">
                                        {item.description}
                                      </small>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                          <h2 className="mb-0">
                                            {item?.sell_type === 1
                                              ? item.price
                                              : item.lastBid === 0
                                              ? item.price
                                              : (
                                                  item.lastBid +
                                                  (item.lastBid *
                                                    item.bid_increasing_amount) /
                                                    100
                                                ).toFixed(4)}{" "}
                                            <span className="h5 fw-400">
                                              {item.artistType === 0
                                                ? config.currencyType
                                                : config.currencyTypeSep}
                                            </span>
                                          </h2>
                                        </div>
                                        <div>
                                          <button
                                            className="btn btn-circle"
                                            onClick={this.collectionLinkFeat.bind(
                                              this,
                                              item,
                                              2
                                            )}
                                          >
                                            Buy
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/*---------------------- Marketplace New  Section End -------------------- */}

          <Footer1 />
        </div>
      </>
    );
  }
}
