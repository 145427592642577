import React, { Component } from "react";
import Footer1 from "../directives/footer1";
import Header1 from "../directives/header1";
import axios from "axios";
// import config from '../config/config'
import config from "../config/config";
import Cookies from "js-cookie";
import "react-alice-carousel/lib/alice-carousel.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Bounce } from "react-reveal";
import Swal from "sweetalert2";
import Popup from "../popup";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

export default class setting extends Component {
  constructor(props) {
    super(props);
    this.loginData = !Cookies.get("artfungibleUserloginSuccess")
      ? []
      : JSON.parse(Cookies.get("artfungibleUserloginSuccess"));

    this.state = {
      getProfilePicAPIData: "",
      user_name: "",
      email: "",
      description: "",
      image: "",
      backGroundImage: "",
      image_file_front: null,
      image_preview_front: "",
      image_file_back: null,
      image_preview_back: "",
      validationError: false,
      isPopupError: false,
      isPopupSuccess: false,
      msg: false,
      seoList: "",
      moreDetailsHideShow: false,
      countrylistData: [],
      shippingAddressData: "",
      value: "", // Initial state for the phone input value
      // const [value, setValue] = useState()
    };
    this.onChange = this.onChange.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.updateProfileImage = this.updateProfileImage.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    this.getProfilePicAPI();
    var parts = window.location.href.split("/");
    var lastSegment = parts.pop() || parts.pop(); // handle potential trailing slash

    this.getSeoAPI(lastSegment);
    this.countryList();
    this.getshippingAddressAPI();
  }

  async getshippingAddressAPI() {
    try {
      await axios({
        method: "post",
        url: `${config.apiUrl}userShippingAddress`,
        data: { userId: this.loginData.data.id },
        headers: { Authorization: this.loginData.Token },
      }).then((response) => {
        if (response.data.success === true) {
          this.setState({
            physicalNftViewData: response.data.shippingAddress,
            value: response.data.shippingAddress.phoneNumber,
          });
        } else {
          this.setState({
            physicalNftViewData: "",
          });
        }
      });
    } catch (err) {
      this.setState({
        physicalNftViewData: "",
      });
    }
  }

  handleChange = (event) => {
    event.preventDefault();
    let value = event.target.value;

    this.setState((prevState) => ({
      physicalNftViewData: {
        ...prevState.physicalNftViewData,
        [event.target.name]: value,
      },
    }));
  };

  async submitForm(e) {
    e.preventDefault();
    let submitData = {
      userId: this.loginData.data.id,
      country_id: this.state.physicalNftViewData.country_id,
      fullName: this.state.physicalNftViewData.fullName,
      phoneNumber: this.state.physicalNftViewData.phoneNumber,
      address: this.state.physicalNftViewData.address,
      apartment: this.state.physicalNftViewData.apartment,
      postCode: this.state.physicalNftViewData.postCode,
      city: this.state.physicalNftViewData.city,
      email:this.state.physicalNftViewData.email
    };
    await axios({
      method: "post",
      url: `${config.apiUrl}updateuserShippingAddress`,
      data: submitData,
      headers: { Authorization: this.loginData.Token },
    })
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            isPopupSuccess: true,
            msg: response.data.msg,
          });
          setTimeout(() => {
            this.setState({
              isPopupSuccess: false,
            });
          }, 2000);
        } else if (response.data.success === false) {
          this.setState({
            isPopupError: true,
            msg: response.data?.msg,
          });
          setTimeout(() => {
            this.setState({
              isPopupError: false,
            });
          }, 2000);
        }
      })
      .catch((err) => {});
  }

  async getSeoAPI(lastSegment) {
    await axios({
      method: "post",
      url: `${config.apiUrl}/getSEO`,
      data: { pageName: lastSegment },
    })
      .then(async (response) => {
        if (response.data.success === true) {
          this.setState({
            seoList: response.data.data,
          });
        } else if (response.data.success === false) {
        }
      })
      .catch((err) => {});
  }

  async countryList() {
    await axios({
      method: "get",
      url: `${config.apiUrl}getCountries`,
      headers: { Authorization: this.loginData.message },
    }).then((response) => {
      if (response.data.success === true) {
        this.setState({
          countrylistData: response.data?.response,
        });
      }
    });
  }

  onChange = (e) => {
    this.setState((prevState) => ({
      getProfilePicAPIData: {
        ...prevState.getProfilePicAPIData,
        [e.target.name]: e.target.value,
      },
    }));
  };

  async getProfilePicAPI() {
    await axios({
      method: "post",
      url: `${config.apiUrl}getuserprofile`,
      headers: { Authorization: this.loginData.Token },
      data: { walletAddress: this.loginData.data?.walletAddress },
    }).then((response) => {
      if (response.data.success === true) {
        this.setState({
          getProfilePicAPIData: response.data?.response,
        });
      }
    });
  }

  async updateProfile() {
    if (
      this.state.getProfilePicAPIData.email === "" ||
      this.state.getProfilePicAPIData.user_name === ""
      // ||
      // this.state.getProfilePicAPIData.description === "" ||
      // this.state.getProfilePicAPIData.description === null
    ) {
      this.setState({
        validationError: true,
      });
      return true;
    }
    await axios({
      method: "post",
      url: `${config.apiUrl}updateUserprofilepic`,
      headers: { Authorization: this.loginData.Token },
      data: {
        walletAddress: this.loginData.data?.walletAddress,
        email: this.state.getProfilePicAPIData.email,
        user_name: this.state.getProfilePicAPIData.user_name,
        description: this.state.getProfilePicAPIData.description,
        addressHome: this.state.getProfilePicAPIData.addressHome,
        phoneNumber: this.state.getProfilePicAPIData.phoneNumber,
      },
    }).then((response) => {
      if (response.data.success === true) {
        this.setState({
          isPopupSuccess: true,
          msg: response.data.msg,
        });
        setTimeout(() => {
          this.setState({
            isPopupSuccess: false,
          });
        }, 2000);

        this.getProfilePicAPI();
        this.setState({
          validationError: false,
        });
      }
    });
  }

  handleImagePreview = (e) => {
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];
    let file_type = "";
    // if (image_as_files.type.indexOf("backgroundcollectionImage") === 0) {
    //   file_type = "backgroundcollectionImage";
    // } else {
    //   file_type = "video";
    // }
    this.setState({
      image_preview_front: image_as_base64,
      image_file_front: image_as_files,
    });
  };

  handleImagePreview2 = (e) => {
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];
    let file_type = "";
    // if (image_as_files.type.indexOf("backgroundcollectionImage") === 0) {
    //   file_type = "backgroundcollectionImage";
    // } else {
    //   file_type = "video";
    // }
    this.setState({
      image_preview_back: image_as_base64,
      image_file_back: image_as_files,
    });
  };

  async updateProfileImage() {
    if (!this.state.image_file_front) {
      this.setState({
        isPopupError: true,
        msg: "Please select User Image",
      });
      setTimeout(() => {
        this.setState({
          isPopupError: false,
        });
      }, 2000);
      return;
    }
    if (!this.state.image_file_back) {
      this.setState({
        isPopupError: true,
        msg: "Please select Background Image",
      });
      setTimeout(() => {
        this.setState({
          isPopupError: false,
        });
      }, 2000);

      return;
    }
    let formData = new FormData();
    formData.append("id", this.loginData.data.id);
    formData.append("image", this.state.image_file_front);
    formData.append("backGroundImage", this.state.image_file_back);
    formData.append("oldImage", this.state.getProfilePicAPIData.image);
    formData.append(
      "oldUserbackGroundImage",
      this.state.getProfilePicAPIData.backGroundImage
    );

    await axios({
      method: "post",
      url: `${config.apiUrl}/userImageUpdate`,
      headers: { Authorization: this.loginData.Token },
      data: formData,
    })
      .then(async (result) => {
        if (result.data.success === true) {
          await Swal.fire({
            text: "Congratulations, Images Added successfully.",
            icon: "success",
            width: 500,
            confirmButtonColor: "#3085d6",
            allowOutsideClick: false,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          await Swal.fire({
            text: result.data.msg,
            icon: "error",
            width: 500,
            confirmButtonColor: "#3085d6",
            allowOutsideClick: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          isPopupError: true,
          msg: err.response.data?.msg,
        });
        setTimeout(() => {
          this.setState({
            isPopupError: false,
          });
        }, 2000);
      });
  }

  async moreDetails(id) {
    if (id === 1) {
      this.setState({
        moreDetailsHideShow: true,
      });
    } else {
      this.setState({
        moreDetailsHideShow: false,
      });
    }
  }
  handleChange1 = (phoneNumber) => {
    this.setState((prevState) => ({
      physicalNftViewData: {
        ...prevState.physicalNftViewData,
        phoneNumber: phoneNumber,
      },
    }));
  };

  render() {
    return (
      <>
        <div className="body_bg">
          <Header1 />
          <Helmet>
            <title>{this.state.seoList?.title}</title>
            <meta title={this.state.seoList?.pageTitle} content="IE=edge" />
            <meta
              name="description"
              content={this.state.seoList?.description
                ?.replace(/<[^>]*>?/gm, "")
                .substring(0, 170)}
            />
          </Helmet>
          <Popup
            isPopupError={this.state.isPopupError}
            isPopupSuccess={this.state.isPopupSuccess}
            msg={this.state.msg}
          />{" "}
          {/*---------------------- Setting Section Start -------------------- */}
          <section className="setting pt-lg-5 pt-3">
            <div className="container-fluid px-lg-5">
              <div className="row">
                <div className="col-12 text-center mb-4">
                  <h2 className="tophead text-left">Setting</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12 pr-lg-5">
                  <Bounce left>
                    <div className="settingleft">
                      <form>
                        <div className="form-group mb-4">
                          <label>User Name</label>
                          <input
                            type="text"
                            className="form-control"
                            aria-describedby="emailHelp"
                            placeholder="User Name"
                            value={this.state.getProfilePicAPIData.user_name}
                            onChange={this.onChange}
                            name="user_name"
                          />
                          {this.state.getProfilePicAPIData.user_name === "" &&
                          this.state.validationError === true ? (
                            <p className="error" style={{ margin: "10px" }}>
                              User Name is Required
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </form>
                    </div>
                  </Bounce>
                </div>
                <div className="col-lg-6 col-md-6 col-12 pl-lg-5">
                  <Bounce right>
                    <div className="settingleft">
                      <form>
                        <div className="form-group mb-4">
                          <label htmlFor="exampleInputEmail1">Email</label>
                          <input
                            type="email"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="useremail@artfunigle.com"
                            value={this.state.getProfilePicAPIData.email}
                            name="email"
                            onChange={this.onChange}
                          />
                          {/* <small
                            id="emailHelp"
                            className="form-text text-muted"
                            style={{
                              fontFamily: "Catamaran",
                              fontWeight: "400",
                              fontSize: "14px",
                            }}
                          >
                            Desc for setting, Desc for setting, Desc for
                            Setting, Desc for setting, Desc for setting, Desc
                            for Setting, Desc for setting, Desc for setting,
                            Desc for Setting,
                          </small> */}
                          {this.state.getProfilePicAPIData.email === "" &&
                          this.state.validationError === true ? (
                            <p className="error" style={{ margin: "10px" }}>
                              Email is Required
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </form>
                    </div>
                  </Bounce>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-6 col-12 pr-lg-5">
                  <Bounce left>
                    <div className="settingleft">
                      <form>
                        <div className="form-group mb-4">
                          <label>User Description</label>
                          <input
                            type="text"
                            className="form-control"
                            aria-describedby="emailHelp"
                            placeholder="User Description"
                            value={this.state.getProfilePicAPIData.description}
                            onChange={this.onChange}
                            name="description"
                          />
                          {/* {(this.state.getProfilePicAPIData.description ===
                            "" ||
                            this.state.getProfilePicAPIData.description ===
                              null) &&
                          this.state.validationError === true ? (
                            <p className="error" style={{ margin: "10px" }}>
                              Description is Required
                            </p>
                          ) : (
                            ""
                          )} */}
                        </div>
                      </form>
                    </div>
                  </Bounce>
                </div>
                {this.state.moreDetailsHideShow === true ? (
                  <div className="col-lg-6 col-md-6 col-12 pl-lg-5">
                    <Bounce right>
                      <div className="settingleft">
                        <form>
                          <div className="form-group mb-4">
                            <label htmlFor="exampleInputEmail1">
                              User Address
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder="User Address"
                              value={
                                this.state.getProfilePicAPIData.addressHome
                              }
                              name="addressHome"
                              onChange={this.onChange}
                            />
                          </div>
                        </form>
                      </div>
                    </Bounce>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {this.state.moreDetailsHideShow === true ? (
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12 pr-lg-5">
                    <Bounce left>
                      <div className="settingleft">
                        <form>
                          <div className="form-group mb-4">
                            <label>User Phone</label>
                            <input
                              type="number"
                              className="form-control"
                              aria-describedby="emailHelp"
                              placeholder="User Phone"
                              value={
                                this.state.getProfilePicAPIData.phoneNumber
                              }
                              onChange={this.onChange}
                              name="phoneNumber"
                            />
                          </div>
                        </form>
                      </div>
                    </Bounce>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* <small
                id="emailHelp"
                className="form-text text-muted"
                style={{
                  fontFamily: "Catamaran",
                  fontWeight: "400",
                  fontSize: "14px",
                  marginLeft: "20px",
                }}
              >
                {this.state.moreDetailsHideShow === false ? (
                  <a
                    onClick={this.moreDetails.bind(this, 1)}
                    style={{ textDecoration: "underline", color: "blue" }}
                  >
                    More Details
                  </a>
                ) : (
                  <a
                    onClick={this.moreDetails.bind(this, 2)}
                    style={{ textDecoration: "underline", color: "blue" }}
                  >
                    Hide Details
                  </a>
                )}
              </small> */}
              <div className="row">
                <div className="col-lg-2 col-md-4 col-12 mb-4 mx-auto">
                  <button
                    type="submit"
                    onClick={this.updateProfile}
                    className="btn btn-buy-primary w-100 mt-2"
                  >
                    Save
                  </button>
                </div>
              </div>

              {/* //========================== Shipping Address Details ============================= */}

              {/* <div className="container-fluid px-lg-5"> */}
              <div className="row">
                <div className="col-12 text-center mb-4">
                  <h2 className="tophead text-left">Shipping Address</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12 pr-lg-5">
                  <Bounce left>
                    <div className="settingleft">
                      <form>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group mb-4">
                              <label
                                htmlFor="exampleFormControlSelect1"
                                className="mb-2"
                              >
                                Country/Region <span className="error">*</span>
                              </label>

                              <select
                                name="country_id"
                                onChange={this.handleChange}
                                value={
                                  this.state.physicalNftViewData?.country_id
                                }
                                class="form-control input-lg"
                                // style={{
                                //   display: "block!important",
                                //   marginTop: "10px",
                                // }}
                              >
                                <option selected="selected" value="">
                                  Select Country
                                </option>
                                {this.state.countrylistData.map((item) => (
                                  <option value={item.id}>{item.name}</option>
                                ))}
                              </select>
                            </div>

                            <div className="form-group mb-4">
                              <label>
                                Phone Number <span className="error">*</span>
                              </label>
                              <PhoneInput
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="CH"
                                value={
                                  this.state.physicalNftViewData?.phoneNumber
                                }
                                onChange={this.handleChange1}
                              />
                              <span className="valida-text">
                                May be used to assist delivery
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label>
                                Address <span className="error">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Street"
                                name="address"
                                onChange={this.handleChange}
                                value={this.state.physicalNftViewData?.address}
                              />
                            </div>

                            <div className="form-group">
                              <input
                                type="text"
                                name="apartment"
                                onChange={this.handleChange}
                                value={
                                  this.state.physicalNftViewData?.apartment
                                }
                                className="form-control"
                                placeholder="Apt, suite, unit, company name(optional)"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row"></div>
                      </form>
                    </div>
                  </Bounce>
                </div>
                <div className="col-lg-6 col-md-6 col-12 pl-lg-5">
                  <Bounce right>
                    <div className="settingleft">
                      <div className="form-group mb-4">
                        <label className="mb-2">
                          Full name (first name and surname){" "}
                          <span className="error">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control m-0"
                          placeholder=""
                          name="fullName"
                          onChange={this.handleChange}
                          value={this.state.physicalNftViewData?.fullName}
                        />
                      </div>

                      <div className="form-group mb-4">
                        <label className="mb-2">
                         Email{" "}
                          <span className="error">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control m-0"
                          placeholder=""
                          name="email"
                          onChange={this.handleChange}
                          value={this.state.physicalNftViewData?.email}
                        />
                      </div>

                      <div className="">
                        <div className="form-group">
                          <label>
                            City <span className="error">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control m-0"
                            placeholder=""
                            name="city"
                            onChange={this.handleChange}
                            value={this.state.physicalNftViewData?.city}
                          />
                        </div>
                      </div>

                      <div className="">
                        <div className="form-group">
                          <label>
                            Postcode
                            <span className="error">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control m-0"
                            placeholder=""
                            name="postCode"
                            onChange={this.handleChange}
                            value={this.state.physicalNftViewData?.postCode}
                          />
                        </div>
                      </div>
                    </div>
                  </Bounce>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-2 col-md-4 col-12 mb-4 mx-auto">
                  <button
                    type="submit"
                    disabled={
                      !this.state.physicalNftViewData?.country_id ||
                      !this.state.physicalNftViewData?.email ||
                      !this.state.physicalNftViewData?.fullName ||
                      !this.state.physicalNftViewData?.phoneNumber ||
                      !this.state.physicalNftViewData?.address ||
                      !this.state.physicalNftViewData?.postCode ||
                      !this.state.physicalNftViewData?.city
                    }
                    onClick={this.submitForm}
                    className="btn btn-buy-primary w-100 mt-2"
                  >
                    Save
                  </button>
                </div>
              </div>
              {/* </div> */}

              {/* <div className="container-fluid px-lg-5"> */}
              <div className="row">
                <div className="col-12 text-center mb-4">
                  <h2 className="tophead text-left">Image Setting</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12 pr-lg-5">
                  <Bounce left>
                    <div className="settingleft">
                      <form>
                        <div className="form-group mb-4">
                          <label>User Image</label>
                          <input
                            type="file"
                            className="form-control"
                            aria-describedby="emailHelp"
                            accept=".jpg,.png,.jpeg,.webp,.gif"
                            onChange={this.handleImagePreview}
                          />
                          {/* {this.state.image_preview_front ? ( */}
                          {this.state.getProfilePicAPIData.image ? (
                            <img
                              src={
                                config.imageUrl1 +
                                this.state.getProfilePicAPIData.image
                              }
                              alt="profileimg"
                              style={{
                                height: "191px",
                                width: "191px",
                                borderRadius: "100px",
                              }}
                              className="profileimg"
                            />
                          ) : this.state.image_preview_front ? (
                            <img
                              src={this.state.image_preview_front}
                              alt="profileimg"
                              style={{
                                height: "191px",
                                width: "191px",
                                borderRadius: "100px",
                              }}
                              className="profileimg"
                            />
                          ) : (
                            ""
                          )}

                          {/* ) : (
                            ""
                          )} */}
                        </div>
                      </form>
                    </div>
                  </Bounce>
                </div>
                <div className="col-lg-6 col-md-6 col-12 pl-lg-5">
                  <Bounce right>
                    <div className="settingleft">
                      <form>
                        <div className="form-group mb-4">
                          <label htmlFor="exampleInputEmail1">
                            User Background Image
                          </label>
                          <input
                            type="file"
                            accept=".jpg,.png,.jpeg,.webp,.gif"
                            className="form-control"
                            aria-describedby="emailHelp"
                            onChange={this.handleImagePreview2}
                          />
                          {/* {this.state.image_preview_back ? ( */}
                          {this.state.getProfilePicAPIData.backGroundImage ? (
                            <img
                              src={
                                config.imageUrl1 +
                                this.state.getProfilePicAPIData.backGroundImage
                              }
                              alt="profileimg"
                              style={{
                                height: "191px",
                                width: "191px",
                                borderRadius: "100px",
                              }}
                              className="profileimg"
                            />
                          ) : this.state.image_preview_back ? (
                            <img
                              src={this.state.image_preview_back}
                              alt="profileimg"
                              style={{
                                height: "191px",
                                width: "191px",
                                borderRadius: "100px",
                              }}
                              className="profileimg"
                            />
                          ) : (
                            ""
                          )}
                          {/* ) : (
                            ""
                          )} */}
                          {/* <small
                            id="emailHelp"
                            className="form-text text-muted"
                            style={{
                              fontFamily: "Gilroy",
                              fontWeight: "400",
                              fontSize: "14px",
                            }}
                          >
                            Desc for setting, Desc for setting, Desc for
                            Setting, Desc for setting, Desc for setting, Desc
                            for Setting, Desc for setting, Desc for setting,
                            Desc for Setting,
                          </small> */}
                        </div>
                      </form>
                    </div>
                  </Bounce>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-2 col-md-4 col-12 mb-4 mx-auto">
                  <button
                    type="submit"
                    onClick={this.updateProfileImage}
                    className="btn btn-buy-primary w-100 mt-2"
                  >
                    Save
                  </button>
                </div>
              </div>
              {/* </div> */}
            </div>
          </section>
          {/*---------------------- Shipping Address Section End -------------------- */}
          <Footer1 />
        </div>
      </>
    );
  }
}
