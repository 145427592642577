import React, { Component } from "react";
import Footer1 from "../directives/footer1";
import Header1 from "../directives/header1";
import axios from "axios";
import config from "../config/config";
import moment from "moment";
import { Link } from "react-router-dom";
import "./css/blog.css";

export default class blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setBlogData: [],
      setfrontNews: 0,
    };
  }

  async getBlogs() {
    await axios
      .get(`${config.apiUrl}/getBlogList`, {})
      .then((result) => {
        if (result.data.success === true) {
          var ss = result.data.response.findIndex((ii) => ii.is_active == 1);

          this.setState({
            setBlogData: result.data.response,
            setfrontNews: ss,
          });
        } else if (result.data.success === false) {
        }
      })

      .catch((err) => {});
  }

  componentDidMount() {
    this.getBlogs();
  }
  render() {
    return (
      <>
        <Header1 />

        <div id="">

          <div className="container px-lg-5">
            <div className="row  mt-2 mb-lg-3">
              <div className="col-md-12 mb-3">
                <div id="container-mix" className="row _post-container_ ">
                  <div className=" col-sm-12 ">
                    {this.state.setBlogData.length == 0 ? 
                    <div className="blog-card position-relative">
                      <div
                        className="d-flex justify-content-center"
                        style={{ marginBottom: "-20px" }}
                      >
                        <dotlottie-player
                          src="https://lottie.host/2779598a-ca3c-4fc6-aeb8-25216948d698/L7Y2Nq9xlE.json"
                          background="transparent"
                          speed={1}
                          style={{ width: "200px", height: "200px" }}
                          loop
                          autoPlay
                        />
                      </div>
                      <h2 className="text-center">Sorry, no blogs found!</h2>
</div>:
<div className="blog-card position-relative">
                      <div className="blogImage">
                        <Link
                          to={
                            this.state.setBlogData.length > 0
                              ? `${config.baseUrl}blogDetail/${
                                  this.state.setBlogData[
                                    this.state.setfrontNews
                                  ].id
                                }`
                              : ""
                          }
                        >
                          <img
                            src={
                              this.state.setBlogData.length > 0
                                ? config.imageUrl1 +
                                  this.state.setBlogData[
                                    this.state.setfrontNews
                                  ].image
                                : ""
                            }
                            width="100%"
                            height={`450px`}
                            className="object-fit-cover"
                          />
                        </Link>
                        <div
                          class="tag mt-2 px-2"
                          style={{ top: "0", bottom: "inherit" }}
                        >
                          <p class="mb-0">
                            {this.state.setBlogData.length > 0
                              ? this.state.setBlogData[this.state.setfrontNews]
                                  .title
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="blog-content p-4">
                        <small className="drop-subheading px-2 rounded text-black">
                          {moment(
                            this.state.setBlogData.length > 0
                              ? this.state.setBlogData[this.state.setfrontNews]
                                  .date
                              : new Date()
                          ).format("LL")}
                        </small>
                        <br />
                        <h2 className="mb-1 nft_world mt-3">
                          {this.state.setBlogData.length > 0
                            ? this.state.setBlogData[this.state.setfrontNews]
                                .heading
                            : ""}
                        </h2>
                        <p>
                        {this?.state?.setBlogData[this.state.setfrontNews]?.shortDescription?.length > 400
    ? `${this?.state?.setBlogData[this.state.setfrontNews]?.shortDescription?.slice(0, 400).split(' ').slice(0, -1).join(' ')}...`
    : this?.state?.setBlogData[this.state.setfrontNews]?.shortDescription}
                                         &nbsp;&nbsp;&nbsp;
                                         <button
                                          className="btn btn-default p-0 box-shadow-none readmore"
                                          style={{color:"#101111"}}
                                          onClick={() => {
                                            window.location.href=this.state.setBlogData.length > 0
                                            ? `${config.baseUrl}blogDetail/${
                                                this.state.setBlogData[
                                                  this.state.setfrontNews
                                                ].id
                                              }`
                                            : ""
                                          }}
                                        >
                                          {this.state.setBlogData.isExpanded
                                            ? "Read Less"
                                            : "Read More"}
                                        </button>
                        </p>
                      </div>
                    </div>
                  }
                    
                  </div>
                </div>
              </div>
            </div>
            {this.state.setBlogData.length == 0 ?  
              '':

            <div className="row">
              <div class="col-12 mb-4">
                <h2 class="tophead mb-0">Latest Blogs</h2>
                <div className="small-line"></div>
              </div>

              <div className="col-sm-12 ">
                <div id="container-mix" className="row _post-container_ fail">
                  {this.state.setBlogData.map((item) => {
                    return (
                      <div className="col-xl-4 col-lg-6 mb-4">
                        <div class="recent-card p-3 border">
                          <Link
                            to={`${config.baseUrl}blogDetail/${item.id}`}
                            className="recent-list "
                          >
                            <div className="position-relative pb-0 overflow-hidden" style={{ borderRadius: "6px"}}>
                              <img
                                className="marketNftcss"
                                src={config.imageUrl1 + item.image}
                                height="250"
                                width="100%"
                                alt="omg"
                                class="object-fit-cover"
                              />
                              <div
                                class="tag mt-2 px-2"
                                style={{ top: "0", bottom: "inherit" }}
                              >
                                <p class="mb-0">{item.title}</p>
                              </div>
                            </div>
                          </Link>
                          <div className="p-3">
                            <Link
                              to={`${config.baseUrl}blogDetail/${item.id}`}
                              className="head-title"
                            >
                              {item.heading}
                            </Link>
                            <div className="author-post">
                              <img src={"#"} alt="" className="ava-author" />

                              <div className="entry-date pull-right">
                                {moment(item.date).format("LL")}
                              </div>
                            </div>
                            <p className="entry-content">
                              
                                <>
                                <p>
                                        {item.isExpanded
                                          ? item.shortDescription // Full description when expanded
                                          : item.shortDescription.length > 235
                                          ? `${item.shortDescription.slice(0, 235).split(' ').slice(0, -1).join(' ')}...` // Truncated description
                                          : item.shortDescription} 
                                          &nbsp;&nbsp;&nbsp;<button
                                          className="btn btn-default p-0 box-shadow-none readmore"
                                          style={{color:"#101111"}}
                                          onClick={() => {
                                            window.location.href=`${config.baseUrl}blogDetail/${item.id}`
                                          }}
                                        >Read More</button>
    
    {/* If the description is already less than 235 characters, show it fully */}
</p>


                             </>
                             
                              <br />
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                </div>
              </div>
            </div>}
          </div>
        </div>
        <Footer1 />
      </>
    );
  }
}
