import React, { Component } from "react";
import Header from "../directives/header1";
import Footer1 from "../directives/footer1";
import config from "../config/config";
import axios from "axios";

export default class whyArtFungible extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dynamicData: [],
    };
  }

  componentDidMount() {
    this.getWhyArtFungibleAPI();
  }

  async getWhyArtFungibleAPI() {
    await axios({
      method: "get",
      url: `${config.apiUrl}getWhyArtFungible`,
    }).then((response) => {
      if (response.data.success === true) {
        this.setState({
          dynamicData: response.data.response,
        });
      }
    });
  }

  render() {
    return (
      <>

        <Header />
        <div className="container px-lg-5">
            <div className="row  mt-2 mb-lg-3">
              <div className="col-md-12 mb-3">
                <div id="container-mix" className="row _post-container_ ">
                  <div className=" col-sm-12 ">
                    <div className="blog-card position-relative">
                      <div className="">
                  <h1>Why Art Fungible ?</h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.dynamicData[0]?.why_art_fungible,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
</div>
</div>
      </div>
        <Footer1 />
      </>
    );
  }
}
