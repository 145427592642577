import './App.css';
//  import './../css/style';
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import config from './config/config'
// import Header from './directives/header'
// import Footer from './directives/footer'
import login from './login'
import register from './register'
import resetpassword from './resetpassword'
import generatepassword from './generatepassword'
import VerifyAccount from './register'
import cart from './components/cart'
import marketplace from './components/marketplace'
import userprofile from './userprofile'
import home from './home'
// import home from './components/home';
import whishlist from './components/whishlist'
import transaction from './components/transaction'
import activity from './components/activity'
import marketplaceDetail from './components/marketplace-detail'
import privacyPolicy from './components/privacy_policy'
import termsAndCondition from './components/terms_and_condition'
import whyArtFungible from './components/whyArtFungible'
import about from './components/about'
import drop from './components/drop'
import kolectToken from './components/kolect_token'
import Introduction from './components/introduction'
import ContactUs from './components/contact_us';
import Help_center from './components/help_center';
import art_fungible from './components/art_fungible';
import viewNfttransaction from './components/view_nft_transaction_details'
import viewBidtransaction from './components/view_bid_transaction_details'
import refundManagement from './components/refundManagement'
import stats from './components/stats'
import blog from './components/blog';
import blogDetail from './components/blogdetail';
import faq from './components/faq';
import MarketplaceNew from './components/marketplaceNew';
import Membership from './components/membership';
import Collection from './components/collection';
import Member from './components/member';
import Afgenesis from './components/afgenesis';
import Afgenesis2 from './components/afgenesis2';
import Itemactivity from './components/itemactivity';
import Seasky from './components/seasky';
import Setting from './components/setting';
import Metadata from './components/metadata';
import Physicalnftview from './components/physicalnftview';
import Modalone from './components/modalone';
import Modalsecond from './components/modalsecond';
import AsNavFor from './components/sliderView';
import home2 from './home2';
import Bgparticle from './bgparticle';
function App() {
  return (
    <BrowserRouter>

      <Switch>

        <Route path={`${config.baseUrl}`} exact component={home} />
        <Route path={`${config.baseUrl}bgparticle`} exact component={Bgparticle} />
        
        <Route path={`${config.baseUrl}home`} exact component={home2} />
        {/* <Route path={`${config.baseUrl}`} exact component={login} /> */}

        <Route path={`${config.baseUrl}login`} exact component={login} />
        <Route path={`${config.baseUrl}register`} exact component={register} />
        <Route path={`${config.baseUrl}cart`} exact component={cart} />
        <Route path={`${config.baseUrl}stats`} exact component={stats} />
        <Route path={`${config.baseUrl}marketplace`} exact component={marketplace} />
        <Route path={`${config.baseUrl}marketplaceDetail/:id`} exact component={marketplaceDetail} />
        <Route path={`${config.baseUrl}userprofile`} exact component={userprofile} />
        <Route path={`${config.baseUrl}whishlist`} exact component={whishlist} />
        <Route path={`${config.baseUrl}transaction`} exact component={transaction} />
        <Route path={`${config.baseUrl}activity`} exact component={activity} />
        <Route path={`${config.baseUrl}resetpassword`} exact component={resetpassword} />
        <Route path={`${config.baseUrl}generatepassword`} exact component={generatepassword} />
        <Route path={`${config.baseUrl}verifyAccount/:token`} component={VerifyAccount} />
        <Route path={`${config.baseUrl}resetpassword/:token`} component={resetpassword} />
        <Route path={`${config.baseUrl}privacy_policy`} exact component={privacyPolicy} />
        <Route path={`${config.baseUrl}terms_and_condition`} exact component={termsAndCondition} />
        <Route path={`${config.baseUrl}whyArtFungible`} exact component={whyArtFungible} />
        <Route path={`${config.baseUrl}about`} exact component={about} />
        <Route path={`${config.baseUrl}newin`} exact component={drop} />
        <Route path={`${config.baseUrl}kolect_token`} exact component={kolectToken} />
        <Route path={`${config.baseUrl}introduction`} exact component={Introduction} />
        <Route path={`${config.baseUrl}contact_us`} exact component={ContactUs} />
        <Route path={`${config.baseUrl}member`} exact component={Member} />
        <Route path={`${config.baseUrl}help-center`} exact component={Help_center} />
        <Route path={`${config.baseUrl}art_fungible`} exact component={art_fungible} />
        <Route path={`${config.baseUrl}viewNfttransaction/:id`} exact component={viewNfttransaction} />
        <Route path={`${config.baseUrl}viewBidtransaction/:id`} exact component={viewBidtransaction} />
        <Route path={`${config.baseUrl}refundManagement`} exact component={refundManagement} />
        <Route path={`${config.baseUrl}blog`} exact component={blog} />
        <Route path={`${config.baseUrl}blogDetail/:id`} exact component={blogDetail} />
        <Route path={`${config.baseUrl}faq`} exact component={faq} />
        <Route path={`${config.baseUrl}marketplaceNew`} exact component={MarketplaceNew} />
        <Route path={`${config.baseUrl}membership/:walletAddress`} exact component={Membership} />
        <Route path={`${config.baseUrl}setting`} exact component={Setting} />
        <Route path={`${config.baseUrl}:artistId`} exact component={Collection} />
        <Route path={`${config.baseUrl}:artistName/:collectionName`} exact component={Afgenesis} />
        <Route path={`${config.baseUrl}afgenesis2`} exact component={Afgenesis2} />
        <Route path={`${config.baseUrl}:artistName/:collectionName/:artWork`} exact component={Itemactivity} />
        <Route path={`${config.baseUrl}seasky`} exact component={Seasky} />
        <Route path={`${config.baseUrl}physicalnftview/:id`} exact component={Physicalnftview} />
        <Route path={`${config.baseUrl}:contract/:id`} exact component={Metadata} />
        <Route path={`${config.baseUrl}modalone`} exact component={Modalone} />
        <Route path={`${config.baseUrl}modalsecond`} exact component={Modalsecond} />
        <Route path={`${config.baseUrl}AsNavFor`} exact component={AsNavFor} />

        

      </Switch>

    </BrowserRouter>
  );
}

export default App;

